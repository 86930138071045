@import "@scss/base";

.newsletter-signup {
  &--inverted {
    color: var(--c-b-white);
  }
  &__headline {
    @include fontStyle(heading-5);
    padding-top: rem(15);
  }
  &__bodytext {
    padding: rem(20) 0;
  }
  &__button {
    padding: rem(15) 0;
    @include mq($from: vp5) {
      padding: rem(24) 0;
    }
  }
  .nav-button{
    padding: rem(8) rem(27);

    .text{
      font-size: 10px;

      @include mq($from: vp5) {
        font-size: 12px;
      }
    }
  }
}
