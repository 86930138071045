@mixin saveButton() {
  position: absolute;
  top: rem(14);
  right: rem(14);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: rem(36);
  height: rem(30);
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);

  transition: all var(--default-transition);

  .icon {
    display: inline-flex;
    color: var(--c-b-white);
  }
}

.save-button {
  @include saveButton();
}
