@import "../../assets/scss/base";

.checkout-footer {
  $module: ".checkout-footer";

  .trustpilot-widget {
    // margin-left: rem(50);
    margin-bottom: rem(30);
  }
  &__container {
    padding-top: rem(30);
    border-top: rem(1) solid var(--c-s-border);
    @include container($use-margin: true);
  }

  &__image {

    margin-bottom: rem(30);
    img {
      max-width: 100%;
      height: auto;
      @include mq($from: vp4) {
        width: auto;
      }
    }
  }

  &__main {
    background-color: var(--c-b-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem(60);
  }

  &__headline {
    @include fontStyle(heading-2);
    color: var(--c-b-white);
    padding-bottom: rem(30);
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    color: var(--c-b-grey3);
    @include mq($from: vp4) {
      flex-direction: row;
    }
  }

  &__link-items {
    @include fontStyle(link-1);
    text-align: center;
    &:not(:last-child) {
      margin-bottom: rem(20);
    }
    @include mq($from: vp4) {
      &:not(:last-child) {
        margin-right: rem(20);
      }
    }
  }

  &__footer-logo {
    display: flex;
    justify-content: center;
    margin: rem(30) 0;
    @include mq($from: vp4) {
      justify-content: flex-start;
    }
  }
}
