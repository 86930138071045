@import "@scss/base";

.pdp-micro-swatches {
  $module: ".pdp-micro-swatches";
  margin-bottom: auto;

  &.choose-configuration {
      margin-bottom: 0;
      margin-top: 0;
      
      h4 {
        @include fontStyle(paragraph-2);
        text-transform: uppercase;
      }
    }

  h4 {
    @include fontStyle(paragraph-2);
    margin-top: rem(20);
    margin-bottom: rem(20);
  }
  &__empty{
    margin-bottom: rem(68);

    @include mq($from: vp5) {
        margin-bottom: auto;
    }
  }

  &__list {
    display: block;
    //overflow: hidden;
    &__inner {
      //overflow: auto;
      display: flex;
      flex-wrap: wrap;
      // &.hero-selection{
      //   justify-content: space-between;
      // }
    }

    &-item {
      display: inline-flex;
      margin-right: rem(10);

      transition: all var(--default-transition);
      padding-bottom: rem(10);
      border-bottom: rem(2) solid transparent;

      &.is-active {
        border-bottom: rem(2) solid var(--c-b-black);
      }
      div {
        background-color: var(--c-t-beige);
        display: inline-flex;

        width: rem(60);
        height: rem(60);
        @include mq($from: vp5) {
          width: rem(50);
          height: rem(50);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &:not(.is-active):hover {
        padding-bottom: rem(4);
        border-bottom: rem(2) solid var(--c-b-grey3);
      }
    }
  }
}
