@import "@scss/base";

.hero-linear {
  $module: ".hero-linear";
  @include container;

  //@include verticalSpacing;
  @include topModuleSpacing(true);

  position: relative;
  margin-bottom: 0;

  --hero-c: var(--c-b-black);
  --hero-bgc: transparent;

  color: var(--hero-c);
  background: var(--hero-bgc);

  &--inverted {
    --hero-c: var(--c-b-white);
  }
  @include grid();

  &__content {
    @include grid();

    @include gridItem(1, 12, 2, 1);

    @include mq($from: vp5) {
      margin-bottom: rem(60);
      @include gridItem(1, 24, 1, 1);
    }
  }
  &__headline {
    @include gridItem(2, 10);

    //@include fadeFromTop(1);

    @include mq($from: vp5) {
      @include gridItem(3, 8, 1, 1);
    }

    h1 {
      @include fontStyle(heading-hero-1);
    }
    h2 {
      @include fontStyle(heading-2);
    }
  }
  &__text {
    margin-top: rem(20);
    @include gridItem(2, 10);

    @include mq($from: vp5) {
      align-self: end;
      margin-top: rem(4); // fit to headline line height
      @include gridItem(12, 8, 1, 1);
    }

    p {
      @include fontStyle(paragraph-2);
    }

    .nav-button,
    .action-button {
      margin-top: rem(60);
      @include mq($from: vp3) {
        width: auto;
      }
      @include mq($from: vp5) {
        margin-top: rem(40);
      }
    }
  }
  .readmore-button {
    display: block;
    padding: rem(15);
    @include mq($from: vp5) {
      padding: rem(15);
    }
    .svg-icon {
      transform: rotate(90deg);
    }
    .text {
      @include fontStyle(link-2);
    }
  }
  .readmore-text {
    margin-top: rem(20);
    display: inline;
  }

  &__image {
    @include offsetContent(left);
    @include gridItem(1, 11, 1, 1);
    height: 50vh;

    @include mq($from: vp5) {
      height: 70vh;
    }
    @include mq($from: vp9) {
      height: 90vh;
    }
    .media-focal__lazy {
      width: 100%;
      height: 100%;
      display: block;
    }

    margin-bottom: rem(50);
    @include mq($from: vp5) {
      margin-bottom: 0;
      @include gridItem(1, 24, 2, 1);
    }
  }

  &--extra-content {
    #{$module}__text {
      p {
        display: inline;
      }
      .nav-button,
      .action-button {
        display: block;
      }
    }
  }

  &--center-image {
    #{$module}__image {
      margin-left: 0;
      width: auto;
      @include gridItem(1, 12, 1, 1);
      @include mq($from: vp5) {
        @include gridItem(1, 24, 2, 1);
      }
    }
  }
}
