@import "@scss/base";

.svg-icon {
  --icon-c: var(--c-b-black);
  fill: var(--icon-c);
  overflow: visible;

  &.is-inverted {
    --icon-c: var(--c-b-white);
    fill: var(--icon-c);
  }
}
