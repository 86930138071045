@import "@scss/base";
@import "../Carousel/carousel.scss";

.presentation-modal {
  $module: ".presentation-modal";

  $autoPlayDuration: 10;

  background-color: #1e1e1d;
  width: 100%;
  flex: 0 0 100%;
  $mg: 5vh;

  &__close {
    position: absolute;
    top: rem(20);
    right: rem(7);
    z-index: 7;

    display: inline-flex;
    width: rem(40);
    height: rem(40);
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: rem(38);
      height: rem(38);
      stroke: var(--c-b-white);
    }
    @include mq($from: vp3) {
      top: 0;
      right: auto;
      left: 0;
    }
    @include mq($from: vp4) {
      right: 0;
      left: auto;
    }
    @include mq($from: vp7) {
      width: rem(80);
      height: rem(80);
      .svg-icon {
        width: rem(60);
        height: rem(60);
      }
    }
  }
  &__content {
    height: 100%;
    width: 100%;

    h3 {
      display: none;
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }

    @include mq($from: vp3) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__headline {
    position: fixed;
    top: calc(var(--site-header-height) + var(--mode-switch-height));
    z-index: 3; // CHANGE LATER ??

    line-height: 1;

    left: rem(10);

    @include scaledHeadline();
  }
  &__auto-gallery {
    position: relative;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    animation: galleryAnim 0.3s ease forwards;

    @include mq($from: vp3) {
      flex: 0 0 calc((11 / 16) * #{100vh - $mg - $mg});
      max-width: calc((11 / 16) * #{100vh - $mg - $mg});
      height: #{100vh - $mg - $mg};
      margin: #{$mg} 0;
    }
  }
  &__progress {
    position: absolute;
    top: rem(14);
    left: rem(14);
    right: rem(14);
    z-index: 10;

    display: flex;
    justify-content: space-between;
    li {
      flex: 1;
      margin: 0 rem(2);
      background-color: var(--c-b-grey2);
      position: relative;
      height: rem(3);

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.0001%;
        height: rem(3);

        background-color: var(--c-b-grey4);
      }
      &.is-complete span {
        width: 100%;
      }
      &.is-active span {
        animation: progressAnim #{$autoPlayDuration}s linear forwards;
      }
    }
  }

  &__slider {
    overflow: hidden;

    height: 100%;
    max-height: 100%;

    .swiper-container {
      height: 100%;
      max-height: 100%;
    }
  }

  .carousel {
    
    &__nav {
      display: block;
      padding: 0;
      position: relative;
    }

    &__prev,
    &__next {
      position: fixed;
      top: 0;
      bottom: 0;
      display: inline-flex;
      align-items: center;
      padding: rem(40);
      box-sizing: border-box;
      color: var(--c-b-white);
      z-index: 6;

      svg {
        fill: var(--c-b-white);
        width: rem(20);
        height: rem(20);
      }
    }
    &__prev {
      left: 0;
      padding-right: calc(50% - rem(60));
      padding-left: rem(20);
      @include mq($from: vp3) {
        padding-right: calc(50% - rem(40));
        padding-left: rem(40);
      }
    }
    &__next {
      right: 0;
      
      padding-right: rem(20);
      padding-left: calc(50% - rem(60));
      @include mq($from: vp3) {
        padding-right: rem(40);
        padding-left: calc(50% - rem(40));
      }
    }
  }

  &__slide {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    &.swiper-slide-active {
      img {
        animation-duration: #{$autoPlayDuration}s;
        animation-timing-function: cubic-bezier(0.5, 0.19, 0.27, 0.53);
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }

      &:nth-child(4n + 1) img {
        animation-name: mediaAnim1;
      }
      &:nth-child(4n + 2) img {
        animation-name: mediaAnim2;
      }
      &:nth-child(4n + 3) img {
        animation-name: mediaAnim1;
      }
      &:nth-child(4n + 4) img {
        animation-name: mediaAnim3;
      }
    }

    &__media {
      position: relative;
      background-color: var(--c-b-black);

      width: 100%;
      height: 100%;
      max-height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__text {
      position: absolute;
      display: grid;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "pos1 pos2 pos3"
        "pos4 pos5 pos6"
        "pos7 pos8 pos9";
      align-items: center;
      justify-content: center;
      text-size-adjust: none;

      h5 {
        @include fontStyle(trumpet-3);
        white-space: nowrap;
      }
      p {
        @include fontStyle(paragraph-2);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: calc(1.57 * 4em);
      }

      div {
        padding: rem(20);
        .is-inverted & {
          color: var(--c-b-white);
        }
      }

      @for $i from 1 through 9 {
        &--position-#{$i} {
          div {
            grid-area: pos#{$i};
          }
        }
      }

      &--position-1 div,
      &--position-4 div,
      &--position-7 div {
        padding-right: 0;
      }
      &--position-2 div,
      &--position-5 div,
      &--position-8 div {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
      }
      &--position-3 div,
      &--position-6 div,
      &--position-9 div {
        text-align: right;
        padding-left: 0;
      }
    }
  }

  @keyframes mediaAnim1 {
    0% {
      transform-origin: center;
      transform: scale(1.2) translateX(-5%);
    }
    100% {
      transform-origin: center;
      transform: scale(1.2) translateX(5%);
    }
  }
  @keyframes mediaAnim2 {
    0% {
      transform-origin: top left;
      transform: scale(1.2) translate(-10%, -10%);
    }
    100% {
      transform-origin: top left;
      transform: scale(1) translate(0, 0);
    }
  }
  @keyframes mediaAnim3 {
    0% {
      transform-origin: right bottom;
      transform: scale(1.2) translate(10%, 10%);
    }
    100% {
      transform-origin: right bottom;
      transform: scale(1) translate(0, 0);
    }
  }

  @keyframes galleryAnim {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes progressAnim {
    0% {
      width: 0.01%;
    }
    100% {
      width: 100%;
    }
  }
}
