@import "@scss/base";

.portfolio-media-tile {
  $module: ".portfolio-media-tile";
  position: relative;
  display: flex;
  flex-direction: column;

  &__image {
    position: relative;
    width: 100%;
    height: 0;

    //assuming rows of 2 elements - overwritten for wide
    padding-bottom: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__text {
    // flex: 0 0 rem(198); //to match product tiles text height
    padding-top: rem(20);
    box-sizing: border-box;

    h3 {
      @include fontStyle(heading-4);
      margin-bottom: rem(10);
    }
    p {
      @include fontStyle(paragraph-3);
    }
  }
  &__products {
    li {
      display: block;
    }
    a {
      @include fontStyle(paragraph-3);
      text-decoration: underline;
    }
  }
  &.is-tall {
    grid-column: span 6;

    @include mq($from: vp3) {
      grid-column: span 5;
      #{$module}__image {
        //percentage is product tile image height divided by wide tile width
        //use 2 gaps, as tall images are usually in a 3 item row
        padding-bottom: 142.82335498%;
      }
    }

    @include mq($from: vp5) {
      grid-column: span 6;

      #{$module}__image {
        //percentage is product tile image height divided by wide tile width
        //use 2 gaps, as tall images are usually in a 3 item row
        padding-bottom: 154.01523185%;
      }
    }
  }

  &.is-square {
    grid-column: span 6;

    #{$module}__image {
      padding-bottom: 100%;
    }

    @include mq($from: vp3) {
      #{$module}__image {
        padding-bottom: 117.62362997%;
      }
    }

    @include mq($from: vp5) {
      grid-column: span 8;

      #{$module}__image {
        //percentage is product tile image height divided by wide tile width
        //use 2 gaps, as tall images are usually in a 3 item row
        padding-bottom: 113.0620985%;
      }
    }
  }

  &.is-wide {
    grid-column: span 12;

    #{$module}__image {
      padding-bottom: 50%;
    }

    @include mq($from: vp5) {
      grid-column: span 15;
      #{$module}__image {
        //percentage is product tile image height divided by wide tile width
        padding-bottom: 58.83008357%;
      }
    }
  }
}
