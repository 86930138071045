@import "@scss/base";



.mega-menu__footer-block {
  $module: ".mega-menu-footer";
  color: var(--c-b-white-trans);
  width: 100%;


  width: 100%;
  @include mq($from: vp5) {
    display: none;
  }
  .mega-menu__button .svg-icon {
    fill: var(--c-b-white-trans);
  }
  .mega-menu__inner {
    @include mq($until: vp5) {
      overflow: inherit;
    }
  }
  .mega-menu__list {
    h2 {
      @include fontStyle(heading-4);
      font-size: rem(15);
    }
    .mega-menu__button {
      font-size: rem(12);
    }
  }

  &__navlist {
    // padding-left: rem(30);
    display: flex;
    flex-direction: column;
    gap: rem(20);
  }

  &__link-item {
    color: var(--c-b-white-trans);
    @include fontStyle(heading-4);
    font-size: rem(15);
  }

  &__so-me-link,
  .country-select-button {
    color: rgba(255,255,255, 0.5);
  }
  // &__subitem {
  //   margin-top: rem(10);
  // }

  &__so-me-group {
//     padding: rem(30) 0 rem(30) rem(30);
    display: flex;
    flex-direction: column;
    gap: rem(10);
  }
  &__so-me-link {
    @include fontStyle(paragraph-3);
    text-transform: uppercase;
  }

  .country-select-button {
    background-color: lighten(black, 12%);
    padding: rem(20);

    button {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .svg-icon {
        fill: var(--c-b-white-trans);
        margin-left: rem(15);
      }
    }
  }
}
