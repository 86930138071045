@import "@scss/base";

.list-hero {
  $module: ".list-hero";
  @include grid;
  @include container;
  @include topModuleSpacing;
  position: relative;

  //custom spacing
  padding-bottom: rem(50);
  @include mq($from: vp5) {
    padding-bottom: rem(70);
  }

  --hero-c: var(--c-b-black);
  --hero-bgc: transparent;

  color: var(--hero-c);
  background: var(--hero-bgc);

  &--inverted {
    --hero-c: var(--c-b-white);
  }

  &__content {
    @include grid();
    @include gridItem(1, 12);
    grid-template-rows: repeat(11, 11vw) auto;
    @include mq($from: vp5) {
      @include gridItem(1, 24);
      grid-template-rows: rem(60) repeat(5, 5vw) rem(60);
    }
    @include mq($from: vp7) {
      grid-template-rows: rem(70) repeat(5, 5vw) rem(70);
    }
  }

  &__headline {
    z-index: 1;
    text-align: center;

    //@include fadeFromTop(0.8);

    @include gridItem(1, 12, 5, 4);

    @include mq($from: vp5) {
      @include gridItem(2, 22, 1, 2);
    }
    h1 {
      //custom sizes according to design!

      text-transform: uppercase;
      line-height: 1.3;
      word-break: normal;
      hyphens: auto;
      text-align: center;

      font-size: rem(40);
      @include mq($from: vp3) {
        font-size: rem(60);
      }
      @include mq($from: vp4) {
        font-size: rem(70);
      }
      @include mq($from: vp5) {
        font-size: clamped(80, 110, vp6);
      }
    }
  }
  &__text {
    text-align: center;
    margin-top: rem(20);
    //@include fadeFromRight(1);
    @include gridItem(2, 10, 11, 3);

    @include mq($from: vp3) {
      padding: rem(30) 0;
      margin-top: 0;
    }
    @include mq($from: vp4) {
      @include gridItem(2, 10, 10, 3);
    }

    @include mq($from: vp5) {
      text-align: left;
      @include gridItem(17, 8, 4, 3);
    }

    p {
      @include fontStyle(heading-4);
    }
  }

  &__image {
    //@include fadeFromTop(1);
    @include gridItem(2, 10, 1, 10);

    @include mq($from: vp3) {
      @include gridItem(2, 10, 1, 9);
      height: calc(100% + rem(40));
      margin-bottom: rem(30);
      margin-top: rem(40);
    }
    @include mq($from: vp4) {
      @include gridItem(2, 10, 1, 8);
    }
    @include mq($from: vp5) {
      margin-top: rem(60);
      height: calc(5 * 5vw + rem(60));
      display: block;
      @include gridItem(9, 8, 1, 5);
      margin-bottom: 0;
    }

    @include mq($from: vp7) {
      height: calc(5 * 5vw + rem(70));
      margin-top: rem(70);
    }
    .media-focal__lazy {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &--text-top {
    @include mq($until: vp3) {
      #{$module}__headline {
        @include gridItem(1, 12, 1, 5);
      }
      #{$module}__image {
        @include gridItem(3, 8, 2, 6);
        margin-top: rem(-10);
      }
      #{$module}__text {
        @include gridItem(2, 10, 8, 6);
      }
    }
  }

  &--tall {
    #{$module}__content {
      grid-template-rows: repeat(3, 32vw) auto auto;
      @include mq($from: vp5) {
        @include gridItem(1, 24);
        grid-template-rows: repeat(2, 16vw) auto 16vw 16vw;
      }
    }
    #{$module}__headline {
      @include gridItem(1, 12, 3, 1);

      @include mq($from: vp5) {
        @include gridItem(1, 24, 2, 1);
      }
      h1 {
        text-align: left;
        line-height: 10vw;
        @include mq($from: vp3) {
          font-size: rem(60);
        }
        @include mq($from: vp4) {
          font-size: rem(80);
        }
        @include mq($from: vp6) {
          font-size: clamped(110, 140, vp6);
        }
      }
    }
    #{$module}__text {
      @include gridItem(1, 12, 4, 2);
      @include mq($from: vp4) {
        margin-top: 0;
        padding-top: 0;
      }
      @include mq($from: vp5) {
        @include gridItem(17, 7, 3, 2);
      }
      p {
        text-transform: none;
        @include fontStyle(paragraph-2);
      }
    }
    #{$module}__image {
      height: auto;
      margin-top: 0;
      // @include fadeFromLeft(0);
      @include gridItem(2, 10, 1, 3);
      @include mq($from: vp5) {
        @include gridItem(3, 13, 1, 5);
      }
    }
  }

  &--split {
    #{$module}__content {
      grid-template-rows: repeat(3, 30vw) auto auto;
      @include mq($from: vp5) {
        @include gridItem(1, 24);
        grid-template-rows: repeat(2, 16vw) auto 16vw auto;
      }
    }
    #{$module}__headline {
      @include gridItem(1, 12, 4, 1);
      @include mq($from: vp5) {
        @include gridItem(17, 7, 3, 1);
      }
      h1 {
        text-align: left;
        @include fontStyle(heading-1);
      }
    }
    #{$module}__text {
      margin-top: 0;
      text-align: left;
      @include gridItem(1, 12, 5, 1);
      @include mq($from: vp5) {
        @include gridItem(17, 7, 4, 2);
      }
      p {
        text-transform: none;
        @include fontStyle(paragraph-2);
      }
    }
    #{$module}__image {
      height: auto;
      margin-top: 0;
      @include gridItem(1, 12, 1, 3);
      @include mq($from: vp5) {
        @include gridItem(3, 13, 1, 5);
      }
    }
  }

  .sub-navigation + & {
    padding-top: rem(20) !important;
    @include mq($from: vp5) {
      padding-top: rem(50) !important;
    }
  }
}
