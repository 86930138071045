@mixin basicLink() {
  @include fontStyle(paragraph-3);

  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;

  span + span {
    margin-left: rem(10);
  }
  .text {
    text-decoration: underline;
  }
  &.is-disabled {
    opacity: 0.8;
    .text {
      text-decoration: none;
    }
  }
}

.basic-link {
  @include basicLink();
}
