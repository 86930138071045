@import "@scss/base";

.simple-gallery {
  $module: ".simple-gallery";
  @include container;
  @include grid;
  @include verticalSpacing;
  grid-template-rows: auto auto;

  @include mq($from: vp5) {
    grid-template-rows: auto;
  }

  &__image {
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: rem($grid-gap-small);
      @include mq($from: vp3) {
        margin-top: rem($grid-gap-regular);
      }
      @include mq($from: vp5) {
        margin-top: 0;
      }
    }

    &:nth-child(1) {
      @include gridItem(1, 12, 1, 1);
      height: 63.5vw;
      @include mq($from: vp5) {
        height: 33.3vw;
        @include gridItem(1, 11, 1, 1);
      }
    }

    &:nth-child(2) {
      @include gridItem(1, 6, 2, 1);
      height: 35.2vw;
      @include mq($from: vp5) {
        height: 19.5vw;
        @include gridItem(12, 7, 1, 1);
      }
    }

    &:nth-child(3) {
      @include gridItem(7, 6, 2, 1);
      height: 56vw;
      @include mq($from: vp5) {
        height: 26.5vw;
        @include gridItem(19, 6, 1, 1);
      }
    }
  }
}
