@import "@scss/base";

.pdp-configurator-filters {
  $module: ".pdp-configurator-filters";

  display: flex;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: rem(10);
  min-height: rem(30);
  height: rem(30);
  flex: 0 0 rem(30);
  @include custom-scrollbar;

  &__list {
    display: flex;
    flex-wrap: nowrap;
    height: rem(30);

    li {
      display: inline-flex;
    }
  }
  &__button {
    @include basicLink();
    height: rem(30);

    padding: 0;
    margin-right: rem(10);

    &.is-active {
      padding: 0 rem(10);
      color: var(--c-b-white);
      background-color: var(--c-b-black);

      .text {
        text-decoration: none;
      }
    }
  }
}
