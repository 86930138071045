@import "@scss/base";

.myfritzhansen-productlist {
    .product-tile__text {
        &.claim-message{
            color: var(--c-s-error2);
        }
        p {
            white-space: normal;
            word-break: normal;
        }
    }
}