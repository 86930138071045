@import "@scss/base";

.sub-navigation {
  $module: ".sub-navigation";
  @include mq($from: vp4) {
    @include container;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // BOTH //
  &__desktop,
  &__mobile-tablet {
    width: 100%;
    border-top: 1px solid lighten(black, 70%);
    border-bottom: 1px solid lighten(black, 70%);
  }
  &__slide {
    margin-top: rem(30);
    margin-bottom: rem(30);
    color: lighten(black, 70%);
  }

  &__item {
    @include fontStyle(link-1);

    &.is-active {
      color: var(--c-b-black);
    }
  }
  &__item-text {
    text-align: left;
    cursor: pointer;
  }

  // DESKTOP //
  &__desktop {
    display: none;
    @include mq($from: vp5) {
      display: flex;
      justify-content: center;
    }

    #{$module}__slide {
      margin-right: rem(50);
    }
  }
  &__desktop-inner {
    display: flex;
    margin: 0 auto;
  }

  // MOBILE //
  &__mobile-tablet {
    display: block;
    @include mq($from: vp5) {
      display: none;
    }
  }
  .swiper-wrapper {
    @include mq($from: vp5) {
      justify-content: center;
    }
    > div {
      &:nth-last-of-type() {
        margin-right: 0 !important;
      }
    }
  }
  .swiper-slide-active {
    color: black;
  }
}
