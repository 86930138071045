@import "@scss/base";

.radio {
  $formfield: ".radio";

  &__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
  }

  &__box {
    appearance: none;
    margin: 0;
    font-size: 0;
    height: rem(25);
    flex: 0 0 rem(25);
    border-radius: 50% 50%;
    background-color: transparent;
    border: rem(1) solid var(--c-b-black);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;

    &:checked {
      height: rem(25);
      width: rem(25);
    }
    &:after {
      content: "";
      display: block;
      background-color: var(--c-b-black);
      border-radius: 50% 50%;
      font-size: 18px;
      width: 50%;
      height: 50%;
      opacity: 0;
      transition: opacity var(--default-transition);
    }
    &:checked:after {
      opacity: 1;
    }
    &[type="radio"]:disabled {
      border-color: var(--c-b-grey2);
      background-color: darken(white, 5%);
      + label {
        color: var(--c-b-grey2);
      }
    }
  }

  &__label {
    margin-left: rem(15);
    @include fontStyle(paragraph-2);
    cursor: pointer;
    text-align: left;
    width: 100%;
    white-space: pre-line;
    line-height: rem(25);
    span {
      display: block;
    }
    small {
      line-height: 1;
      display: block;
      @include fontStyle(paragraph-3);
      color: var(--c-b-grey2);
    }
  }

  &__message {
    @include fontStyle(paragraph-2);
    margin-top: rem(5);
    color: var(--c-s-error2);
  }

  &--label-left {
    #{$formfield}__label {
      order: 1;
    }
    #{$formfield}__box {
      &[type="radio"] {
        order: 2;
      }
    }
    #{$formfield}__label {
      text-align: start;
    }
  }
  &.is-invalid {
    #{$formfield}__box,
    #{$formfield}__label {
      color: var(--c-s-error2);
      border-color: var(--c-s-error2);
    }
  }
}
