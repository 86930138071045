:root {
  // Colors
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }
  --margin-unit: rem(5);
  --mode-switch-height: rem(30);

  // Animation standards
  --anim-duration: 0.25s;
  --anim-ease: cubic-bezier(0.25, 0.01, 0.25, 1);
  --default-transition: var(--anim-duration) var(--anim-ease);

  --site-header-height: rem(63);
}
