@import "@scss/base";

.timeline-progressbar {
  $module: ".timeline-progressbar";

  position: fixed;
  height: rem(1);
  left: 5vw;
  width: 96vw;
  bottom: rem(20);
  z-index: 2;

  @include mq($until: vp5) {
    bottom: rem(30);
  }
  &__progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.001%;
    background-color: var(--c-b-white);
    z-index: 2;
  }

  &__bg {
    background-color: var(--c-b-grey2);
    position: absolute;
    inset: 0;
    z-index: 1;
  }
  &__divisions {
    position: relative;
    z-index: 2;

    span {
      display: inline-flex;      
      width: rem(2);
      height: rem(2);
      background-color: var(--c-b-black);
      position: absolute;
      top: 0;
    }
  }
}
