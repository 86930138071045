@import "@scss/base";

.pdp-hero-selection {
  $module: ".pdp-hero-selection";
  margin-bottom: rem(40);

  &__list {
    display: block;
    overflow: hidden;

    &__inner {
      overflow: auto;
      display: flex;
    }

    &-item {
      display: inline-flex;
      margin: 0 rem(10) rem(10) 0;

      transition: all var(--default-transition);
      padding: rem(10) rem(10) rem(12);
      box-shadow: 0px 0px 0px rem(2) transparent inset;
      
      flex: 0 0 9%; 
      @include mq($from: vp4) {
        flex: 0 0 10%;
      }
      @include mq($from: vp5) {
        flex: 0 0 11%;
      }
      @include mq($from: vp6) {
        flex: 0 0 11%;
      }
      @include mq($from: vp7) {
        flex: 0 0 10%;
      }

      background-color: #efeeeb;

      &.is-active {
        box-shadow: 0px 0px 0px rem(1) var(--c-b-black) inset;
      }

      &.open-configurator-item{
        margin-right: 0;
      }

      div {
        display: inline-flex;

        width: rem(29);
        height: rem(29);
        @include mq($from: vp2) {
          width: rem(60);
          height: rem(60);
        }
        @include mq($from: vp3) {
          width: rem(56);
          height: rem(56);
        }
        @include mq($from: vp4) {
          width: rem(80);
          height: rem(80);
        }
        @include mq($from: vp5) {
          width: rem(38);
          height: rem(38);
        }
        @include mq($from: vp6) {
          width: rem(45);
          height: rem(45);
        }
        @include mq($from: vp8) {
          width: rem(55);
          height: rem(55);
        }

        &.open-configurator-item__inner {
          flex-direction: column;
          background-color: #efeeeb;
          align-items: center;

          .svg-icon--plus {
            flex: 0 0 100%;
          }

          div {
            font-size: .80rem;
          }

        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &:not(.open-configurator-item):not(.is-active):hover {
        padding-bottom: rem(12);
        box-shadow: inset 0 -4px 0 rem(-3) var(--c-b-grey3);
      }
    }
  }
}