@import "@scss/base";

.nav-tile {
  $module: ".nav-tile";

  &--interactive {
    /// Removed for FHBC-672
    // &:hover .nav-tile__img {
    //   opacity: 0.7;
    // }
  }
  &__img {
    transition: opacity var(--default-transition);
  }

  .text-tile {
    padding: rem(30) 0;
    @include mq($from: vp5) {
      padding-top: rem(30);
      padding-left: 0;
    }
  }
}
