@import "@scss/base";

.timeline {
  $module: ".timeline";
  background-color: var(--c-b-timeline);

  &__scroll-container {
    background-color: var(--c-b-timeline);
    position: fixed;
    z-index: 2; // REMOVE THIS LATER ?
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: calc(
      var(--site-header-height) + var(--mode-switch-height) + rem(100)
    );
    height: calc(
      100% - var(--site-header-height) - var(--mode-switch-height) - rem(100)
    );
    will-change: transform;
    overflow: hidden;

    @include mq($from: vp3) {
      padding-top: calc(var(--site-header-height) + var(--mode-switch-height));
      height: calc(
        100% - var(--site-header-height) - var(--mode-switch-height)
      );
    }
  }

  &__section-wrapper {
    max-height: 100%;
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
    padding-bottom: rem(50);
    padding-left: 25vw;
    padding-right: 25vw;
    box-sizing: border-box;
  }

  &__logo {
    position: absolute;
    top: 18.5vh;
    left: rem(50);
    z-index: 1;
    @include mq($from: vp3) {
      top: 30vh;
      left: rem(107);
    }
    @include mq($from: vp4) {
      top: 36vh;
      left: 18.5vw;
    }
    @include mq($from: vp5) {
      left: -15vw;
      top: 44vh;
    }
    @include mq($from: vp6) {
      top: 45vh;
    }
    @include mq($from: vp7) {
      top: 49vh;
    }
    @include mq($from: vp8) {
      top: 51vh;
    }
    svg {
      fill: var(--c-b-grey4);
      width: 25vmin;
      height: 25vmin;
      @include mq($from: vp3) {
        width: 13vmin;
        height: 13vmin;
      }
      @include mq($from: vp5) {
        width: 11vmin;
        height: 11vmin;
      }
    }
  }

  &__headline {
    position: fixed;
    top: calc(var(--site-header-height) + var(--mode-switch-height));
    left: rem(10);
    z-index: 3; // CHANGE LATER ??

    pointer-events: none;
    color: var(--c-b-white);

    @include scaledHeadline();

    small {
      position: absolute;
      top: 0;
      font-size: rem(10);
    }

    span {
      display: block;
      position: relative;

      white-space: nowrap;
    }

    &__secondary {
      span {
        position: absolute;
        top: 100%;
        left: 0.7em;
      }
    }

    &__year-decade {
      position: absolute;
    }

    // just to add space and place the quotation correct
    &__ghost-decade {
      opacity: 0;
    }
  }
}
