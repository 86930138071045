@mixin animated-underline($no-hover: false) {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 100%;
    height: rem(1);
    background: currentColor;
    transition: right var(--default-transition);
  }
  @if $no-hover {
  } @else {
    &:hover {
      &:before {
        right: 0;
      }
    }
  }
}
