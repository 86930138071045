@import "@scss/base";

.pdp-product-viewer {
  $module: ".pdp-product-viewer";

  position: relative;
  align-self: flex-start;

  @include mq($from: vp5) {
    height: 100%;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--c-t-beige);

    position: relative;

    @include mq($from: vp5) {
      height: 100%;
      padding: 0;
    }

    h6 {
      position: absolute;
      z-index: 2;
      top: rem(10);
      right: rem(10);
      text-align: right;
      font-size: rem(10);
      line-height: 1.636;

      @include mq($from: vp5) {
        top: rem(20);
        right: rem(20);
      }
    }
  }

  &__simple {
    width: 100%;
    height: 100%;

    .pdp-product-images {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .basic-link {
    @include fontStyle(paragraph-4);
    position: absolute;
    top: rem(20);
    left: rem(20);
    align-items: center;

    display: none;

    @include mq($from: vp5) {
      display: inline-flex;
    }

    &.right {
      left: initial;
      right: rem(20);
    }

    .icon {
      display: inline-flex;
      align-items: center;
    }

    .svg-icon {
      width: rem(20);
      height: rem(20);
    }
  }

  &__guide {
    z-index: 1;
    position: absolute;
    bottom: rem(10);
    left: rem(20);
    right: rem(20);

    @include mq($from: vp3) {
      bottom: rem(15);
    }

    @include mq($from: vp4) {
      bottom: rem(20);
    }

    display: flex;
    justify-content: center;

    div {
      display: inline-flex;
      align-items: center;
      margin-right: rem(5);
      margin-left: rem(5);
      font-size: rem(10);

      span+span {
        margin-left: rem(10);
      }
    }

    .pdp-webar-button{
      text-decoration: underline;
    }

    .pdp-download-images,
    .pdp-webar-button {
      display: none;

      @include mq($from: vp5) {
        display: inline;
      }

      &.is-styled-image{
        display: inline;
      }
    }

  }
}