@import "@scss/base";

.product-line {
  $module: ".product-line";
  padding: rem(20) 0;
  border-bottom: rem(1) solid var(--c-b-grey3);

  display: grid;
  grid-template-columns: 1fr rem(50) minmax(60%, 1fr) 1fr;
  grid-gap: rem(20);

  &__image {
    background-color: var(--c-t-beige);
    flex: 0 0 rem(120);
    display: block;

    height: 0;
    padding-bottom: 100%;

    position: relative;
    .product-image {
      position: absolute;
      top: 0;
      left: 0;
      img {
        padding: 15% 6%;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__quantity {
    flex: 0 0 rem(40);
    @include fontStyle(paragraph-2);
  }

  &__text {
    flex: 1 1 rem(250);
    h3,
    p {
      @include fontStyle(paragraph-2);
    }
    h6 {
      @include fontStyle(paragraph-3);
    }
    strong {
      font-weight: $weight-medium;
    }
  }

  &__prices {
    flex: 1 1 rem(120);
    text-align: right;
    @include fontStyle(paragraph-2);
  }
}
