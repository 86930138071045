.page-enter-active {
  transition: all 1s ease-out;
}

.page-exit-active {
  transition: all 1s ease-out;
}

.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

// fade in is used on text items that are rendered after initial render
.animation--fade-in {
  display: block;
  animation: fadeIn ease 0.6s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes foldDown {
  0% {
    opacity: 0.5;
    transform: translateY(rem(-10)) scaleY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}

@keyframes foldUp {
  0% {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }

  100% {
    transform: scaleY(0);
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.loader-dots {
  display: inline-flex;
  flex: 0 0 rem(40);
  align-items: center;
  justify-content: center;

  span {
    position: relative;
    display: inline-block;
    width: rem(8);
    height: rem(8);
    border-radius: rem(4);
    background-color: var(--c-b-grey3);
    color: var(--c-b-grey3);
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: rem(8);
      height: rem(8);
      border-radius: rem(4);
      background-color: var(--c-b-grey3);
      color: var(--c-b-grey3);
      animation: dot-flashing 1s infinite alternate;
    }
    &:before {
      left: rem(-15);
      animation-delay: 0s;
    }

    &:after {
      left: rem(15);
      animation-delay: 1s;
    }
  }
}
@keyframes dot-flashing {
  0% {
    background-color: var(--c-b-grey3);
  }
  50%,
  100% {
    background-color: var(--c-b-grey4);
  }
}
