@import "@scss/base";

.qrcode-modal {
    $module: ".qrcode-modal";

    &__content {
        display: flex;
        flex-direction: column;

        align-items: center;
        padding: rem(30) rem(40);

        h3 {
            @include fontStyle(heading-3);
            margin-bottom: rem(20);
        }

        p {
            @include fontStyle(paragraph-3);
            margin-bottom: rem(20);
        }

        .text {
            text-align: center;
        }

        .action-button {
            margin-top: auto;
        }

        .button-modal{
            display: flex;
            justify-content: center;
            width: 100%;
            .action-button{
                margin-right: 15px;
            }
        }
    }
}