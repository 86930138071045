@import "@scss/base";
@import "./vision-slider-gallery";

.vision-slider-slide {
  $module: ".vision-slider-slide";

  &__media {
    @include grid;

    margin-bottom: rem(40);
    aspect-ratio: 16 / 9;

    .media-focal {
      @include gridItem(1, 12);
      @include offsetContent(left);
      @include mq($until: vp3) {
        left: rem(-25) !important;
        margin-left: 0 !important;
      }

      @include mq($from: vp5) {
        @include gridItem(1, 24);
        margin-left: 0;
      }
    }
  }
  &__texts {
    @include grid;
  }
  &__headline {
    @include fontStyle(heading-3);
    margin-bottom: rem(20);

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    opacity: 0;
    transform: translateY(-10px);

    transition: all var(--default-transition) 0.2s;

    @include gridItem(2, 10);
    @include mq($from: vp5) {
      @include gridItem(1, 22);
    }
    grid-row: 1;
  }
  &__bodytext {
    margin-bottom: rem(90);
    @include fontStyle(paragraph-2);

    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    opacity: 0;
    transform: translateY(-10px);

    transition: all var(--default-transition) 0.3s;

    @include gridItem(2, 8);
    grid-row: 2;

    @include mq($from: vp5) {
      @include gridItem(1, 12);
    }
    span div,
    .action-button {
      margin-top: rem(20);
    }

    .action-button {
      .svg-icon {
        transform: rotate(90deg);
      }
    }

    &__inner {
      position: relative;
    }
  }
  &__fade-out-div {
    position: absolute;
    pointer-events: none;
    height: rem(150);
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(var(--vision-slider-bgc-a), var(--vision-slider-bgc));
  }
  &__quote {
    display: none;
    text-align: right;

    @include gridItem(2, 10);
    grid-row: 3;
    @include mq($from: vp5) {
      @include gridItem(12, 12);
    }

    h2 {
      @include fontStyle(heading-3);
      text-transform: none;
      font-size: rem(35) !important;
    }
    h5 {
      margin-top: rem(25);
      @include fontStyle(paragraph-1);
    }
  }
  &__gallery {
    display: none;

    margin-top: rem(90);

    @include mq($from: vp3) {
      justify-content: space-between;
    }
  }

  width: 100%;
  box-sizing: border-box;
  max-height: 25vh;

  @include mq($from: vp3) {
    max-height: 50vh;
  }
  @include mq($from: vp7) {
    max-height: 60vh;
  }

  &.swiper-slide-active {
    max-height: none;

    #{$module}__headline,
    #{$module}__bodytext {
      opacity: 1;
      transform: translateY(0);
    }
    #{$module}__quote {
      display: block;
    }
    #{$module}__gallery {
      display: block;
      @include mq($from: vp3) {
        display: flex;
      }
    }
  }

  &.swiper-slide-prev {
    opacity: 0;
  }
}
