@import "@scss/base";

.pdp-swatch-group {
  $module: ".pdp-swatch-group";

  display: grid;
  grid-template-columns: rem(100) 1fr;
  grid-column-gap: rem(20);
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  @include mq($from: vp4) {
    grid-template-columns: rem(200) 1fr;
  }

  &__image {
    position: relative;
    height: 100%;
    width: rem(100);
    @include mq($from: vp4) {
      width: rem(200);
      flex: 0 0 rem(200);
    }
    box-sizing: border-box;
    transition: opacity var(--default-transition);

    @include shimmer;

    .product-image {
      img {
        display: block;
        max-width: 100%;
        min-height: rem(100);
      }
      &--error {
        img {
          object-fit: contain;
        }
      }
    }
  }

  &__tag {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: rem(4) rem(8);
    @include fontStyle(trumpet-2);
  }

  &.is-active {
    #{$module}__image {
      border: rem(2) solid var(--c-b-black);
    }
  }

  // &.is-unallowed {
  //   filter: grayscale(1);
  //   #{$module}__image {
  //     opacity: 0.5;

  //     &:after {
  //       content: "🚫";
  //       opacity: 0.8;
  //       font-size: rem(30);
  //       position: absolute;
  //       top: rem(5);
  //       right: rem(10);
  //       display: inline-flex;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   }
  // }

  &:hover {
    #{$module}__image {
      opacity: 0.7;
    }
    h4 {
      span:before {
        right: 0;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    min-height: rem(75);
    @include mq($from: vp5) {
      min-height: rem(100);
    }

    h4 {
      display: flex;
      overflow: hidden;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include fontStyle(heading-5);
        @include animated-underline();
      }
    }
    p {
      margin-top: rem(10);
      @include fontStyle(paragraph-3);
    }

    footer {
      display: flex;
      margin-top: rem(10);

      @include mq($from: vp5) {
        margin-top: auto;
      }

      span {
        flex: 0 0 rem(15);
        height: rem(15);
        margin-right: rem(8);
        display: inline-flex;
        img {
          display: inline-flex;
          max-width: 100%;
        }
      }
      h5 {
        display: inline-flex;
        @include fontStyle(paragraph-3);
        white-space: nowrap;
        min-width: 0;

        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
}
