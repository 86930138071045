@import "@scss/base";

.rich-text-module {
  $module: ".rich-text-module";

  --rich-text-module-c: var(--c-b-black);
  color: var(--rich-text-module-c);

  --rich-text-module-bgc: var(--c-b-white);
  background: var(--rich-text-module-bgc);

  &--inverted {
    --rich-text-module-c: var(--c-b-white);
  }

  &__content {
    overflow: hidden;
  }

  &__content--initial {
    position: relative;
  }
  &__fade-out-div {
    position: absolute;
    pointer-events: none;
    height: 30vh;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(255,255,255,0.2), var(--rich-text-module-bgc));
  }
  &__read-more-button {
    .svg-icon {
      transform: rotate(90deg);
    }
  }

  &--expanded {
    #{$module}__fade-out-div {
      display: none;
    }
  }
}
