@import "@scss/base";

.register-products {
  $module: ".account-details";
  @include container;
  @include grid;
  @include verticalSpacing(true);
  @include verticalSpacing;

  &.my-fritzhansen{
    padding-top: unset;
    padding-bottom: unset;
    margin-top: unset;
    margin-bottom: unset;
    @include verticalSpacing(false,false);
    .select{
      &::after{
        top: rem(55);
      }
    }
  }

  &__headline {
    grid-column: 3 / span 14;
    @include fontStyle(heading-1);
    margin-bottom: rem(50);
  }

  &__radio-buttons {
    margin-bottom: rem(50);
  }

  &__form {
    grid-column:  1 / span 12;
    @include mq($from: vp4) {
        grid-column: 2 / span 8;
    }

    @include mq($from: vp5) {
        grid-column: 3 / span 9;
    }

    .radio+.radio {
      margin-top: rem(15);
    }

    hr {
      border: 0;
      margin: rem(60) 0 0;
      padding: 0;
    }
  }

  &__form-row {
    &--dual {
      display: flex;
      justify-content: space-between;

      >*:first-child {
        flex: 0 0 calc(40% - rem(25));
        margin-right: rem(25);
      }

      >*:last-child {
        flex: 0 1 60%;
      }
    }

    >p {
      margin-top: rem(10);
      @include fontStyle(paragraph-2);
      margin-bottom: rem(20);
    }

    &+.register-products__form-row {
      padding-top: rem(30);
      .not-empty-list{
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
      }
      .products-id-list:has(.not-empty-list){
        border: 1px;
        border-style: solid;
        border-color: black;
        margin-bottom: 1.875rem;
      }
    }

    .checkbox__box {
      border-radius: 50% 50%;

      &:after {
        border-radius: 50% 50%;
      }
    }
  }
}