@import "@scss/base";

.pdp-configurator-options {
  $module: ".pdp-configurator-options";

  h3 {
    @include fontStyle(heading-4);
    margin: rem(15) 0 rem(20);
  }

  &__inner {
    border-top: rem(1) solid var(--c-b-grey3);

    .collapsible {
      border-bottom: rem(1) solid var(--c-b-grey3);

      &.is-active {
        .pdp-configurator-options__collapsible-item {
          p {
            display: none;
          }
        }

        .svg-icon {
          transform: rotate(90deg);
        }
      }

      .svg-icon {
        transform: rotate(-90deg);
      }

      &__trigger {
        width: 100%;
      }

      &__content {
        padding-bottom: rem(14);
        @include fontStyle(paragraph-3);

        .is-active {
          text-decoration: underline;
        }
      }
    }
  }

  &__collapsible-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //border-bottom: rem(1) solid var(--c-b-grey3);
    padding-right: rem(1);
    padding: rem(14) 0;

    &:hover {

      p,
      h5 {
        opacity: 0.8;
      }
    }

    p,
    h5 {
      font-family: $font-primary;
      @include fontStyle(paragraph-3);
      text-align: left;
      transition: opacity var(--default-transition);
    }

    h5 {
      font-weight: $weight-medium;
    }

    svg {
      height: rem(35);
      flex: 0 0 rem(35);
      object-fit: contain;
      object-position: center;
    }
  }

  &__radio-list {
    h5 {
      padding-bottom: rem(5);
    }

    .radio {
      &__box {
        height: rem(20);
        flex: 0 0 rem(20);

        &:after {
          width: 100%;
          height: 100%;
        }

        &:checked {
          height: rem(20);
          width: rem(20);
        }
      }

      &__wrapper {
        align-items: center;
      }

      &__label {
        font-size: 13px;
      }
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: rem(1) solid var(--c-b-grey3);
    padding-right: rem(1);

    &:last-child {
      border-bottom: 0;
    }

    padding: rem(14) 0;

    cursor: pointer;

    &:hover {

      p,
      h5 {
        opacity: 0.8;
      }
    }

    .product-image,
    img {
      height: rem(35);
      flex: 0 0 rem(35);
      object-fit: contain;
      object-position: center;
    }

    div {
      flex: 0 1 70%;
    }

    p,
    h5 {
      font-family: $font-primary;
      @include fontStyle(paragraph-3);
      text-align: left;
      transition: opacity var(--default-transition);
    }

    h5 {
      font-weight: $weight-medium;
    }
  }
}