@import "@scss/base";

.nav-panel {
  grid-column: 1 / span 12;
  @include grid();
  @include container;
  grid-template-rows: auto auto;
  padding-bottom: 10vw;

  @include mq($from: vp5) {
    padding: 0;
    grid-column: 1 / span 24;
    grid-template-rows: auto;
    padding-bottom: rem(30);
  }
  &--interactive {
    &:hover .nav-panel__img {
      opacity: 0.7;
    }
  }

  &__img {
    grid-column: 1 / span 11;
    width: 100%;
    @include offsetContent(left);
    height: 58vw;
    object-fit: cover;
    transition: 200ms ease;
    @include mq($from: vp4) {
      grid-column: 1 / span 8;
      height: 42vw;
    }
    @include mq($from: vp5) {
      @include gridItem(3, 11, 1, 1);
      height: 26vw;
      width: auto;
      margin-left: 0;
    }
  }
  .text-tile {
    grid-column: 2 / span 10;
    padding-bottom: rem(20);
    padding-top: rem(30);
    @include mq($from: vp4) {
      grid-column: 2 / span 7;
      padding-top: rem(50);
    }
    @include mq($from: vp5) {
      @include gridItem(15, 8, 1, 1);
    }
  }
  // &__headline {
  //   h2 {
  //     @include fontStyle(heading-3);
  //     padding-bottom: rem(20);
  //     padding-top: rem(30);
  //     @include mq($from: vp5) {
  //       padding-top: rem(40);
  //     }
  //     @include mq($from: vp6) {
  //       padding-top: rem(50);
  //     }
  //   }
  // }
  // &__bodytext {
  //   p {
  //     @include fontStyle(paragraph-2);
  //     padding-bottom: rem(30);
  //   }
  // }
}
