@import "@scss/base";

.product-filters-list {
  $module: ".product-filters-list";

  @include menuOverlay();

  @include mq($from: vp5) {
    padding-top: rem(60);
  }

  &__header {
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 13px;
      text-transform: uppercase;
    }

    //border-bottom: rem(1) solid var(--c-b-grey3);
    //padding-bottom: rem(25);
    //margin-bottom: rem(20);

    &__close-icon {
      margin-left: rem(12);
      cursor: pointer;

      svg {
        transform: rotate(45deg);
        vertical-align: baseline;
      }
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    box-sizing: border-box;
    min-height: 85vh;
  }

  &__facets {
    flex: 1 1 50%;
    overflow-y: auto;
  }

  &__footer {
    margin-top: rem(50);
    display: flex;
    justify-content: space-between;
    .action-button{
      .text{
        font-size: 13px;
        font-weight: $weight-regular;
      }
    }
  }

  .collapsible {
    transition: padding-bottom var(--default-transition);

    &:last-child{
      padding-bottom: rem(20);
      border-bottom: rem(1) solid var(--c-b-grey3);
    }

    &__trigger {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      
      padding-top: rem(20);
      margin-top: rem(20);
      border-top: rem(1) solid var(--c-b-grey3);

      h3 {
        font-size: 13px;
        text-transform: uppercase;
      }

      span {
        transition: transform var(--default-transition);
        display: inline-flex;
      }

      &.is-active {
        span {
          transform: scaleY(-1);
        }
      }
    }
    &__content{
      padding-top: rem(25);
    }
  }

  .checkbox {
    padding: 0;
    margin-bottom: rem(20);

    &__box {
      margin: 0;
    }

    &__label {
      margin: 0;
      padding: 0;
      font-size: 13px;
      padding-left: rem(18);
    }
  }

  .action-button {
    flex: 0 0 calc(50% - rem(10));
  }
}