@import "@scss/base";

.pdp {
  $module: ".pdp";
  @include container;
  @include grid;
  @include verticalSpacing;

  // grid-template-rows: auto auto;
  grid-row-gap: rem(20);

  @include mq($from: vp3) {
    grid-row-gap: rem($container-horizontal-spacing-regular);
  }
  @include mq($from: vp7) {
    grid-row-gap: rem($container-horizontal-spacing-large);
  }
  @include mq($from: vp8) {
    grid-row-gap: rem($container-horizontal-spacing-xlarge/2);
  }

  .error {
    margin-top: rem(100);
    h2 {
      @include fontStyle(heading-2);
      margin-bottom: rem(20);
    }
    p {
      @include fontStyle(paragraph-2);
      line-height: rem(30);
    }
    @include gridItem(1, 12);

    @include mq($from: vp5) {
      @include gridItem(2, 16);
    }
  }

  .pdp-product-viewer {
    //@include fadeFromLeft(0.7);
  }
  .pdp-configurator {
    //@include fadeFromRight(0.7);
  }

  .pdp-product-thumbs,
  .pdp-product-viewer {
    @include gridItem(1, 12);

    @include mq($from: vp5) {
      @include gridItem(1, 12);
    }
    @include mq($from: vp6) {
      @include gridItem(1, 13);
    }
    @include mq($from: vp7) {
      @include gridItem(1, 15);
    }
  }

  .pdp-configurator,
  .pdp-delivery-info,
  .pdp-product-specification {
    @include gridItem(1, 12);

    @include mq($from: vp5) {
      @include gridItem(14, 11);
    }
    @include mq($from: vp6) {
      @include gridItem(15, 10);
    }
    @include mq($from: vp7) {
      @include gridItem(17, 8);
    }
  }
  .pdp-product-thumbs,
  .pdp-product-specification {
    @include mq($from: vp5) {
      padding-top: rem($container-horizontal-spacing-regular);
    }
    @include mq($from: vp7) {
      padding-top: rem($container-horizontal-spacing-large);
    }
    @include mq($from: vp8) {
      grid-row-gap: rem($container-horizontal-spacing-xlarge/2);
    }
  }
}
