@import "@scss/base";

.basket {
	$module: '.basket';

	@include container;
	@include verticalSpacing;

	.mini-basket {
		height: auto;
	}
}
