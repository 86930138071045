@import "@scss/base";

.site-search {
  $module: ".site-search";

  @include menuOverlay;

  position: relative;

  &__form {
    @include mq($from: vp5) {
      padding-top: rem(60);
    }

    input {
      text-transform: uppercase;
      flex: 1;

      @include mq($from: vp5) {
        font-size: rem(28);

        &::placeholder {
          font-size: rem(28);
        }
      }
    }
  }

  &__links {
    margin-top: rem(100);
    max-width: rem(300);
    margin-right: auto;

    &-title {
      @include fontStyle(paragraph-2);
      display: block;
      font-weight: 500;
      margin-bottom: rem(20);
    }

    .mega-menu__list {
      display: block;
      padding: 0;

      .mega-menu__button {
        //@include fontStyle(heading-4);
        font-size: rem(14);
      }
    }
  }

  &__list {
    margin-top: rem(40);
    @include mq($from: vp5) {
      margin-top: rem(70);
    }

    &__title {
      @include fontStyle(heading-3);
      margin-bottom: rem(25);
    }

    ul {
      @include mq($from: vp5) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: rem(20);

        li {
          grid-column: span 1;
        }
      }
    }
  }

  &__filter-list {  
    @include mq($until: vp3) {
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: rem(40);
    }

    @include mq($from: vp3){
      top: rem(112);
      right: rem(20);
    }

    @include mq($from: vp5){
      top: rem(171);
      right: rem(60);
    }

    top: 0;
    right: 0;
    display: block;
    position: absolute;

    ul {
      @include mq($until: vp2) {
        flex-wrap: wrap;
      }   
      display: flex;
    }
  }

  &__filter-item {
    &:nth-child(1){
      margin-right: rem(10);
      @include mq($until: vp2) {
        margin-bottom: rem(10);
      }   
    }
  }

  .site-search-tile {
    padding-bottom: rem(20);
    border-bottom: rem(1) solid var(--c-b-grey3);
    margin-bottom: rem(45);

    &__image {
      position: relative;
      height: 0;
      width: 100%;
      display: block;
      overflow: hidden;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        transform-origin: center;
        transition: transform var(--default-transition);
      }
    }

    &__text {
      h4 {
        @include fontStyle(heading-4);
        margin-top: rem(20);
        margin-bottom: rem(30);
      }
    }

    &:hover {
      #{$module}-tile__image {
        img {
          transform: scale(1.05);
        }
      }
    }

    &--page {
      #{$module}-tile__image {
        padding-bottom: 66%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        object-fit: cover;
      }

      p {
        @include fontStyle(paragraph-2);
      }
    }

    &--product {
      #{$module}-tile__image {
        background-color: var(--c-t-beige);
        padding-bottom: 100%;

        background-color: var(--c-t-beige);
        position: relative;
        height: 0;
        padding-bottom: 100%;

        .product-image {
          position: absolute;
          top: 0;
          left: 0;

          img {
            box-sizing: border-box;
            padding: 15% 6%;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      p {
        @include fontStyle(paragraph-3);
      }
    }
  }
}