@import "@scss/base";

.content-modal {

    &__close-icon {
        position: absolute;
        top: rem(8);
        right: rem(8);

        @include mq($from: vp3) {
            top: rem(10);
            right: rem(10);
        }

        @include mq($from: vp6) {
            top: rem(18);
            right: rem(18);
        }

        font-size: rem(32);
        z-index: 1;
        cursor: pointer;

        svg {
            stroke: var(--c-b-black);
        }
    }

    &__content {
        display: flex;
        position: relative;

        @include mq($until: vp7) {
            flex-direction: column-reverse;
        }
    }

    &__item {
        flex: 0 1 50%;

        &.no-image{
            flex: auto;
        }
        
        &.image-item {
            .media-focal {
                @include mq($until: vp7) {
                    height: 25.5vh;
                }
            }
        }

        h3 {
            font-size: rem(16);

            @include mq($from: vp6) {
                font-size: rem(26);
            }

            @include mq($from: vp5) {
                font-size: rem(20);
            }

            margin: 0 rem(75);
            padding-bottom: rem(15);

            @include mq($from: vp6) {
                padding-bottom: rem(35);
            }

            text-align: center;
            text-transform: uppercase;
        }

        p {
            font-size: rem(10);

            @include mq($from: vp4) {
                font-size: rem(13);
            }

            margin: 0 rem(50);
            text-align: center;
        }
    }

    &__left-item {
        margin: rem(35) 0;

        @include mq($from: vp7) {
            margin: rem(100) 0;
        }

        &__body {
            padding-bottom: rem(30);
        }

        &__buttons {
            display: flex;

            @include mq($from: vp6) {
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }

            justify-content: space-evenly;
            margin: 0 rem(27);

            .action-button {
                width: rem(120);
                padding: rem(8) rem(12);

                @include mq($from: vp2) {
                    width: rem(140);
                }

                @include mq($from: vp3) {
                    width: rem(160);
                }

                @include mq($from: vp4) {
                    width: rem(200);
                    padding: rem(10) rem(10);
                }

                &:last-child {
                    @include mq($from: vp6) {
                        margin-top: rem(10);
                    }
                }

                .text {
                    @include mq($until: vp6) {
                        font-size: rem(10);
                    }
                }
            }
        }
    }
}