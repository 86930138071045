@import "@scss/base";

.pdp-product-specification {
  $module: ".pdp-product-specification";
  display: block;

  @include mq($from: vp5) {
    display: none;
  }

  &__note {
    @include fontStyle(paragraph-3);
    color: var(--c-b-grey2);
    margin-bottom: rem(40);
  }

  .collapsible {
    border-top: 1px solid lighten(black, 80%);
    width: 100%;

    &.is-active {
      padding-bottom: rem(25);
    }

    &:last-child {
      border-bottom: 1px solid lighten(black, 80%);
    }

    &__trigger {
      @include fontStyle(paragraph-1);
      font-weight: 400;
      padding: rem(20) 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-transform: uppercase;

      #{$module}__icon {
        transform: rotate(90deg);
      }
    }
  }

  &__table {
    width: 100%;
    margin: 0 0 rem(25);

    table-layout: auto;
    text-align: left;
    @include fontStyle(paragraph-2);

    thead {
      font-weight: $weight-medium;

      tr {
        border-bottom: rem(1) solid var(--c-b-black);
      }
    }

    th {
      @include fontStyle(pdp-specification-text);
      padding: rem(5) 0 rem(5);
    }

    td {
      @include fontStyle(pdp-specification-text);
      padding: rem(5) 0 rem(5);
      text-align: right;
    }
  }

  &__link {
    @include fontStyle(pdp-specification-text);
    display: block;
    text-decoration: underline;
  }

  &__link--not-in-table {
    margin: rem(15) 0;
  }

  &__designer-link {
    margin-bottom: 1px;

    .icon {
      .svg-icon {
        transform: none;
      }
    }
  }

  &__bodytext {
    @include fontStyle(pdp-specification-text);

    h4 {
      font-weight: $weight-medium;
    }
    a {
      display: block;
      text-decoration: underline;
    }
  }

  &__bodytext--bottom {
    margin: rem(20) 0;
  }

  &__image {
    width: 100%;

    @include mq($from: vp5) {
      width: 45%;
    }

    height: auto;
  }

  &__dimension-filter {
    ul {
      justify-content: flex-end;
      display: flex;
    }
  }

  &__dimension-filter-item {
    @include fontStyle(pdp-specification-text);
    opacity: 0.6;

    &.active {
      font-weight: $weight-medium;
      opacity: 1;

      button {
        text-decoration: underline;
      }
    }

    &:nth-child(1) {
      margin-right: rem(10);
    }
  }

  &__about {
    h4 {
      @include fontStyle(pdp-specification-text);
      font-weight: $weight-medium;
      margin-bottom: rem(10);
    }

    p {
      @include fontStyle(pdp-specification-text);
      margin-bottom: rem(30);
    }
  }

  // .nav-button {
  //   display: none;
  //   @include mq($from: vp5) {
  //     display: inline-block;
  //     margin: rem(30) 0;
  //   }
  // }

  .pdp-delivery-info {
    margin-bottom: rem(20);
  }
}