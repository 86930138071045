$font-primary: "HansenGrotesque", sans-serif;

$default-lineheight: 1.4;

$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

// Example
// style-name: (
//   ff: $header-font,      // font-family
//   fw: $weight-bold,      // font-weight
//   size: 20,              // font-size
//   lh: 24,                // line-height
//   ls: 1,                 // letter-spacing
//   tt: uppercase,         // text-transform
//   fs: italic,            // font-style
//   medium: (
//     size: 18,
//     lh: 22
//   ),
//   small: (
//     size: 16,
//     lh: 20
//   )
// )
// usage: @extend %t-[style-name];
// usage: @include t-[style-name]

// NB!!! Line height is annotated in pixels, and calculated against the font-size to make a unitless value.
// Eg. size: 28, lh: 28 =  28/28 = 1 line-height
//

$typography-vars: (
  heading-hero-1: (
    ff: $font-primary,
    sz: 30,
    lh: 40,
    tt: uppercase,
    fw: $weight-regular,
    breakpoints: (
      vp4: (
        sz: 35,
        lh: 45,
      ),
      vp5: (
        sz: 35,
        lh: 45,
        szf: clamped(35, 50, vp5),
      ),
      vp9: (
        sz: 80,
        lh: 95,
        ls: -1,
      ),
    ),
  ),
  heading-1: (
    ff: $font-primary,
    sz: 30,
    lh: 40,
    tt: uppercase,
    fw: $weight-regular,
    breakpoints: (
      vp4: (
        sz: 35,
        lh: 45,
      ),
      vp5: (
        sz: 35,
        lh: 45,
        szf: clamped(35, 80, vp5),
      ),
      vp9: (
        sz: 80,
        lh: 95,
        ls: -1,
      ),
    ),
  ),
  heading-2: (
    ff: $font-primary,
    sz: 22,
    lh: 29,
    tt: uppercase,
    fw: $weight-regular,
    breakpoints: (
      vp5: (
        sz: 26,
        lh: 33,
        szf: clamped(26, 36, vp5),
      ),
      vp9: (
        sz: 60,
        lh: 78,
        ls: -1,
      ),
    ),
  ),
  heading-3: (
    ff: $font-primary,
    sz: 22,
    lh: 29,
    tt: uppercase,
    fw: $weight-regular,
    breakpoints: (
      vp5: (
        sz: 22,
        lh: 29,
        szf: clamped(22, 28, vp5),
      ),
      vp7: (
        sz: 28,
        lh: 36,
        szf: clamped(28, 32, vp7),
      ),
      vp8: (
        sz: 32,
        lh: 42,
        szf: clamped(32, 38, vp8),
      ),
      vp9: (
        sz: 38,
        lh: 49,
      ),
    ),
  ),
  heading-4: (
    ff: $font-primary,
    sz: 16,
    lh: 24,
    tt: uppercase,
    fw: $weight-regular,
    breakpoints: (
      vp5: (
        sz: 16,
        lh: 24,
        szf: clamped(16, 20, vp5),
      ),
      vp7: (
        sz: 20,
        lh: 28,
        szf: clamped(20, 22, vp7),
      ),
      vp8: (
        sz: 22,
        lh: 29,
        szf: clamped(22, 25, vp8),
      ),
      vp9: (
        sz: 25,
        lh: 34,
      ),
    ),
  ),
  heading-5: (
    ff: $font-primary,
    sz: 14,
    lh: 17,
    tt: uppercase,
    fw: $weight-regular,
    breakpoints: (
      vp5: (
        sz: 16,
        lh: 19,
      ),
      vp7: (
        sz: 18,
        lh: 22,
      ),
    ),
  ),
  trumpet-1: (
    ff: $font-primary,
    sz: 11,
    lh: 13,
    tt: uppercase,
    fw: $weight-regular,
  ),
  trumpet-2: (
    ff: $font-primary,
    sz: 10,
    lh: 16,
    tt: uppercase,
    fw: $weight-regular,
  ),
  trumpet-3: (
    ff: $font-primary,
    sz: 12,
    lh: 25,
    tt: uppercase,
    fw: $weight-bold,
  ),
  paragraph-1: (
    ff: $font-primary,
    sz: 16,
    lh: 25,
    fw: $weight-regular,
    breakpoints: (
      vp5: (
        sz: 18,
        lh: 28,
      ),
      vp8: (
        sz: 20,
        lh: 31,
      ),
      vp9: (
        sz: 22,
        lh: 34,
      ),
    ),
  ),
  paragraph-2: (
    ff: $font-primary,
    sz: 15,
    lh: 22,
    fw: $weight-regular,
    breakpoints: (
      vp8: (
        sz: 15,
        lh: 25,
      ),
      vp9: (
        sz: 18,
        lh: 28,
      ),
    ),
  ),
  paragraph-3: (
    ff: $font-primary,
    sz: 13,
    lh: 18,
    fw: $weight-regular,
    breakpoints: (
      vp8: (
        sz: 13,
        lh: 20,
      ),
      vp9: (
        sz: 13,
        lh: 24,
      ),
    ),
  ),
  paragraph-4: (
    ff: $font-primary,
    sz: 10,
    lh: 18,
    fw: $weight-regular,
    breakpoints: (
      vp8: (
        sz: 10,
        lh: 20,
      ),
      vp9: (
        sz: 10,
        lh: 24,
      ),
    ),
  ),
  link-1: (
    ff: $font-primary,
    sz: 14,
    lh: 22,
    fw: $weight-medium,
    tt: uppercase,
    breakpoints: (
      vp8: (
        sz: 16,
        lh: 25,
      ),
      vp9: (
        sz: 18,
        lh: 28,
      ),
    ),
  ),
  link-2: (
    ff: $font-primary,
    sz: 13,
    lh: 13,
    fw: $weight-medium,
  ),
  pdp-specification-text: (
    ff: $font-primary,
    sz: 13,
  ),
);
