@import "@scss/base";

.portfolio-admin {
  $module: ".portfolio-admin";

  @include menuOverlay;

  &__header {
    @include mq($from: vp5) {
      padding-top: rem(60);
    }
    position: relative;
    margin-bottom: rem(30);

    h1 {
      @include fontStyle(heading-1);
    }

    .success {
      position: absolute;
      top: calc(100% - 1em);
      right: 0;
      @include fontStyle(trumpet-3);
    }
  }

  &__create {
    margin: rem(40) auto 0;
    border: rem(1) dashed var(--c-b-grey2);
    display: flex;
    width: 100%;
    height: rem(75);
    max-width: rem(360);
    align-items: center;
    justify-content: center;

    @include fontStyle(trumpet-3);
    font-weight: 500;
    .icon {
      margin-right: rem(10);
    }
  }

  .portfolio-admin-list {
    li + li {
      .portfolio-admin-list-item {
        border-top: 0;
      }
    }
  }

  .portfolio-admin-list-item {
    position: relative;

    --portfolio-item-border-color: var(--c-b-grey3);
    border-bottom: rem(1) solid var(--portfolio-item-border-color);

    &--create {
      border-bottom: rem(1) solid var(--portfolio-item-border-color);
      --portfolio-item-border-color: var(--c-b-black);
    }

    @include fontStyle(paragraph-2);

    &:first-child {
      border-top: rem(1) solid var(--portfolio-item-border-color);
    }

    &__inner {
      display: flex;
      width: 100%;
      align-items: center;
      height: rem(60);
      width: 100%;
    }

    a {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .count {
      @include fontStyle(paragraph-3);
    }
    .name {
      @include fontStyle(paragraph-1);
    }

    .icon {
      margin-left: rem(10);
    }

    .icon,
    .name,
    .count,
    .input {
      position: relative;
      margin-right: rem(20);
    }

    .input {
      flex: 1 1 40%;

      appearance: none;
      border: 0;
      padding: 0;
      @include fontStyle(paragraph-2);
    }
    .save {
      text-decoration: underline;
      margin-left: auto;
    }

    .menu-button {
      width: rem(35);
      height: rem(20);
      background-color: var(--c-b-white);
      color: var(--c-b-grey2);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }
    .action-menu {
      top: rem(35);
      right: rem(10);
      right: rem(10);
    }
  }
}
