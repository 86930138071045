@import "@scss/base";

.action-menu {
  $module: ".action-menu";

  background-color: var(--c-b-white);
  position: absolute;
  top: 100%;
  right: 0;
  border: rem(1) solid var(--c-b-grey4);
  z-index: 1000;
  transform-origin: top right;
  opacity: 0;
  transform: scaleY(0);

  transition: all var(--default-transition);

  &.is-active {
    opacity: 1;
    transform: scaleY(1);
    box-shadow: 0 rem(4) rem(4) rgba(0, 0, 0, 0.25);
  }

  &__item {
    width: 60vw;
    max-width: rem(210);
    box-sizing: border-box;
    overflow: hidden;

    & + & {
      border-top: rem(1) solid var(--c-b-grey3);
    }
    &__inner {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      transition: transform var(--default-transition);
    }

    &.is-prompting &__inner {
      transform: translateX(-100%);
    }

    .action {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 rem(15);

      height: rem(50);
      flex: 0 0 100%;

      .icon {
        margin-right: rem(20);
        width: 1em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      @include fontStyle(trumpet-1);
    }
    .prompt {
      box-sizing: border-box;
      padding: 0 rem(15);
      height: rem(50);
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .confirm {
        display: inline-flex;
        padding: rem(8) rem(13);
        background-color: var(--c-s-error1);
        color: var(--c-b-white);
      }
      .cancel {
        text-decoration: underline;
      }
    }
  }
}
