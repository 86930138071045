@import "@scss/base";

.country-selector {
  $module: ".country-selector";
  display: grid;

  --country-selector-bgc: transparent;

  background-color: var(--country-selector-bgc);

  grid-gap: rem(15);

  @include mq($from: vp4) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(15);
  }

  .select {
    &__selector {
      @include mq($from: vp4) {
        margin-bottom: 0;
        height: 100%;
      }
    }
  }

  .action-button {
    margin: rem(5) 0 rem(13);
  }

  .notInMarketText {
    @media screen and (min-width: 500px) {
      grid-column: span 3;
    }
    a {
      text-decoration: underline;
    }
  }
}