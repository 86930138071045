$container-horizontal-spacing-small: 10;
$container-horizontal-spacing-regular: 30;
$container-horizontal-spacing-large: 60;
$container-horizontal-spacing-xlarge: 140;
$container-horizontal-spacing-xxlarge: 220;

$container-horizontal-spacing-values-fluid: (
  vp3: clamped($container-horizontal-spacing-regular, $container-horizontal-spacing-large, vp3),
  vp7: clamped($container-horizontal-spacing-large, $container-horizontal-spacing-xlarge, vp7),
  vp8: clamped($container-horizontal-spacing-xlarge, $container-horizontal-spacing-xxlarge, vp8),
  vp9: rem($container-horizontal-spacing-xxlarge),
);

$container-vertical-spacing-xsmall: 90;
$container-vertical-spacing-small: 100;
$container-vertical-spacing-regular: 120;
$container-vertical-spacing-large: 140;
$container-vertical-spacing-xlarge: 160;
$container-vertical-spacing-xxlarge: 200;

$container-vertical-spacing-values-fluid: (
  vp4: clamped($container-vertical-spacing-small, $container-vertical-spacing-regular, vp4),
  vp5: clamped($container-vertical-spacing-regular, $container-vertical-spacing-large, vp5),
  vp7: clamped($container-vertical-spacing-large, $container-vertical-spacing-xlarge, vp7),
  vp8: clamped($container-vertical-spacing-xlarge, $container-vertical-spacing-xxlarge, vp8),
  vp9: rem($container-vertical-spacing-xxlarge),
);
