@import "@scss/base";

.flowbox-slider {
  $module: ".flowbox-slider";
  @include container;
  @include verticalSpacing;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__headline {
    margin: 0 auto;
    @include fontStyle(heading-3);
    padding-bottom: rem(30);
  }
  &__button {
    display: flex;
    justify-content: center;
    padding-top: rem(30);
  }

  .bzfy-c-carousel-control,
  .bzfy-c-mobile-circle {
    background: var(--c-b-black) !important;
  }
}
