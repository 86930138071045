@mixin scaledHeadline() {
  transform-origin: top left;
  transform: scale(0.4);
  @include mq($from: vp2) {
    transform: scale(0.5);
  }
  @include mq($from: vp4) {
    transform: scale(0.8);
  }
  @include mq($from: vp5) {
    transform: scale(0.85);
  }
  @include mq($from: vp7) {
    transform: scale(1.2);
  }

  span {
    color: var(--c-b-white);
    display: inline-flex;
    text-align: right;

    font-family: $font-primary;
    font-size: rem(200);
    line-height: 1;
  }
  em,
  strong {
    display: inline-flex;
    justify-content: center;
  }
  em {
    width: 0.7em;
    max-width: 0.7em;
  }
  strong {
    width: 0.4em;
    max-width: 0.4em;
  }
}
