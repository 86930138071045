@import "@scss/base";

.contact-links-group {
  $module: ".contact-links-group";
  display: block;

  &__item {
    display: block;
    a {
      @include fontStyle(paragraph-1);
      font-weight: 400;
      text-decoration: underline;
    }
  }

  &__item + &__item {
    margin-top: 0.5em;
  }
}
