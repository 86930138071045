@import "@scss/base";

.pdp-swatch-tile {
  $module: ".pdp-swatch-tile";
  max-width: rem(400); //to ensure SB visibility
  cursor: pointer;
  min-width: 0; //to avoid text flowing out and getting ellipsis to work

  .product-image {
    position: static;
  }
  &__inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(30);
    border: rem(2) solid transparent;
    transition: opacity var(--default-transition);
    min-height: rem(123);
    position: relative;
    width: 100%;

    @include shimmer;
  }
  &__tag {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: rem(4) rem(8);
    @include fontStyle(trumpet-2);
  }

  &__message {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    @include fontStyle(paragraph-3);
    padding: rem(10);
  }

  &.is-unallowed {
    #{$module}__message {
      display: block;
    }
  }

  &.is-active {
    #{$module}__inner {
      border: rem(2) solid var(--c-b-black);
    }
  }

  &.no-image {
    #{$module}__inner {
      background: #efefef;
      &:before {
        display: none;
      }
      img {
        object-fit: contain;
      }
    }
  }

  h6 {
    margin-top: rem(10);
    @include fontStyle(paragraph-3);
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;

    // span {
    //   @include animated-underline(true);
    // }
  }

  &:hover {
    #{$module}__inner {
      opacity: 0.7;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  &--cover {
    #{$module}__inner {
      padding: 0;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
