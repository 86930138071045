@import "@scss/base";

.order-calculation {
  $module: ".order-calculation";
  p,
  h5 {
    @include fontStyle(paragraph-2);
  }
  h5 {
    font-weight: $weight-medium;
  }
  &__section {
    padding: rem(20) 0;
    border-bottom: rem(1) solid var(--c-b-grey3);

    div {
      display: flex;
      justify-content: space-between;
      p,
      h5 {
        &:first-child {
          flex: 1 0 60%;
          max-width: 60%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      h5 {
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }
}
