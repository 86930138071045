@import "@scss/base";

.pdp-material-selector {
  $module: ".pdp-material-selector";
  display: flex;
  flex-direction: column;
  max-height: 100%;

  border-top: rem(1) solid var(--c-b-grey3);

  h3 {
    @include fontStyle(heading-4);
    margin: rem(15) 0 rem(20);
    flex: 0 1 rem(40);
  }
  h5 {
    @include fontStyle(paragraph-2);
    flex: 1;
  }
  &__content {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100% - rem(60));
    @include custom-scrollbar;
    padding-right: rem(20);
  }
  &__info {
    margin-bottom: rem(20);
    @include mq($from: vp4) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__tiles {
    display: grid;
    grid-gap: rem(20);
    padding-bottom: rem(20);
    align-items: flex-start;

    grid-template-columns: 1fr 1fr;
    @include mq($from: vp4) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
