@import "@scss/base";

.info-panel {
  $module: ".info-panel";
  border-bottom: rem(1) solid var(--c-b-grey3);
  padding: rem(20) 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &__section {
    flex: 0 0 40%;
    margin-right: 10%;
    &:nth-child(n + 3) {
      margin-top: rem(20);
    }

    @include mq($from: vp5) {
      flex: 0 0 20%;

      &:nth-child(n + 3) {
        margin-top: 0;
      }
      &:nth-child(n + 4) {
        margin-top: rem(20);
      }
    }
    @include fontStyle(paragraph-2);

    h5 {
      font-weight: $weight-medium;
    }
    p {
      white-space: pre-line;
    }
  }
}
