@import "@scss/base";

// .site-header {
//   padding-bottom: rem(200);
// }
.main-header {
  $module: ".main-header";

  display: flex;
  justify-content: space-between;
  align-content: center;

  position: fixed;
  right: 0;
  left: 0;
  top: rem(29);
  
  z-index: map-get($z-indexes, "main-header-mobile");

  transition: background-color 0.2s, transform 0.5s ease, color 0.2s ease;
  
  @include mq($until: vp5) {
    transform: translateY(-1px);
  }

  @include mq($from: vp5) {
    top: var(--mode-switch-height, 0);
    z-index: map-get($z-indexes, "main-header");
  }

  color: var(--c-s-site-c);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: var(--site-header-height);
    background-color: var(--c-s-site-bgc);
    transform-origin: center top;
    transform: scaleY(0);
    opacity: 0;
    z-index: -1;
    transition: all var(--default-transition);
  }
  &:hover, &.force-white {
    &:before {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  &.is-inverted {
    color: var(--c-s-site-bgc);
    background-color: transparent;

    &:hover {
      color: var(--c-s-site-c);
    }
  }

  &.is-inverted {
    color: var(--c-s-site-bgc);
    background-color: transparent;

    &:hover {
      color: var(--c-s-site-c);
    }
  }

  &.is-configuring {
    @include mq($until: vp5) {
      display: none;
    }
  }

  .is-scrolled & {
    color: var(--c-s-site-c);
  }

  &.is-unchanging {
    .is-scrolled &.is-inverted {
      color: var(--c-s-site-bgc);
      &:hover {
        color: var(--c-s-site-c);
      }
    }
  }

  &.is-active {
    // z-index: 0;
    @include mq($from: vp5) {
      background-color: transparent;
      // z-index: map-get($z-indexes, "main-header");
    }
    &:hover {
      &:before {
        transform: scaleY(0);
        opacity: 0;
      }
    }
  }

  &__logo {
    @include fontStyle(heading-3);
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($until: vp5) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: rem(24);
    }
    @include mq($from: vp5) {
      position: static;
      top: auto;
      left: auto;
      transform: translate(0, 0);
      display: block;
      padding: rem(30) 0 rem(30) rem(40);
    }
    @include mq($from: vp8) {
      padding: rem(40) 0 rem(40) rem(40);
    }
  }

  &__nav {
    // position: relative;
    @include mq($until: vp5) {
      flex: 0;
      padding: rem(20) rem(12);
      // z-index: map-get($z-indexes, "main-header");
    }

    &:not(:first-child) {
      justify-content: flex-end;
    }

    display: flex;
    align-content: center;
    box-sizing: border-box;

    @include mq($from: vp5) {
      padding: rem(30);
      width: 70vw;
    }
    @include mq($from: vp6) {
      padding: rem(30) rem(40);
      width: 60vw;
    }
    @include mq($from: vp7) {
      width: 50vw;
    }
    @include mq($from: vp8) {
      padding: rem(40);
    }
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    align-content: center;

    li {
      display: inline-flex;
      .icon {
        display: inline-flex;
        align-content: center;
      }
    }
    &-button {
      overflow: visible;
      padding: 0 rem(10);
      @include mq($until: vp2) {
        padding: 0 rem(5);
      }
      color: currentColor;
      .svg-icon {
        overflow: visible;
        fill: currentColor;
      }
    }
    &-icon {
      width: rem(16);
    }
  }

  &__item {
    padding: 0 rem(12);
    position: relative;
    transition: opacity var(--default-transition);
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    font-size: rem(13);
    line-height: rem(16);
    text-transform: uppercase;
    @include mq($from: vp6) {
      font-size: rem(14);
    }

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 100%;
      height: rem(1);
      background: currentColor;
      transition: right var(--default-transition);
    }

    &.is-active {
      color: var(--c-b-grey2);
      &:before {
        right: 0;
      }
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__back {
    transform: rotate(180deg);
    box-sizing: border-box;
    margin-right: auto;
    font-size: rem(30);

    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-flex;
      transition: transform var(--default-transition);
    }
    &:hover {
      span {
        transform: translateX(rem(-10));
      }
    }

    // @include mq($from: vp4) {
    //   right: calc(70vw - rem(40) - rem(20)); //button width & menu padding
    // }
    // @include mq($from: vp5) {
    //   right: calc(50vw - rem(40) - rem(40)); //button width & menu padding
    // }
  }
}
