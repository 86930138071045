@import "@scss/base";

.video-player-modal {
  $module: ".video-player-modal";
  background-color: transparent;
  height: 100%;
  aspect-ratio: 16 / 9;

  @include mq($from: vp3) {
    width: 80vw;
  }
  @include mq($from: vp4) {
    width: 90vw;
  }
  @include mq($from: vp6) {
    width: 80vw;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;

    display: inline-flex;
    width: rem(40);
    height: rem(40);
    align-items: center;
    justify-content: center;
    background-color: var(--c-b-black);
    .svg-icon {
      width: rem(38);
      height: rem(38);
      stroke: var(--c-b-white);
    }
    @include mq($from: vp3) {
      right: auto;
      left: 0;
    }
    @include mq($from: vp4) {
      right: 0;
      left: auto;
    }
  }
  &__content {
    height: 100%;

    h3 {
      display: none;
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }
  }
}
.video-player-consent-placeholder {
  .button-container {
    flex-direction: row;
  }
  &__content {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: rem(30) rem(40);

    h3 {
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }
    p {
      @include fontStyle(paragraph-1);
      margin-bottom: rem(20);
    }

    .action-button {
      margin-top: auto;
      margin-right: 20px;
      margin-bottom: 20px;
      @media screen and (max-width: 500px){
          width: 100%;
      }
    }
  }
}
