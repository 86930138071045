@import "@scss/base";

.quote-module {
  $module: ".quote-module";

  @include verticalSpacing;
  grid-template-rows: repeat(6, auto);

  --quote-module-c: var(--c-b-black);
  color: var(--quote-module-c);
  --quote-module-bgc: transparent;
  background: var(--quote-module-bgc);

  &--has-bgcolor {
    @include verticalSpacing(true);
  }
  &--inverted {
    --quote-module-c: var(--c-b-white);
  }

  @include grid;
  @include container;


  &__tag {
    @include fontStyle(trumpet-1);
    font-weight: 700;
    padding-bottom: rem(5);
    color: var(--c-s-error1);
  }
  &__name {
    @include fontStyle(link-1);
  }
  &__tag-and-name {
    @include gridItem(2, 10, 1, 1);
    padding-bottom: rem(30);

    @include fadeFromBottom(0.2);

    @include mq($from: vp5) {
      padding-bottom: rem(100);
      @include gridItem(11, 12, 1, 1);
    }
  }

  &__image {
    @include gridItem(1, 4, 2, 4);
    @include offsetContent(left);
    height: 42vw;

    @include fadeFromLeft(0.8);

    @include mq($from: vp4) {
      @include gridItem(1, 4, 2, 2);
    }
    @include mq($from: vp5) {
      @include gridItem(1, 9, 1, 4);
    }
  }

  &__quote {
		@include fontStyle(heading-2);
		padding-bottom: rem(30);
    grid-column: 5 / span 7;
    @include mq($from: vp5) {
      grid-column: 11 / span 11;
    }

    @include fadeFromBottom(0.4);
	
		@include mq($from: vp5) {
		  padding-bottom: rem(50);
		}
	}

  &__bodytext {
    @include gridItem(1, 12, 6, 1);
    @include mq($from: vp4) {
      grid-column: 5 / span 6;
      grid-row: auto;
    }
    @include mq($from: vp5) {
      grid-column: 11 / span 11;
    }

    @include fadeFromBottom(0.4);
  }

  &__button {
    @include gridItem(1, 7, 7, 1);
    padding-top: rem(35);
    @include mq($from: vp5) {
      grid-column: 11;
    }
  }

  &--no-trumpet-and-name {
    #{$module}__image {
      @include gridItem(1, 4, 1, 4);

      @include mq($from: vp5) {
        @include gridItem(1, 9, 1, 4);
      }
    }
  }
}
