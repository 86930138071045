@import "../../assets/scss/base";

.checkout-flow {
  $module: ".checkout-flow";
  @include verticalSpacing;

  &__inner {
    @include container;
    @include grid;

    &--altapay {
      display: block;
      form {
        display: block;
        max-width: rem(500);
      }
    }
  }
  &__progress {
    @include gridItem(1, 12, 1, 1);
    @include mq($from: vp3) {
      @include gridItem(2, 10, 1, 1);
    }
    @include mq($from: vp5) {
      @include gridItem(2, 22, 1, 1);
    }

    margin-bottom: rem(60);
    @include mq($from: vp5) {
      margin-bottom: rem(100);
    }
  }

  &__header {
    @include gridItem(1, 12, 2, 1);
    @include mq($from: vp3) {
      @include gridItem(2, 10, 2, 1);
    }
    @include mq($from: vp5) {
      @include gridItem(2, 22, 2, 1);
    }

    margin-bottom: rem(40);
    h3 {
      @include fontStyle(heading-3);
      padding-bottom: rem(20);

      @include gridItem(1, 12);
      @include mq($from: vp3) {
        padding-bottom: rem(40);

        @include gridItem(2, 10);
      }
      @include mq($from: vp5) {
        @include gridItem(2, 20);
      }
    }
    p {
      @include fontStyle(paragraph-2);
      @include mq($from: vp5) {
        width: 60%;
      }
    }
  }

  &__aside {
    // TODO: DOESNT WORK
    // position: sticky;
    // top: 0;
    margin-top: rem(60);
    @include gridItem(1, 12, 4, 1);
    @include mq($from: vp3) {
      @include gridItem(2, 10, 4, 1);
    }
    @include mq($from: vp5) {
      margin-top: 0;
      @include gridItem(16, 8, 3, 1);
    }
  }

  &__aside + &__main {
    @include mq($from: vp5) {
      @include gridItem(2, 13, 3, 1);
    }
  }

  &__main {
    @include gridItem(1, 12, 3, 1);
    @include mq($from: vp3) {
      @include gridItem(2, 10, 3, 1);
    }
    @include mq($from: vp5) {
      @include gridItem(2, 22, 3, 1);
    }

    .form {
      @include mq($from: vp6) {
        width: 80%;
      }
      @include mq($from: vp7) {
        width: 70%;
      }
      @include mq($from: vp9) {
        width: 60%;
      }

      hr {
        border: 0;
        margin: rem(60) 0 0;
        padding: 0;
      }
      h4 {
        @include fontStyle(paragraph-1);
        font-weight: $weight-medium;
        padding-bottom: rem(30);
      }

      .checkbox + .checkbox,
      .radio + .radio {
        margin-top: rem(15);
      }

      &__message {
        padding-top: rem(10);

        h5 {
          font-size: rem(12);
          font-weight: $weight-medium;
          padding-bottom: rem(5);
        }
        p {
          font-size: rem(10);
        }
      }

      &__row {
        &--dual {
          display: flex;
          justify-content: space-between;
          > *:first-child {
            flex: 0 0 calc(40% - rem(25));
            margin-right: rem(25);
          }
          > *:last-child {
            flex: 0 1 60%;
          }
        }
        & + .form__row {
          padding-top: rem(30);
        }
      }
      &__group + .form__group {
        margin-top: rem(60);
      }
    }

    table {
      width: 100%;
      max-width: 100%;
      line-height: 1.4;
      th,
      td {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: rem(200);
      }
      @include mq($from: vp4) {
        min-width: 0;
        th,
        td {
          padding-right: rem(30);

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  &__footer {
    &__message {
      margin-top: rem(20);
      color: var(--c-s-error2);
    }

    .action-button {
      margin-top: rem(60);
    }

    .action-button--primary {
      --button-bgc: #d6e1b9;

      &.is-disabled {
        opacity: 1;
        --button-bgc: var(--c-b-grey4);
        --button-c: var(--c-b-grey1);
        &:hover {
          opacity: 1;
        }
      }
      .text {
        font-size: rem(12);
        text-transform: none;
      }
    }
    .action-button--secondary {
      .text {
        font-size: rem(12);
        text-transform: none;
      }
    }
  }
}
