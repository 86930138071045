@import "../../assets/scss/base";

#selectpayment {
  padding-top: rem(20);
}

#submitbutton {
  @include buttonStyles();
  border: 0;
  --button-bgc: var(--c-b-black);
  --button-c: var(--c-b-white);
  @include fontStyle(paragraph-2);
}

//Swish
#Mobile,
#MobileOnApp {
  .AltaPayMobileInputOptionOr {
    display: flex;
    flex-direction: column;
    padding-top: rem(20);
  }

  .pensio_payment_form_table {
    tr {
      flex-direction: column;

      .pensio_payment_form_input_cell {
        flex-direction: column;
      }
    }
  }
}

#klarna_options {
  #klarna_container {
    display: block !important;

    .klarnaPaymentMethodContainer {
      margin-bottom: rem(20);
    }
  }

  //.klarnaPaymentMethodContainer{
  .radioButtonLabel {
    display: flex;

    div {
      display: flex;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      span {
        margin-left: rem(15);
        @include fontStyle(paragraph-2);
        cursor: pointer;
        text-align: left;
        width: 100%;
        white-space: pre-line;
        line-height: rem(25);
      }
    }
  }

  input[name="klarna_category_toggler"] {
    appearance: none;
    margin: 0;
    font-size: 0;
    height: rem(25);
    width: rem(40);
    flex: 0 0 rem(25);
    border-radius: 50% 50%;
    background-color: transparent;
    border: rem(1) solid var(--c-b-black);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;

    // &:checked {
    //   height: rem(25);
    //   width: rem(25);
    // }

    &:after {
      content: "";
      display: block;
      background-color: var(--c-b-black);
      border-radius: 50% 50%;
      font-size: 18px;
      width: 50%;
      height: 50%;
      opacity: 0;
      transition: opacity var(--default-transition);
    }

    &:checked:after {
      opacity: 1;
    }

    &[type="radio"]:disabled {
      border-color: var(--c-b-grey2);
      background-color: darken(white, 5%);

      +label {
        color: var(--c-b-grey2);
      }
    }

  }
}

.pensio_payment_form_table {
  $module: ".pensio_payment_form_table";

  width: 100%;
  .pensio_payment_form_select_card_label {
      position: absolute;
      top: rem(72);
      @include mq($from: vp5) {
        left: calc(100% + 1rem);
        top: rem(16);
        width: rem(160);
      }
    }

  #creditCardTypeIcon{
    display: inline-block;
    top: rem(88);
    left: rem(112);

    @include mq($from: vp5) {
      left: calc(100% + 1rem);
      top: rem(64);
    }
  }

  #creditCardTypeSecondIcon {
    display: inline-block;
    top: rem(88);
    left: rem(144);

    @include mq($from: vp5) {
      left: calc(100% + 3rem);
      top: rem(64);
    }
  }

  tr {
    &:nth-child(1) {
      margin-bottom: rem(32);
      @include mq($from: vp5) {
        margin-bottom: 0;
      }
    }
    display: flex;
    align-items: center;
    position: relative;
  }

  td {
    height: auto;
    display: flex;
    justify-content: space-between;
    line-height: 3.6em;
    position: relative;
    flex: 1;

    @include mq($from: vp5) {
      flex: 50%;
    }
  }

  td:not(.pensio_payment_form_cvc_error) {
    padding-top: rem(20);
  }

  input[type="tel"],
  input[type="text"] {
    @include fontStyle(paragraph-2);
    appearance: none;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    width: 100%;
    padding: rem(18) rem(18) rem(16) rem(18);
    border: rem(1) solid var(--c-b-grey3);
    background-color: var(--input-bg);
    color: var(--c-b-grey1);
    line-height: 1;
    outline: 0;
    height: auto;
    margin: 0;
  }

  img {
    display: none;
    position: absolute;
    bottom: rem(16);
    left: calc(100% + rem(16));
    width: rem(24);
    height: rem(15);
  }

  select {
    @include fontStyle(paragraph-2);
    width: 40%;
    padding: rem(18) rem(18) rem(16) rem(18);
    line-height: normal;
    border: rem(1) solid var(--c-b-grey3);
    outline: none;
    background-color: transparent;
    color: var(--c-b-grey1);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
  }

  small {
    display: block;
    position: absolute;
    left: calc(100% + rem(10));
    top: calc(50% + rem(10));
    transform: translateY(-50%);

    a {
      display: block;
    }
  }

  input[type="submit"] {
    @include buttonStyles();
    border: 0;
    --button-bgc: var(--c-b-black);
    --button-c: var(--c-b-white);
    @include fontStyle(paragraph-2);
  }
}