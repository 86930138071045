@import "@scss/base";

.pdp-model-selector {
  $module: ".pdp-model-selector";
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;

  border-top: rem(1) solid var(--c-b-grey3);

  h3 {
    @include fontStyle(heading-4);
    margin: rem(15) 0 rem(20);
    flex: 0 0 1em;
  }
  &__content {
    margin-top: rem(20);
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100% - rem(#{40 + 20 + 40 + 40}));
    @include custom-scrollbar;
    padding-right: rem(20);
  }
  &__tiles {
    display: grid;
    grid-gap: rem(20);
    grid-template-columns: 1fr 1fr;
    padding-bottom: rem(20);
    align-items: flex-start;

    @include mq($from: vp4) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .pdp-swatch-tile {
    &__inner {
      padding: 0;
      border: 0;
      display: block;
      min-height: 0;
    }

    .product-image {
      height: 0;
      padding-bottom: 100%;
      position: relative;

      .product-image__image {
        background-color: #efeeeb;
        box-sizing: border-box;
        padding: rem(10);
        object-fit: contain;
      }
    }
  }
}
