@import "@scss/base";

.nav-tiles-slider {
  $module: ".nav-tiles-slider";

  @include verticalSpacing;

  .nav-button--outlined {
    @include mq($from: vp5) {
      margin-left: 0;
    }
  }
  .nav-button--outlined {
    @include mq($from: vp4) {
      margin-top: rem(60);
    }
  }
  .swiper-container {
    padding-bottom: rem(30);
    box-sizing: border-box;
    padding-left: 2vw;
    @include mq($from: vp3) {
      padding-left: 13vw;
    }
    @include mq($from: vp5) {
      padding-left: 12.5vw;
    }
    @include mq($from: vp8) {
      padding-left: 14.5vw;
    }
    @include mq($from: vp9) {
      padding-left: 16vw;
    }
  }

  .carousel__footer {
    padding-left: 2vw;
    @include mq($from: vp3) {
      padding-left: 13vw;
    }
    @include mq($from: vp5) {
      padding-left: 12.5vw;
    }
    @include mq($from: vp8) {
      padding-left: 14.5vw;
    }
    @include mq($from: vp9) {
      padding-left: 16vw;
    }
  }
  &__slide {
    width: 71.7%;

    &:not(:last-child) {
      @include gridGapRight();
    }
    @include mq($from: vp4) {
      width: 44%;
    }

    .nav-tile {
      @include mq($from: vp4) {
        grid-template-columns: 1fr;
      }
      &__img {
        position: relative;
        height: 0;
        padding-bottom: 100%;
      }
    }
    .text-tile {
      &__headline {
        h2,
        h3 {
          padding-left: 0;
          @include fontStyle("heading-4");
        }
      }
      &__bodytext {
        p {
          padding-left: 0;
        }
      }
      &__link,
      &__contact {
        padding-left: 0;
      }
    }
  }
  .carousel__header {
    grid-column: 1 / span 12;
    @include mq($from: vp5) {
      grid-column: 1 / span 24;
    }
  }

  &--compact {
    #{$module}__slide {
      width: 62%;
      @include mq($from: vp4) {
        width: 37.1%;
      }
      @include mq($from: vp5) {
        width: 24.5%;
      }
    }
    .nav-tile {
      &__img {
        height: 68vw;
        @include mq($from: vp4) {
          height: 48vw;
        }
        @include mq($from: vp5) {
          height: 28vw;
        }
        padding-bottom: 0;
      }
      .media-focal__image {
        position: static;
      }
    }
  }
}
