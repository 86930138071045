@import "@scss/base";

.tag-list-button {
  $module: ".tag-list-button";

  --tag-c: var(--c-b-black);
  --tag-bgc: transparent;

  border: rem(1) solid var(--c-b-black);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: rem(10);
  min-width: 30vw;
  height: rem(45);
  transition: all var(--default-transition);

  font-family: $font-primary;
  font-size: rem(13);
  line-height: rem(22);
  text-transform: uppercase;

  color: var(--tag-c);
  background: var(--tag-bgc);

  @include mq($from: vp2) {
    min-width: 22vw;
  }
  @include mq($from: vp3) {
    min-width: 15vw;
  }
  @include mq($from: vp5) {
    padding: rem(11) rem(22);
    min-width: 0;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
  }
  &.is-active {
    --tag-bgc: var(--c-b-black);
    --tag-c: var(--c-b-white);
  }
}
