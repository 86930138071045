@import "@scss/base";

.material-info-overlay {
  $module: ".material-info-overlay";

  display: block;
  padding: rem(20);
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  @include mq($from: vp5) {
    padding: 0 rem(60) rem(60);
    min-width: rem(300);
  }

  &__headline {
    h4 {
      @include fontStyle(heading-4);
    }
    p {
      margin-top: rem(10);
      @include fontStyle(paragraph-2);
    }
  }
  &__cta {
    margin-top: rem(40);
  }
  &__button {
    @include fontStyle(paragraph-2);
    display: inline-flex;
    align-items: center;

    .label {
      display: inline-flex;
      text-decoration: underline;
    }
    .icon {
      display: inline-flex;
      margin-right: rem(20);
    }
  }
  &__image {
    margin-top: rem(30);
    display: block;
    max-width: 100%;
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
