.showinfo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.65rem;
    .showinfo-title{
        font-weight: bold;
        margin-bottom: 0.65rem;
    }
    .showinfo-value{
        margin-bottom: 0.65rem;
    }
    textarea{
        resize: none;
        width: 15rem;
        height: 3rem;
        border: 0;
        font-family: "HansenGrotesque", sans-serif;
        font-size: 0.8125rem;
        line-height: 1.53846;
        text-align: center;
    }
    .is-showninfomodal{
        width: 15rem;
        .select__selector{
            text-align: center;
            border: solid black 1px;
            padding: 0;
         
        }
        &:after{
            top: 1rem;
        }
    }
}