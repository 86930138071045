@import "@scss/base";

.portfolio-product-tile {
  $module: ".portfolio-product-tile";
  position: relative;

  grid-column: span 6;

  @include mq($from: vp3) {
    grid-column: span 7;
  }

  @include mq($from: vp5) {
    grid-column: span 9;
  }
}
