@import "@scss/base";

.modal-overlay {
  $module: ".modal-overlay";

  &__container {
    //styles for outer overlay
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6000;
    @include mq($from: vp4) {
      flex-direction: row;
    }
    &.content-modal{
      flex-direction: row;
    }
  }

  &__box {
    //styles for surrounding box
    box-sizing: border-box;
    background-color: var(--c-b-white);
    flex: 100%;
    width: 100%;

    @include mq($from: vp3) {   
      &.content-modal {
        width: auto;
        flex: 0 1 rem(500);
      }
    }

    @include mq($from: vp4) {   
      width: auto;
      flex: 0 1 rem(500);
      &.video-player-consent-placeholder {
        flex: 0 1 rem(600);
      }
      &.showinfo-modal{
        flex: 0 1 rem(750);
      }
    }
    @include mq($from: vp7) {   
      &.content-modal {
        flex: 0 1 rem(850);
      }
    }
  }
  &__content {
    //styles for inner content
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: visible;
  height: auto;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity var(--default-transition);

  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.test-modal {
  padding: rem(20) rem(10);
  @include mq($from: vp4) {
    padding: rem(30);
  }
  &__content {
    padding: rem(20) 0;
    position: relative;
  }
  .action-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
