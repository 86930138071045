@mixin offsetContent($direction: left, $breakpoint: false) {
  @if $breakpoint {
    $size: map-get($container-horizontal-spacing-values-fluid, $breakpoint);
    @include mq($from: $breakpoint) {
      width: calc(100% + #{$size});
      @if $direction == right {
        margin-right: calc(#{$size} * -1);
      } @else {
        margin-left: calc(#{$size} * -1);
      }
    }
  } @else {
    $size1: rem($container-horizontal-spacing-small);
    width: calc(100% + #{$size1});

    @if $direction == right {
      margin-right: calc(#{$size1} * -1);
    } @else {
      margin-left: calc(#{$size1} * -1);
    }
    @each $breakpoint, $size in $container-horizontal-spacing-values-fluid {
      @include mq($from: $breakpoint) {
        width: calc(100% + #{$size});
        @if $direction == right {
          margin-right: calc(#{$size} * -1);
        } @else {
          margin-left: calc(#{$size} * -1);
        }
      }
    }
  }
}

// $col-320: 5.444444444;
// $col-768: 5.295138889;
// $col-1024: 2.05078125;
// $col-1440: 2.155671296;
// $col-1920: 1.714409722;

$grid-col-pcts: (
  vp1: colWidth(300, $container-horizontal-spacing-small, $grid-columns-mobile, $grid-gap-small),
  vp3: colWidth(560, $container-horizontal-spacing-regular, $grid-columns-mobile, $grid-gap-regular),
  vp5:
    colWidth(900, $container-horizontal-spacing-regular, $grid-columns-desktop, $grid-gap-regular),
  vp7: colWidth(1400, $container-horizontal-spacing-large, $grid-columns-desktop, $grid-gap-large),
  vp8: colWidth(1900, $container-horizontal-spacing-xlarge, $grid-columns-desktop, $grid-gap-xlarge),
  vp9:
    colWidth(2400, $container-horizontal-spacing-xlarge, $grid-columns-desktop, $grid-gap-xxlarge),
);

@mixin indentCols($cols, $breakpoint, $right: false, $use-margin: false) {
  $cw: map-get($grid-col-pcts, $breakpoint);
  $gg: map-get($grid-gap-sizes, $breakpoint);

  @include mq($from: $breakpoint) {
    @if $right {
      @if $use-margin {
        margin-right: calc(#{$cw * $cols}% + #{$gg * $cols}px);
      } @else {
        padding-right: calc(#{$cw * $cols}% + #{$gg * $cols}px);
      }
    } @else {
      @if $use-margin {
        margin-left: calc(#{$cw * $cols}% + #{$gg * $cols}px);
      } @else {
        padding-left: calc(#{$cw * $cols}% + #{$gg * $cols}px);
      }
    }
  }
}
