@import "@scss/base";

.product-series-tile {
  $module: ".product-series-tile";
  display: flex;
  flex-direction: column;

  &__img {
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }
  .media-focal__image {
    position: static;
  }

  &__text {
    margin-top: rem(20);
  }
  sup {
    font-size: 75%;
    position: relative;
    top: rem(-5);
  }
  h3 {
    @include fontStyle(heading-4);
  }
}
