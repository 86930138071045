@import "@scss/base";

.nav-list-button {
  $module: ".nav-list-button";

  padding: rem(15) 0;

  @include fontStyle(heading-3);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: padding-left var(--default-transition);
  &:hover {
    padding-left: rem(20);
  }

  .text {
    font-size: 75%;
    line-height: 1.4;
  }
  .icon {
    display: inline-flex;
    svg {
      fill: currentColor;
      // width: 1em;
      // height: 1em;
    }
  }
}
