@import "@scss/base";
@import "../notification";

.notification-item-added {
  $notification: ".item-added";
  display: flex;

  --notification-item-added-bgc: var(--c-t-lightgreen);

  background-color: var(--notification-item-added-bgc);

  position: fixed;

  top: 0;
  right: 0;

  @include mq($from: vp4) {
    top: rem(15);
    right: rem(15);
    min-height: 20vw;

  }


  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem(23);
    width: 100%;
    max-width: rem(400);
    @include mq($from: vp4) {
      display: flex;
      flex-direction: column;
      padding: rem(50) rem(45);
    }
  }
  &__headline {
    padding-bottom: rem(20);
    text-align: center;
    @include fontStyle(heading-4);
    @include mq($from: vp4) {
      padding-bottom: rem(40);
    }
  }
  &__bodytext{
    @include fontStyle(paragraph-2);
    padding-bottom: rem(20);
    text-align: center;
    @include mq($from: vp4) {
      padding-bottom: rem(40);
    }
  }
}
