.collapsible {
  .svg-icon {
    transition: transform 0.2s;
    height: 1em;
  }
  &.is-active {
    .svg-icon {
      transform: rotate(180deg);
    }
  }
  &__content {
    overflow: hidden;
  }
}
