@import "@scss/base";

.product-list {
  $module: ".product-list";

  @include container();
  position: relative;

  .product-filters-visual {
    margin-bottom: rem(50);
    @include mq($from: vp4) {
      margin-bottom: rem(60);
    }
  }
  &__filters {
    flex: 1 1 auto;
  }
  &__tools {
    display: flex;
    position: relative;
    @include mq($until: vp5) {
      justify-content: space-between;
      align-items: center;
    }
    @include mq($from: vp5){
      border-top: rem(1) solid var(--c-b-grey3);
    }
    border-bottom: rem(1) solid var(--c-b-grey3);
    padding-bottom: rem(20);
    margin-bottom: rem(20);

    .select,
    .select__selector {
      font-size: rem(11);
      margin: 0;
    }
    @include mq($from: vp5) {
      padding: rem(15) 0 rem(15) 0;
    }
  }

  &__sort {
    font-weight: $weight-medium;
  }

  &--empty {
    min-height: 70vh;
  }
  &__paging {
    margin: rem(20) 0;
    text-align: center;
  }

  &__tiles {
    @include mq($from: vp3) {
      @include grid();
    }
    .spot-tile {
      border-bottom: rem(1) solid var(--c-b-grey3);
      padding-bottom: rem(20);
      margin-bottom: rem(60);
      @include mq($from: vp3) {
        grid-column: span 6;
      }
      @include mq($from: vp5) {
        grid-column: span 16;
      }
    }
    .swiper-container {
      padding: 0 0 rem(60) 0;
    }
  }
  &__tile {
    border-bottom: rem(1) solid var(--c-b-grey3);
    padding-bottom: rem(20);
    margin-bottom: rem(60);
    grid-column: span 6;
    // @include mq($from: vp3) {
    // }
    // @include mq($from: vp5) {
    //   grid-column: span 6;
    // }
  }
}
