@import "@scss/base";

.message-block {
  $module: ".message-block";
  @include grid;
  @include container;
  @include verticalSpacing;
  @include verticalSpacing(true);

  &__inner {
    text-align: center;
    @include gridItem(2, 10);
    @include mq($from: vp5) {
      @include gridItem(8, 8);
    }
  }

  h5 {
    @include fontStyle(trumpet-2);
  }
  h1 {
    @include fontStyle(heading-3);
    text-transform: none;
  }
  p {
    @include fontStyle(paragraph-3);
  }

  h1 + p,
  h1 + a {
    margin-top: rem(30);
  }
  p + a {
    margin-top: rem(20);
  }

  .nav-button {
    padding: rem(10) rem(20);
    .text {
      text-transform: none;
      font-size: rem(12);
    }
  }
}
