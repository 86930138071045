@import "@scss/base";

.pdp-product-images-spinner {
  height: 100%;
  width: 100%;

  &__frames {
    display: grid;
    grid-template-areas: "image";
    grid-template-rows: 100%;
    grid-template-rows: 100%;
    touch-action: pan-y;
    @include mq($from: vp5) {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq($from: vp5) {
      max-height: calc(
        100vh - #{rem($container-horizontal-spacing-regular)} - var(--site-header-height)
      );
    }
    @include mq($from: vp7) {
      max-height: calc(
        100vh - #{rem($container-horizontal-spacing-large)} - var(--site-header-height)
      );
    }
    @include mq($from: vp8) {
      max-height: calc(
        100vh - #{rem($container-horizontal-spacing-xlarge/2)} - var(--site-header-height)
      );
    }
  }

  &__frame {
    display: block;
    grid-area: image;
    width: 100%;
    @include mq($from: vp5) {
      height: 100%;
    }

    object-fit: contain;
    object-position: center;
    opacity: 0.0001;
    pointer-events: none;

    &.pdp-product-images-spinner__firstImage {
      opacity: 1;
    }

    &.show {
      opacity: 1;
    }
  }
}
