@import "@scss/base";

.site-footer {
  $component: ".site-footer";

  @include mq($from: vp5) {
    position: sticky;
    bottom: 0;
    left: 0;
  }

  position: relative;

  &__lower-part {
    position: relative;
    display: grid;
    background-color: var(--c-b-black);
    color: var(--c-b-white);
    grid-gap: rem(60);
    padding: rem(30) rem(30);

    @include mq($from: vp5) {
      grid-template-columns: auto;
      grid-column-gap: rem(80);
      grid-row-gap: 0;
      padding: rem(40) rem(180) rem(84);
    }
  }

  &__headline {
    @include fontStyle(heading-5);
    padding-top: rem(15);

    &__collapsible-item {
      display: block;

      @include mq($from: vp5) {
        display: none;
      }
    }
  }

  &__signup {
    @include gridItem(1, 1, 1, 3);

    @include mq($from: vp5) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .input-field {
      position: relative;

      &__info-message,
      &__success-message {
        position: absolute;
        bottom: rem(-50);
        height: 100%;
      }
    }

    .newsletter-signup {

      p,
      ::placeholder {
        opacity: 0.7;
      }
    }
  }

  &__portal-links {
    position: absolute;
    bottom: rem(140);
    left: rem(30);
    display: grid;
    grid-gap: rem(10);

    @include mq($from: vp5) {
      @include gridItem(1, 1, 3, 1);
      inset: auto;
      align-self: flex-start;
      padding-top: rem(30);
      position: static;
    }

    .nav-button {
      color: var(--c-b-white);
    }

    .svg-icon {
      fill: var(--c-b-white);
    }
  }

  &__nav-list {

    display: none;

    @include mq($from: vp5) {
      display: flex;
    }

    &--mobile {
      display: block;

      @include mq($from: vp5) {
        display: none;
      }

      .collapsible {
        border-bottom: rem(1) solid var(--c-b-white);
        width: 100%;

        &.is-active {
          padding-bottom: rem(25);
        }

        &:last-child {
          border-bottom: 0;
        }

        &__trigger {
          @include fontStyle(heading-5);
          padding: rem(20) 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          #{$component}__icon {
            transform: rotate(90deg);
          }

          &.is-active {
            #{$component}__icon {
              transform: rotate(-90deg);
            }
          }
        }
      }

    }

    flex-direction: column;
    padding-bottom: rem(30);

    @include mq($from: vp5) {
      padding-bottom: 0;
      flex-direction: row;
    }
  }

  &__collapsible-item {
    font-weight: $weight-regular;
    color: var(--c-b-white);
    line-height: rem(25);
    margin-top: rem(10);

    @include mq($from: vp5) {
      margin-bottom: 0;
    }

    &--desktop {
      display: none;

      @include mq($from: vp5) {
        display: block;
      }
    }
  }

  &__link-item-group {
    display: none;

    @include mq($from: vp5) {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: rem(10);
    }

    @include mq($from: vp5) {
      flex: 0 0 25%;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: rem(30);
      }
    }
  }

  &__some {
    flex: 0 0 25%;

    &-icons {
      height: rem(30);
      display: flex;
      padding: rem(15) 0;

      @include mq($from: vp5) {
        padding: rem(24) 0;
      }

      &__item {
        img {
          height: rem(30);
        }
      }
    }
  }

  &__contact {
    flex: 0 0 25%;

    .contact-links-group {
      padding: rem(15) 0;

      @include mq($from: vp5) {
        padding: rem(24) 0 rem(34);
      }

      &__item {
        margin-top: 0;

        a {
          font-size: 12px;
          font-weight: 400;
          text-decoration: underline;
        }
      }
    }
  }

  &__store-locator-list {
    padding-top: rem(100);

    @include mq($from: vp5) {
      padding-top: 0;
    }
  }

  &__stores {
    flex: 0 0 25%;
  }

  &__store-locator {
    padding: rem(24) 0;
  }

  &__map-marker-icon {
    margin-right: rem(8);
  }

  &__newsletter-signup {
    flex: 0 0 25%;
  }

  &__upper-part {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: var(--c-s-site-bgc);

    display: flex;
    flex-direction: column;

    @include mq($from: vp5) {
      flex-direction: row;
      padding: rem(40) rem(180) 0;
    }

    padding: rem(40) rem(35) rem(30);
  }

  &__lower-main {
    padding: rem(30) 0;
    border-bottom: rem(1.5) solid var(--c-b-grey3);
    width: 100%;

    @include mq($from: vp5) {
      padding: 0;
      border: none;
      display: flex;
      align-items: center;
    }

    .mode-switch {
      position: static;
      background-color: var(--c-b-white);
      color: var(--c-b-black);
      z-index: 1;

      &__bodytext {
        display: none;
      }

      &__buttons {
        .button {
          font-size: rem(14);

          &:last-child {
            margin-left: 0;
            padding-left: rem(16);
          }

          &.is-inactive {
            opacity: 0.5;
            text-decoration: none;
          }
        }
      }

      &__country-selector {
        display: none;
      }

      height: rem(30);
    }
  }

  &__logo-wrapper {
    display: flex;
    flex-direction: column;

    @include mq($from: vp5) {
      flex-direction: row;
    }

    div {
      flex: 0 0 25%;
    }
  }

  &__footer-logo {
    margin-bottom: rem(20);

    @include mq($from: vp5) {
      margin-bottom: 0;
    }
  }
}