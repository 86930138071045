@import "@scss/base";

.timeline-introimage {
  $module: ".timeline-introimage";

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  width: rem(400);
  max-width: 95vw;
  padding-right: rem(100);
  padding-left: rem(20);
  
  @include mq($from: vp3) {
    width: rem(600);
    padding-right: rem(150);
  }
  @include mq($from: vp4) {
    padding-right: rem(280);
  }
  @include mq($from: vp5) {
    //to compensate for headline
    padding-left: calc(10vw + rem(20));
     width: rem(750);
  }
  @include mq($from: vp7) {
    width: rem(800);
  }
  @include mq($from: vp8) {
    width: rem(850);
  }
  @include mq($from: vp9) {
    width: rem(1000);
  }

  &--offset {
    justify-content: flex-end;

    @include mq($from: vp3) {
      justify-content: center;
    }

    @include mq($from: vp5) {
      justify-content: flex-end;
    }

  }
  &--wide {
    width: 120vw;
    padding-right: 30vw;
    padding-left: 0;
    justify-content: flex-end;

    @include mq($from: vp4) {
      width: 80vw;
    }

    @include mq($from: vp5) {
      padding-left: 5vw;
      height: 100%;
      padding-right: 30vw;
    }

    @include mq($from: vp8) {
      padding-right: 10vw;
    }

    .media-focal {
      aspect-ratio: 16 / 9;
    }
    #{$module}__inner {
      @include mq($from: vp5) {
        flex: 0 0 65%;
        max-height: 65%;

      }
    }
  }

  &__inner {
    position: relative;

    @include mq($from: vp5) {
      overflow: visible;
    }

    @include mq($from: vp3) {
      height: calc((9 / 16) * rem(600));
    }
    @include mq($from: vp5) {
      height: calc((9 / 16) * rem(750));
    }
    @include mq($from: vp7) {
      height: calc((9 / 16) * rem(800));
    }
    @include mq($from: vp8) {
      height: calc((9 / 16) * rem(850));
    }
    @include mq($from: vp9) {
      height: calc((9 / 16) * rem(1000));
    }
  }

  .media-focal {
    margin-top: auto;
    min-width: 20vw;
    height: auto;

    .lazy-load-image-background,
    &__image {
      position: static;
    }
  }

  &__headline {
    //font size is used for headline translate! adjust accordingly

    font-size: rem(100);

    @include mq($from: vp5) {
      font-size: rem(200);

      position: absolute;
      inset: 0;
      z-index: 1;
    }
    @include mq($from: vp9) {
      font-size: rem(300);
    }

    h2 {
      @include fontStyle(heading-2);
      color: var(--c-b-white);
      max-width: rem(800);
      font-size: rem(22);
      padding-bottom: 2rem;

      @include mq($from: vp5) {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: -10vw;
        font-size: rem(45);
        width: 80%;
      }
    }
  }
}
