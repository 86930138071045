@import "@scss/base";

.product-filters-visual {
  $module: ".product-filters-visual";
  &__title {
    @include fontStyle(heading-4);
    margin-bottom: rem(20);
    @include mq($from: vp4) {
      margin-bottom: rem(30);
    }
  }
  &__slide {
    @include gridGapRight();
    width: 27%;
    @include mq($from: vp4) {
      width: 17%;
    }
    @include mq($from: vp5) {
      width: 12%;
    }
    @include mq($from: vp7) {
      width: 10%;
    }
    @include mq($from: vp8) {
      width: 8%;
    }
  }

  &__filter-wrapper {
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    width: 100%;
    cursor: pointer;
    top: 0;
    bottom: 0;
    border: 1px solid var(--c-s-border);
    transition: border .2s;

    &--active {
      border: 1px solid var(--c-b-black);
    }
    &--disabled {
      opacity: .2;
      pointer-events: none;
    }

  }

  &__label {
    @include fontStyle(trumpet-1);
    display: none;
    @include mq($from: vp4) {
      display: block;
      align-self: end;
      padding-left: rem(12);
    }
  }
  &__image {
    width: 60%;
    height: 60%;
  }
}
