@import "@scss/base";

.vision-slider {
  $module: ".vision-slider";

  @include verticalSpacing(true);
  @include verticalSpacing;

  transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  --vision-slider-bgc: var(--c-b-white);
  background: var(--vision-slider-bgc);

  .carousel {
    @include container;
    @include grid;

    @include mq($until: vp3) {
      padding: 0;
      margin: 0;
    }

    .swiper-container {
      @include gridItem(1, 12);

      @include mq($from: vp5) {
        @include gridItem(2, 22);
      }
      padding-bottom: rem(30);
      box-sizing: border-box;
      overflow: visible;
    }

    &__nav {
      @include gridGap(true);
      margin-right: 0;
      grid-column: 2 / span 10; // Place under headline (same width)
      grid-row: 3;
      padding: rem(20) 0;

      svg {
        width: rem(20);
        height: rem(20);
      }
      @include mq($from: vp5) {
        grid-column: auto;
        padding: 0;
      }

    }
    &__prev,
    &__next {
      @include fontStyle(heading-5);
    }
   
  }

  &__top {
    position: relative;
    @include container(false, true);
    @include grid;

    margin-bottom: rem(25);
    @include mq($from: vp3) {
      margin-bottom: rem(45);
    }

    h2 {
      @include fontStyle(heading-1);

      @include gridItem(2, 11);
      margin-bottom: rem(20);
      
      @include mq($from: vp5) {
        @include gridItem(2, 23);
      }
    }
    h5 {

      @include fontStyle(heading-4);
      @include gridItem(2, 11);
      @include mq($from: vp5) {
        @include gridItem(2, 23);
      }
    }

    .carousel__nav {
      margin-right: 0 !important;
      @include mq($from: vp3) {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  &__bottom {
    @include container(false, true);

    position: relative;

    h5 {
      @include fontStyle(heading-4);
      margin-bottom: rem(25);
      @include gridItem(2, 5);
      @include mq($from: vp5) {
        @include gridItem(2, 20);
      }
    }
    
    .carousel__nav {
      margin-right: 0 !important;
      margin-top: rem(30);
      display: block;
      
      &__buttons {
        display: flex;
        align-items: center;
        
        margin: 0 0 rem(30);
        @include mq($from: vp5) {
          margin: rem(25) 0 rem(30);
        }

        button {
          padding: 0;
          @include animated-underline();
          
          padding: rem(5) 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          justify-content: start;

          &:before {
            top: 90%;
          }
        }
        button:hover {
          transform: translate(0,0);
        }
        .carousel__prev {
          opacity: 0.5;
        }
        h5 {
          margin: 0;
          margin-left: auto;
        }
        span {
          margin: 0 rem(10);
          display: block;
          height: rem(12);
          flex: 0 0 rem(1);
          background-color: var(--c-b-black);
        }
      }
    }


    &__progress {
      position: relative;

      @include offsetContent(right);
      @include gridItem(2, 11);
      @include mq($from: vp5) {
        @include gridItem(2, 23);
      }

      display: block;
      height: rem(1);
      background-color: rgba(0, 0, 0, 0.2);

      span {
        position: absolute;
        left: 0;
        width: 0%;
        top: 0;
        bottom: 0;
        background-color: var(--c-b-white);
      }
    }
  }


  &__preview {

    @include mq($from: vp4) {
      display: grid;
      grid-template-columns: repeat(23, 1fr);
      grid-column-gap: rem(25);
    }
    
    &:hover .text {
      opacity: 0.5;
      
      @include mq($from: vp5) {
        transform: translateX(rem(20));
      }
      h4 {
        text-decoration: underline;
      }
    }
    .media-focal {
      height: 100%;
      aspect-ratio: 16 / 9;
      margin-right: 25%;
      
      @include mq($from: vp4) {
        margin-right: 0;
        grid-column: span 7;
      }
      @include mq($from: vp5) {
        grid-column: span 5;
      }
      @include mq($from: vp7) {
        grid-column: span 4;
      }
    }
    .text {
      margin-top: rem(20);
      transition: all var(--default-transition);
      grid-column: span 16;
      @include mq($from: vp5) {
        margin-top: 0;
        grid-column: span 13;
      }
      @include mq($from: vp7) {
        grid-column: span 10;
      }
      
      h4 {
        max-width: rem(600);
        @include fontStyle(heading-4);
        margin-bottom: rem(10);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 3em;
        & + p {
          display: none;
        }
      }
    }

  }
}
