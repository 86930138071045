@import "@scss/base";

.store-locator {
  $module: ".store-locator";
  width: 100%;
  @include mq($from: vp5) {
    height: 100vh;
  }

  &__inner {
    background-color: var(--c-b-grey4);
    display: grid;
    grid-template-areas:
      "map"
      "filters"
      "search"
      "list";
    grid-template-rows: 50vh auto auto 50vh;

    @include mq($from: vp5) {
      display: grid;
      grid-template-areas:
        "filters search"
        "list map";
      grid-template-rows: rem(100) 60vh;
      grid-template-columns: 33% 67%;
    }
  }

  &__filters {
    grid-area: filters;
    box-sizing: border-box;

    padding: rem(20) rem(10);
    border-bottom: rem(1) solid var(--c-b-grey3);

    @include mq($from: vp5) {
      border-bottom: 0;
      padding: 0 rem(30) 0 rem(50);
    }

    display: flex;
    align-items: center;

    .select {
      @include mq($from: vp5) {
        padding: rem(30) 0;
        border-bottom: rem(1) solid var(--c-b-grey3);
      }

      margin: 0;
      display: flex;
      width: 100%;

      &__selector {
        border: 0;
        padding: 0;

        font-size: rem(16);
        line-height: rem(24);
        @include mq($from: vp5) {
          font-size: rem(20);
          line-height: rem(30);
        }
        text-transform: uppercase;
        color: initial;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 0 0 calc(100% - rem(30));
      }
      &:after {
        top: 50%;
        right: 0;
      }
    }
  }
  &__search {
    grid-area: search;
    padding: rem(20) rem(10);
    border-bottom: rem(1) solid var(--c-b-grey3);

    @include mq($from: vp5) {
      border-bottom: 0;
      padding: rem(30) rem(30) rem(30) 0;
    }

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__field {
      flex: 1 1 50%;
      display: flex;
      align-items: center;

      input {
        min-width: rem(300);
        border: 0;
        appearance: none;
        padding: 0;
        background-color: transparent;
        @include fontStyle(paragraph-1);

        @include mq($from: vp5) {
          font-size: rem(20);
        }
        text-transform: uppercase;
        &::placeholder {
          font-size: rem(20);
          line-height: rem(30);
        }
      }
      svg {
        font-size: rem(20);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        height: 1em;
      }
    }

    &__geoloc {
      font-size: rem(20);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1em;
      height: 1em;
    }
  }
  &__list {
    grid-area: list;
    box-sizing: content-box;

    overflow: hidden;
    overflow-y: auto;

    @include mq($from: vp5) {
      padding: 0 rem(30) 0 rem(50);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      padding: rem(30) rem(10);
      transition: padding var(--default-transition);

      @include mq($from: vp5) {
        padding: rem(30) 0;
      }
      border-bottom: rem(1) solid var(--c-b-grey3);

      h5 {
        @include fontStyle(paragraph-2);
        font-weight: 600;
        margin-bottom: rem(10);
      }
      p {
        @include fontStyle(paragraph-2);
      }

      &.is-active {
        color: var(--c-b-grey1);
        padding-left: rem(10);
        @include mq($from: vp5) {
          padding-left: rem(30);
        }
        h5 {
          text-decoration: underline;
        }
        p {
          font-weight: 600;
        }
      }
    }
  }
  &__map {
    grid-area: map;
    width: 100%;
    height: 100%;
  }

  &-cluster {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--cluster-size);
    height: var(--cluster-size);
    border-radius: 50%;

    .text {
      font-size: rem(14);
      line-height: rem(22);
      font-weight: 600;
      color: var(--c-b-white);
    }

    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 rem(5) rgba(0, 0, 0, 0.2);
    box-sizing: content-box;

    z-index: 90;
  }
  &-marker {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    font-size: rem(40);
    height: 0;
    z-index: 80;
  }

  &-search-location {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    font-size: rem(60);
    height: 0;

    z-index: 100;
  }
}
