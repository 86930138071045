@import "@scss/base";

.country-select-button {
  $module: ".country-select-button";

  button {
    text-transform: uppercase;
  }

  .svg-icon {
    width: rem(12);
    height: rem(10);
    fill: var(--c-b-white);
  }
}
