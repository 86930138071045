@import "@scss/base";

.input-field {
  $formfield: ".input-field";
  position: relative;
  margin: rem(2) 0 0; // COMMENT: Calculated spacing = 2 + 7 + 3*2 = 15
  padding: rem(3) 0; // COMMENT: Give some room for the label's minus position when active/focussed

  --input-bg: transparent;

  &__field {
    @include fontStyle(paragraph-2);
    @include mq($until: vp3) {
      font-size: rem(16);
    }
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    appearance: none;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    width: 100%;
    padding: rem(18) rem(18) rem(16) rem(18);
    border: rem(1) solid var(--c-b-grey3);
    background-color: var(--input-bg);
    color: var(--c-b-grey1);
    line-height: 1;
    outline: 0;
    transition: all 0.25s ease, text-indent 0s ease 0s;

    /////////// PLACEHOLDER BROWSER STUFF /////////////
    &::placeholder {
      // COMMENT: Hide the "real" placeholder text
      color: var(--input-bg);
      opacity: 0;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--input-bg);
      opacity: 0;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--input-bg);
      opacity: 0;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--input-bg);
      opacity: 0;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--input-bg);
      opacity: 0;
    }
    &:-webkit-autofill {
      // Expose a hook for JavaScript when auto fill is shown.
      // JavaScript can capture 'animationstart' events
      animation-name: onAutoFillStart;
    }
    &:not(:-webkit-autofill) {
      animation-name: onAutoFillStop;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover {
      // COMMENT: Known issue - Chrome has a bug regarding font-size when hovering autofill-suggestions
      -webkit-box-shadow: 0 0 0 10000px var(--input-bg) inset; // COMMENT: Actual background-color
      transition: background-color 10000s ease-in-out 0s;
      -webkit-text-fill-color: var(--c-b-black) !important;
      color: var(--c-b-black) !important;
      // font-size: rem(20);
      outline: 0;
    }

    &:-moz-autofill,
    &:-moz-autofill-preview {
      background-color: red;
      color: #000;
    }
    &:-moz-ui-invalid {
      // COMMENT: Handles Firefox's internal validation, ex. when type="email" without validation rules
      color: var(--c-b-black);
      border-color: var(--c-s-error2);
      outline: 0; // COMMENT: Overwrite browser styling
      box-shadow: none; // COMMENT: Overwrite browser styling

      + #{$formfield}__label {
        color: var(--c-s-error2);
      }
    }

    //////////// FOCUS ///////////////
    &:focus {
      border: rem(1) solid var(--c-b-grey2);
    }

    /////////// LABEL ////////////////
    &:placeholder-shown + #{$formfield}__label,
    &.placeholder-shown + #{$formfield}__label {
      top: rem(32);
      @include fontStyle(paragraph-2);
      color: var(--c-b-grey3);
      background-color: transparent;
    }

    &:focus + #{$formfield}__label {
      top: rem(4);
      font-size: rem(11);
      background-color: var(--c-b-white);
      color: var(--c-b-grey1);
    }

    &:-webkit-autofill:focus + #{$formfield}__label {
      // COMMENT: When in (Ch)rome, hovering an autofill-suggestion (supposedly...)
      top: rem(4) !important;
      font-size: rem(11);
      background-color: var(--c-b-white);
    }
  }
  &__label {
    // COMMENT: This label functions as the usual placeholder-attr
    @include fontStyle(paragraph-2);
    display: block;
    position: absolute;
    pointer-events: none;
    left: rem(18);
    transform: translate(0, -50%);
    transition: all 0.25s ease;
    line-height: 1;
    color: var(--c-b-grey3);
    top: rem(4);
    background-color: var(--c-b-white);
    padding: 0 rem(2);
    z-index: 1;
    max-width: 85%;
  }

  // for * mandatory <span>*</span>
  &[required],
  &.is-required {
    #{$formfield}__required {
      display: inline-block;
      vertical-align: text-top;
    }
  }

  // when invalid, put class
  &.is-invalid {
    #{$formfield}__field {
      color: var(--c-b-black);
      border-color: var(--c-s-error2);

      &:focus {
        border-color: var(--c-s-error2);
      }
    }
    #{$formfield}__field + #{$formfield}__label {
      color: var(--c-s-error2);
      font-size: rem(11);
    }
    #{$formfield}__field:placeholder-shown + #{$formfield}__label,
    #{$formfield}__field.placeholder-shown + #{$formfield}__label {
      color: var(--c-s-error2);
      font-size: rem(14);
    }
    #{$formfield}__field:focus + #{$formfield}__label,
    #{$formfield}__field.placeholder-shown:focus + #{$formfield}__label {
      top: rem(4);
      background-color: var(--c-b-white);
      color: var(--c-s-error2);
      font-size: rem(11);
    }
  }

  // when valid
  &.is-valid {
    #{$formfield}__field {
      color: var(--c-b-grey1);
      border-color: var(--c-b-grey2);
    }
    #{$formfield}__field + #{$formfield}__label {
      color: var(--c-b-grey2);
      font-size: rem(11);
    }
  }
  &.is-disabled {
    #{$formfield}__field {
      color: var(--c-b-grey3);
      border-color: var(--c-b-grey3);
    }
    #{$formfield}__label {
      color: var(--c-b-grey3);
    }
  }

  &__message {
    @include fontStyle(paragraph-2);
    margin-top: rem(5);
    color: var(--c-s-error2);
  }
}
@keyframes onAutoFillStart {
  from {
    width: inherit;
  }
  to {
    width: inherit;
  }
}

@keyframes onAutoFillCancel {
  from {
    width: inherit;
  }
  to {
    width: inherit;
  }
}
