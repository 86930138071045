@import "@scss/base";

:root {
  --portfolio-tile-gap: #{rem($grid-gap-small)};
}

.portfolio-layout {
  $module: ".portfolio-layout";

  @include verticalSpacing;
  @include verticalSpacing(true);
  @include container;

  --portfolio-tile-gap: #{rem($grid-gap-small)};
  @include mq($from: vp5) {
    --portfolio-tile-gap: #{rem($grid-gap-regular)};
  }
  @include mq($from: vp7) {
    --portfolio-tile-gap: #{rem($grid-gap-large)};
  }
  @include mq($from: vp8) {
    --portfolio-tile-gap: #{rem($grid-gap-xlarge)};
  }
  @include mq($from: vp9) {
    --portfolio-tile-gap: #{rem($grid-gap-xxlarge)};
  }

  &__header {
    @include grid;
    .message-block {
      @include gridItem(2, 10);
      @include mq($from: vp5) {
        @include gridItem(3, 21, 1, 1);
      }
    }
    &__inner {
      position: relative;
      margin-bottom: rem(30);

      @include gridItem(2, 10);
      @include mq($from: vp5) {
        @include gridItem(3, 21, 1, 1);
        margin-bottom: rem(50);
      }
      h1 {
        @include fontStyle(heading-1);
      }
      p {
        @include fontStyle(paragraph-2);
      }

      .action-button {
        margin-top: rem(20);
        display: flex;
      }
      .basic-link {
        margin-top: rem(20);
        display: inline-block;

        @include mq($from: vp4) {
          margin-top: 0;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  &__wrapper {
    @include gridItem(1, 12);
    @include mq($from: vp5) {
      @include gridItem(1, 24, 2, 1);
    }

    @include grid;

    grid-row-gap: rem(30);

    .feature-small {
      grid-column: span 12;
      padding: 0;
      @include mq($from: vp5) {
        grid-column: span 24;
        padding: 0;
      }
    }

    & > * {
      padding-bottom: rem(15);
      border-bottom: rem(1) solid var(--c-b-grey3);
      margin-bottom: rem(45);

      .action-button,
      .action-menu {
        position: absolute;
      }

      .action-menu {
        top: rem(10);
        right: rem(10);
        .action {
          text-transform: none;
          .label {
            font-size: rem(12);
          }
        }
      }
      //info button
      .action-button {
        z-index: 999;
        top: rem(10);
        right: rem(10);
        height: rem(33);
        padding: rem(5) rem(13);
        margin-top: 0 !important;
        transition: 250ms ease;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: rem(12);
        @include mq($from: vp5) {
          opacity: 0;
        }
      }

      &:hover {
        @include mq($from: vp5) {
          .action-button {
            opacity: 1;
          }
        }
      }
    }
  }

  //   &__row {
  //     width: 100%;
  //     margin-top: rem(40);

  //     // display: flex;
  //     // flex-wrap: wrap;
  //     //    justify-content: center;
  //     display: grid;

  //     // gap: rem(20);
  //     // display: grid;
  //     // grid-gap: 20px;
  //     // grid-template-columns: repeat(auto-fill, 180px);
  //     // grid-template-columns: repeat(auto-fill, minmax(auto, auto));
  //     grid-auto-rows: auto;
  //     // position: relative;

  //     .portfolio-material-tile {
  //       flex: 1 1 150px;
  //     }
  //   }
}
