@import "@scss/base";

.mini-basket-product {
  $module: ".mini-basket-product";
  padding: rem(50) 0;

  box-sizing: border-box;
  //auto to allow for long prices
  grid-template-columns: 1fr auto;

  // grid-template-columns: 1fr minmax(50%, 1fr) 1fr;
  display: grid;

  grid-template-areas:
    "image text"
    "quantity prices";

  grid-template-columns: rem(100) auto;
  grid-gap: rem($grid-gap-small);
  @include mq($from: vp3) {
    grid-gap: rem($grid-gap-regular);
  }
  @include mq($from: vp5) {
    padding: rem(30) 0;
    grid-template-columns: rem(120) auto minmax(25%, 1fr);
    grid-template-areas:
      "image text prices"
      "links quantity quantity";
  }
  @include mq($from: vp7) {
    grid-template-columns: rem(150) auto minmax(25%, 1fr);
    grid-gap: rem($grid-gap-large);
  }
  @include mq($from: vp8) {
    grid-template-columns: rem(180) auto minmax(25%, 1fr);
    grid-gap: rem($grid-gap-xlarge);
  }
  @include mq($from: vp9) {
    grid-gap: rem($grid-gap-xxlarge);
  }

  //percentage leaves 30/640 for spacing
  &__image {
    grid-area: image;
    display: block;
    background-color: var(--c-t-beige);
    position: relative;
    height: 0;
    padding-bottom: 100%;

    .product-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      max-height: inherit;
      max-width: inherit;
      box-sizing: border-box;
      
      &--full-horizontal {
        padding: 10% 2%;
      }
      &--full-vertical {
        padding: 2%% 4%;
      }
      &--scale-small {
        padding: 23% 18%;
      }
      &--scale-medium {
        padding: 15% 12%;
      }
      &--scale-large {
        padding: 10% 6%;
      }

      img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    @include mq($from: vp5) {
      flex-direction: row;
    }
    grid-area: image;
    align-self: flex-end;
    @include mq($from: vp5) {
      align-self: center;
      grid-area: links;
    }
  }
  &__link {
    display: inline-flex;
    font-size: rem(12);
    line-height: 1.8333;
    color: var(--c-b-grey2);
    text-decoration: underline;
    &:not(:first-child) {
      @include mq($from: vp5) {
        margin-left: rem(20);
      }
      @include mq($until: vp5) {
        margin-top: rem(10);
      }    
    }
  }

  &__text {
    grid-area: text;
    min-height: rem(180);
    h3 {
      @include fontStyle(heading-4);
      margin-bottom: rem(16);
    }

    p {
      @include fontStyle(paragraph-2);
    }
    p + p {
      margin-top: rem(5);
    }
    strong {
      font-weight: $weight-medium;
    }

    input {
      margin-top: rem(20);
    }
  }
  &__quantity {
    grid-area: quantity;
    align-self: center;
    margin-top: rem(20);

    @include mq($from: vp5) {
      margin-top: 0;
      align-self: end;
    }
  }
  &__prices {
    grid-area: prices;
    align-self: center;
    text-align: right;
    margin-top: rem(20);

    @include mq($from: vp5) {
      margin-top: 0;
      align-self: start;
    }

    h4 {
      @include fontStyle(heading-4);
      text-transform: none;
      white-space: nowrap;
    }
    h5 {
      color: var(--c-b-grey2);
      text-decoration: line-through;
      @include fontStyle(heading-5);
    }
  }
  &__unique-material-notice{
    color: var(--c-s-error2);
  }
}
