@import "@scss/base";

.status-bar {
  $module: ".status-bar";

  padding: rem(12) rem(17);

  font-size: rem(12);
  display: flex;
  box-sizing: border-box;

  background-color: var(--c-b-black);
  color: var(--c-b-white);
  min-height: rem(40);
  strong {
    margin-right: 0.5em;
    font-weight: 700;
  }
}
