@import "@scss/base";
/*
Loading spinner is used to show a loading state. Size is controlled by font-size since all dimentions are relative.
*/
.loading-dots {
  $module: ".loading-dots";
  $root-size: 100;
  $dot-size: 18;
  --component-bg: var(--c-b-black);

  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  font-size: rem(80);
  display: flex;
  align-items: center;

  > span {
    display: block;
    position: absolute;
    width: ($dot-size / $root-size) + em;
    height: ($dot-size / $root-size) + em;
    border-radius: 50%;
    background: var(--component-bg);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  > span:nth-child(1) {
    left: (8 / $root-size) + em;
    animation: lds-ellipsis1 0.6s infinite;
  }
  > span:nth-child(2) {
    left: (8 / $root-size) + em;
    animation: lds-ellipsis2 0.6s infinite;
  }
  > span:nth-child(3) {
    left: calc(0.5em - #{(($dot-size/2) / $root-size) + em});
    animation: lds-ellipsis2 0.6s infinite;
  }
  > span:nth-child(4) {
    right: (8 / $root-size) + em;
    animation: lds-ellipsis3 0.6s infinite;
  }
  &--inverted {
    --component-bg: var(--c-b-white);
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0.333em, 0);
    }
  }
}
