@import "@scss/base";

.category-navigation-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding-bottom: rem(30);
    @include mq($from: vp5){
        padding: 0 15vw rem(30);
    }
    
    &__item {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 15px;
        padding: 0 rem(15);
        opacity: .5;
        &.is-active{
            opacity: 1;
        }
    }
}