.pdp-product-image-zoom {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;

  &.show {
    display: block;
    cursor: zoom-out;
  }

  canvas {
    position: absolute;
    touch-action: none;
  }
}