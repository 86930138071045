@import "@scss/base";

.menu-overlay {
  $module: ".menu-overlay";
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: map-get($z-indexes, "mega-menu");

  &.is-active {
    pointer-events: auto;
  }

  &__backdrop {
    cursor: pointer;
    display: flex;
    background-color: rgba(0, 0, 0, 0.15);
    height: calc(100 * var(--vh));
    width: 100vw;
    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      @include mq($from: vp5) {
        width: 30vw;
      }
      @include mq($from: vp6) {
        width: 50vw;
      }
      img,
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;

    background-color: var(--c-b-white);
    left: 100vw;
    width: 85vw;
    
    &.filterOverlay{
      @include mq($until: vp5){
        width: 100vw;
      }
    }
    
    height: 100%;

    @include mq($from: vp5) {
      width: 70vw;
      height: calc(100% - var(--site-header-height));
      padding-top: var(--site-header-height);

      display: flex;
    }
    @include mq($from: vp6) {
      width: 60vw;
    }
    @include mq($from: vp7) {
      width: 50vw;
    }
  }
}
