@import "@scss/base";

.invoice-tile {
  &:hover {
    .product-tile__link {
      &:before {
        right: 100%;
      }
    }
  }

  .product-tile-link-container {
    .product-tile__link {
      &:hover {
        &:before {
          right: 0;
        }
      }
    }
  }

  &.is-stolen {
    .product-tile__image {
      .image {
        border: 1px solid red;
      }
    }
  }

}

.invoice-upload {
  padding-left: clamp(8.75rem, 7.36842vw, 13.75rem);
  padding-right: clamp(8.75rem, 7.36842vw, 13.75rem);
  padding-bottom: 30px;
  display: flex;

  .invoice-input {
    font-size: 1rem;
    line-height: 1.5625;
  }
}