@import "@scss/base";

.notify-me-overlay {
  $module: ".notify-me-overlay";

  @include menuOverlay;

  padding-top: rem(100);

  &__headline {
    @include fontStyle(heading-3);
    margin-bottom: rem(30);
  }
  &__bodytext {
    @include fontStyle(paragraph-2);
    margin-bottom: rem(20);
  }
  &__error {
    margin-top: rem(60);
    @include fontStyle(paragraph-1);
    color: var(--c-s-error2);
  }
  .action-button {
    position: relative;
    --button-bgc: #d6e1b9;
    padding: rem(5) rem(20);
    height: rem(60);

    .text,
    .icon {
      position: relative;
      z-index: 2;
    }

    &.is-disabled {
      @include shimmerGreen;
      opacity: 0.9;
    }
  }
  &__form {
    .form__row {
      display: flex;
      justify-content: space-between;
    }
    .input-field {
      padding: 0;
      margin: 0;

      &__message {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
    .input-field,
    .action-button {
      display: flex;
      flex: 0 0 48%;
    }
  }
  &__receipt {
    .action-button {
      width: 48%;
    }
  }
}
