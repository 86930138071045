// SPACING - CUSTOMIZED
// Docs: http://tachyons.io/docs/layout/spacing/

// An eight step powers of two scale ranging from 0 to 16rem.

// Base:
//   p = padding
//   m = margin

// Modifiers:
//   a = all
//   h = horizontal
//   v = vertical
//   t = top
//   r = right
//   b = bottom
//   l = left

//   0 = none
//   1 = 1st step in spacing scale
//   2 = 2nd step in spacing scale
//   3 = 3rd step in spacing scale
//   4 = 4th step in spacing scale
//   5 = 5th step in spacing scale
//   6 = 6th step in spacing scale
//   7 = 7th step in spacing scale
//   8 = 8th step in spacing scale

@mixin responsive-spacing($breakpoint: null) {
  @if ($breakpoint) {
    @for $i from 0 through $margin-steps {
      $size: marginValue($i);
      //padding
      @include mq($from: #{$breakpoint}) {
        .pa#{$i}-#{$breakpoint} {
          padding: $size;
        }
        .pl#{$i}-#{$breakpoint} {
          padding-left: $size;
        }
        .pr#{$i}-#{$breakpoint} {
          padding-right: $size;
        }
        .pt#{$i}-#{$breakpoint} {
          padding-top: $size;
        }
        .pb#{$i}-#{$breakpoint} {
          padding-bottom: $size;
        }

        .pv#{$i}-#{$breakpoint} {
          padding-top: $size;
          padding-bottom: $size;
        }
        .ph#{$i}-#{$breakpoint} {
          padding-left: $size;
          padding-right: $size;
        }

        // margins
        .ma#{$i}-#{$breakpoint} {
          margin: $size;
        }
        .ml#{$i}-#{$breakpoint} {
          margin-left: $size;
        }
        .mr#{$i}-#{$breakpoint} {
          margin-right: $size;
        }
        .mt#{$i}-#{$breakpoint} {
          margin-top: $size;
        }
        .mb#{$i}-#{$breakpoint} {
          margin-bottom: $size;
        }

        .mv#{$i}-#{$breakpoint} {
          margin-top: $size;
          margin-bottom: $size;
        }
        .mh#{$i}-#{$breakpoint} {
          margin-left: $size;
          margin-right: $size;
        }
      }
    }
  } @else {
    @for $i from 0 through $margin-steps {
      $size: marginValue($i);
      .pa#{$i} {
        padding: $size;
      }
      .pl#{$i} {
        padding-left: $size;
      }
      .pr#{$i} {
        padding-right: $size;
      }
      .pt#{$i} {
        padding-top: $size;
      }
      .pb#{$i} {
        padding-bottom: $size;
      }

      .pv#{$i} {
        padding-top: $size;
        padding-bottom: $size;
      }
      .ph#{$i} {
        padding-left: $size;
        padding-right: $size;
      }

      // margins
      .ma#{$i} {
        margin: $size;
      }
      .ml#{$i} {
        margin-left: $size;
      }
      .mr#{$i} {
        margin-right: $size;
      }
      .mt#{$i} {
        margin-top: $size;
      }
      .mb#{$i} {
        margin-bottom: $size;
      }

      .mv#{$i} {
        margin-top: $size;
        margin-bottom: $size;
      }
      .mh#{$i} {
        margin-left: $size;
        margin-right: $size;
      }
    }
  }
}
