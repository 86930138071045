@import "@scss/base";

.input-field-w-button {
  $formfield: ".input-field-w-button";
  display: grid;
  align-items: center;
  position: relative;

  &--inverted {
    #{$formfield}__wrapper {
      border-bottom: 1px solid var(--c-b-white) !important;
    }

    #{$formfield}__info-message,
    input,
    ::placeholder {
      color: var(--c-b-white) !important;
    }
    ::placeholder {
      margin-left: 0;
    }
    .svg-icon {
      fill: var(--c-b-white) !important;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-b-black);
  }
  input {
    @include fontStyle(paragraph-2);
    width: 100%;
    border: none;
    outline: none;
    padding: rem(10) 0;
    background-color: transparent;
  }
  .button {
    cursor: pointer;
  }

  .svg-icon {
    fill: var(--c-b-black);
    cursor: pointer;
  }
}

::placeholder {
  @include fontStyle(paragraph-2);
  opacity: 1;
  color: var(--c-b-black);
}

.arrow-icon {
  color: var(--c-b-black);
  font-size: rem(24);
}
