@import "@scss/base";

.media-focal {
  &:not(.media-focal--reveal) {
    @include shimmer;
  }
}

.media-focal,
.product-image {
  $module: ".media-focal";
  position: relative;
  display: block;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;

  &__lazy {
    display: inline;
  }

  &__image,
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    position: absolute;
    top: 0;
    left: 0;

    // object-position: set by focal point
  }

  &__play-icon {
    border: rem(2) solid var(--c-b-white);

    color: var(--c-b-white);

    display: none;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: rem(65);
    height: rem(65);

    .svg-icon {
      width: rem(35);
      height: rem(35);
    }

    .link-wrapper--video & {
      display: inline-flex;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;

    &--10pct {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.1) -3.64%,
          rgba(0, 0, 0, 0.05) 100%);
    }

    &--20pct {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.2) -3.64%,
          rgba(0, 0, 0, 0.1) 100%);
    }

    &--30pct {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.3) -3.64%,
          rgba(0, 0, 0, 0.15) 100%);
    }
  }

  &__save-button,
  &__image-modal-button {
    position: absolute;
    top: rem(14);
    right: rem(14);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rem(20);
    height: rem(20);
    box-sizing: border-box;

    transition: all var(--default-transition);

    background-color: rgba(0, 0, 0, 0.1);

    .icon {
      display: inline-flex;
      fill: var(--c-b-white);
    }

    .text {
      position: absolute;
      transform-origin: center right;
      transform: scaleX(0.2);
      opacity: 0;
      transition: all var(--default-transition);
      color: var(--c-b-white);
      right: 100%;
      padding-right: rem(10);
      top: 0;
      white-space: nowrap;
      background-color: rgba(0, 0, 0, 0.01);
    }

    &:hover {
      .text {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }

  &__image-modal-button {
    top: rem(40);
    @include mq($until: vp5) {
      display: none;
    }
  }

  .lazy-load-image-background {
    @include shimmer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.lazy-load-image-loaded {
      display: flex !important;

      &::before {
        content: none;
      }
    }
  }
}

.product-image {
  width: 100%;
  height: 100%;
}

@media (any-hover: hover) {
  .media-focal {
    &__save-button,
    &__image-modal-button {
      opacity: 0;
      transform: scale(0.9);
    }

    &:hover .media-focal__save-button,
    &:hover .media-focal__image-modal-button {
      opacity: 1;
      transform: scale(1);
    }
  }

  a .media-focal,
  button .media-focal {

    img,
    video {
      transition: filter 0.3s, opacity 0.3s,
        transform 1s cubic-bezier(0.8, 0, 0.2, 1) !important;
    }
  }

  a:hover .media-focal,
  button:hover .media-focal {

    img,
    video {
      transform: scale(1.05) !important;
    }
  }
}

//blur effect
.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter 0.3s;
}

.lazy-load-image-background.blur>img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded>img {
  opacity: 1;
  transition: opacity 0.3s;
}

// opacity effect
.lazy-load-image-background.opacity {
  opacity: 0;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
  opacity: 1;
  transition: opacity 0.5s var(--anim-ease), transform 0.3s;
}