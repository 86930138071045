@import "@scss/base";

.choose-payment-form {

  .payment-logo-wrapper {
    margin: rem(30) 0 rem(30);
  }
  
  .choose-payment-logo {
    max-width: 60vw;
    @include mq($from: vp2) {
      max-width: 60vw;
    }

    @include mq($from: vp3) {
      max-width: 20vw;
    }

    @include mq($from: vp5) {
      //padding: rem(11) rem(22);
      //min-width: 0;
    }
  }

  .action-button--primary {
    --tag-c: var(--c-b-black);
    --tag-bgc: transparent;

    border: rem(1) solid var(--c-b-black);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem(10);
    min-width: 60vw;
    height: rem(70);
    transition: all var(--default-transition);
    margin-top: 0;

    font-family: $font-primary;
    font-size: rem(13);
    line-height: rem(22);
    text-transform: uppercase;

    color: var(--tag-c);
    background: var(--tag-bgc);

    @include mq($from: vp2) {
      min-width: 60vw;
    }

    @include mq($from: vp3) {
      min-width: 20vw;
    }

    @include mq($from: vp5) {
      padding: rem(11) rem(22);
      //min-width: 0;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
    }

    &.is-active {
      --tag-bgc: var(--c-b-black);
      --tag-c: var(--c-b-white);
    }
  }

  .form {
    h4 {
      @include fontStyle(heading-3);
      padding-bottom: rem(30);
    }
  }

  .radio__label {
    span {
      color: var(--c-b-grey2);
    }
  }
}