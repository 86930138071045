@import "@scss/base";

.unique-material-uavailable-modal {
  //$module: ".out-of-stock-modal";

  &__content {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: rem(30) rem(40);

    h3 {
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }
    p {
      @include fontStyle(paragraph-1);
      margin-bottom: rem(20);
    }

    .action-button {
      margin-top: auto;
    }
  }
}
