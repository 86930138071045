@import "@scss/base";

.image-gallery-outer {
  position: relative;
  @include verticalSpacing();  
}

.image-gallery {
  $module: ".image-gallery";
  @include container;
  overflow: hidden;

  @include mq($from: vp4) {
    height: 54vh;
  }
  @include mq($from: vp5) {
    height: 60vh;
  }
  @include mq($from: vp9) {
    height: 68vh;
  }

  --image-gallery-c: var(--c-b-black);
  color: var(--image-gallery-c);
  --image-gallery-bgc: transparent;
  background: var(--image-gallery-bgc);

  &--inverted {
    --image-gallery-c: var(--c-b-white);
  }

  &--has-bgcolor {
    @include verticalSpacing(true);

    #{$module}__wrapper {
      padding-bottom: rem(30);
      @include mq($from: vp4) {
        padding-bottom: rem(90);
      }
    }
  }

  &--3-or-less {
    height: auto;
    #{$module}__wrapper {
      grid-template-rows: none;
    }
  }

  &--expanded {
    height: "auto";

    #{$module}__wrapper {
      // just for mobile's sake
      grid-template-rows: minmax(2rem, auto) minmax(4rem, auto) auto;
    }
  }

  &__wrapper {
    height: auto;
    @include grid;
    overflow: hidden;
    grid-template-rows: minmax(2rem, auto) minmax(4rem, auto) 22vh;

    @include mq($from: vp4) {
      grid-template-rows: minmax(2rem, auto) minmax(4rem, auto) auto;
    }
  }

  &__headline {
    @include fontStyle(heading-3);
    padding-bottom: rem(20);
    @include gridItem(2, 10, 1, 1);
    @include mq($from: vp5) {
      @include gridItem(3, 10, 1, 1);
    }
  }
  &__bodytext {
    @include fontStyle(paragraph-2);
    padding-bottom: rem(40);
    @include gridItem(2, 10, 2, 1);
    @include mq($from: vp5) {
      @include gridItem(3, 10, 2, 1);
    }
  }

  &__left-col {
    height: auto;
    @include gridItem(1, 6, 3, 1);
    @include mq($from: vp5) {
      grid-column: 3 / span 10;
    }
  }
  &__right-col {
    height: auto;
    @include gridItem(7, 6, 3, 1);
    @include mq($from: vp5) {
      @include gridItem(14, 9, 1, 3);
    }
  }
  &__image {
    height: auto;

    margin-bottom: rem(10);
    @include mq($from: vp5) {
      margin-bottom: rem(50);
    }
    &:last-child {
      margin-bottom: 0;
      @include mq($from: vp5) {
        margin-bottom: 0;
      }
    }

    .media-focal {
      height: 100%;
      position: relative;
      .lazy-load-image-background {
        position: static;
      }
      &__image {
        position: static;
      }
    }
  }
  img {
    width: 100%;
  }
  &__button {
    position: absolute;
    bottom: 1vw;
    left: 50%;
    display: flex;
    transform: translateX(-50%);

    @include mq($from: vp4) {
      bottom: 2vw;
    }
    @include mq($from: vp9) {
      bottom: 3vw;
    }
    // justify-content: center;
    // align-items: center;
    // margin-top: rem(20);
    .svg-icon {
      transform: rotate(90deg);
    }
  }
}
