@import "@scss/base";

.material-set-list {
  $module: ".material-set-list";
  @include verticalSpacing();
  margin-top: -10vw;
  @include mq($from: vp5) {
    margin-top: -8vw;
  }
  @include mq($from: vp7) {
    margin-top: -5vw;
  }

  &__tiles {
    @include container();
    @include grid();

    .material-set-tile {
      cursor: pointer;
      border-bottom: rem(1) solid var(--c-b-grey3);
      padding-bottom: rem(20);
      grid-column-end: span 6;
      margin-bottom: rem(56);
      @include mq($from: vp5) {
        margin-bottom: rem(75);
        grid-column-end: span 8;
      }
    }
    .spot-tile {
      grid-column-end: span 6;
      margin-bottom: rem(56);
      @include mq($from: vp5) {
        margin-bottom: rem(75);
        grid-column-end: span 8;
      }
    }
  }
  
  &__footer {
    margin-top: rem(30);
    display: block;
    text-align: center;
    .action-button {
      margin-top: rem(20);
    }
  }
  
  .tag-list {
    @include mq($from: vp5) {
      padding-bottom: rem(70);
    }
    &__inner {
      justify-content: flex-start;
    }
  }
}
