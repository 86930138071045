@import "@scss/base";

.micro-tile-grid {
	$module: '.micro-tile-grid';

	@include container;
	@include verticalSpacing;

	&__inner {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: rem(15);
		grid-row-gap: rem(30);
		
		// @include mq($from: vp2) {
		// 	grid-column-gap: rem(15);
		// 	grid-template-columns: repeat(3, 1fr);
		// }
		@include mq($from: vp4) {
			grid-template-columns: repeat(4, 1fr);
		}
		@include mq($from: vp5) {
			grid-row-gap: rem(40);
			grid-column-gap: rem(20);
			grid-template-columns: repeat(5, 1fr);
		}
		// @include mq($from: vp7) {
		// 	grid-template-columns: repeat(6, 1fr);
		// }
	}

	
	&__item {
		grid-column: span 1;
		display: block;

		.media-focal {
			display: block;
			height: auto;

			.lazy-load-image-background,
			&__image {
				position: static;
			}
			&__image {
				aspect-ratio: 1 / 1;
			}
			&__save-button {
				display: none;
			}			
		}
		h4 {
			@include fontStyle(trumpet-1);
			margin-top: rem(10);
			@include mq($from: vp3) {
				@include fontStyle(heading-5);
				margin-top: rem(15);
			}
			@include mq($from: vp5) {
				@include fontStyle(heading-4);
				margin-top: rem(15);
			}
			// @include mq($from: vp7) {
			// 	margin-top: rem(30);
			// }	
		}
	}
}
