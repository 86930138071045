.loader-dots {
  flex: 1;
}

.configurator-loader {
  display: flex;

}

@media screen and (max-width: 5000px) {
  .configurator-loader.configurator-loadsize {
    min-height: calc(var(--param-count)*rem(69));
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .configurator-loader.configurator-loadsize {
    min-height: calc(var(--param-count)*rem(65));
    width: 100%;
  }
}
