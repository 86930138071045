@import "@scss/base";

.product-price {
  $module: ".product-price";
  > span {
    display: inline-block;
  }

  &__original {
    margin-left: rem(5);
  }
}

.animation-fade-in {
  animation: fadeIn var(--default-transition);
}
