@import "@scss/base";

.order-receipt {
  $module: ".order-receipt";
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &__message {
    display: block;
    flex: 0 0 100%;
    margin-top: rem(20);

    @include mq($from: vp5) {
      flex: 0 0 50%;
      margin-left: 50%;
    }
    h5 {
      max-width: rem(600);
      font-size: rem(12);
      font-weight: $weight-medium;
      padding-bottom: rem(5);
    }
    p {
      max-width: rem(600);
      font-size: rem(10);
    }
  }

  .status-bar,
  .info-panel,
  .product-line {
    flex: 0 0 100%;
  }
  .order-calculation {
    flex: 0 0 100%;
    overflow: hidden;
    @include mq($from: vp5) {
      flex: 0 0 50%;
      margin-left: 50%;
    }
  }
}
