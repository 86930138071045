@import "@scss/base";

.timeline-bodytext {
  $module: ".timeline-bodytext";

  width: 73vw;
  padding-right: rem(100);
  
  @include mq($from: vp3) {
    padding-right: rem(150);
    width: 70vw;
  }
  @include mq($from: vp4) {
    width: 56vw;
    height: 100vh;
    padding-right: rem(200);
    align-self: start;
  }
  @include mq($from: vp5) {
    width: 50vw;
    height: 56vh;
    align-self: center;
  }
  @include mq($from: vp7) {
    width: 30vw;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: rem(30);
  }

  p {
    display: block;
    @include fontStyle(paragraph-2);
    padding-bottom: rem(10);

    @include mq($from: vp5) {
      padding-top: 40vmin;
    }
  }

  .action-button {
    display: inline-flex;
    justify-content: flex-start;
    margin-top: rem(20);
  }

  &--two-cols {
    width: 180vw;

    #{$module}__inner {
      grid-template-columns: 1fr 1fr;
    }

    @include mq($from: vp3) {
      width: 60vw;
    }
    @include mq($from: vp4) {
      width: 90vw;
    }
    @include mq($from: vp5) {
      width: 70vw;
    }
    @include mq($from: vp6) {
      width: 65vw;
    }
    @include mq($from: vp7) {
      width: 60vw;
    }
    @include mq($from: vp8) {
      width: 55vw;
    }
  }
}
