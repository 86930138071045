@mixin container($no-side-margin: false, $use-margin: false) {
  $mobile-size: clamped(
    $container-horizontal-spacing-small,
    $container-horizontal-spacing-regular,
    vp2
  );

  @if $use-margin {
    @if $no-side-margin == no-right {
      margin-left: $mobile-size;
    } @else if $no-side-margin == no-left {
      margin-right: $mobile-size;
    } @else {
      margin-left: $mobile-size;
      margin-right: $mobile-size;
    }

    @each $breakpoint, $size in $container-horizontal-spacing-values-fluid {
      @include mq($from: $breakpoint) {
        @if $no-side-margin == no-right {
          margin-left: $size;
        } @else if $no-side-margin == no-left {
          margin-right: $size;
        } @else {
          margin-left: $size;
          margin-right: $size;
        }
      }
    }
  } @else {
    @if $no-side-margin == no-right {
      padding-left: $mobile-size;
    } @else if $no-side-margin == no-left {
      padding-right: $mobile-size;
    } @else {
      padding-left: $mobile-size;
      padding-right: $mobile-size;
    }

    @each $breakpoint, $size in $container-horizontal-spacing-values-fluid {
      @include mq($from: $breakpoint) {
        @if $no-side-margin == no-right {
          padding-left: $size;
        } @else if $no-side-margin == no-left {
          padding-right: $size;
        } @else {
          padding-left: $size;
          padding-right: $size;
        }
      }
    }
  }
}

.container {
  @include container;
}
