@mixin menuOverlay() {
  display: block;
  padding: calc(var(--mode-switch-height) + rem(20)) rem(20) rem(20);

  box-sizing: border-box;
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  overflow-y: auto;

  @include mq($from: vp5) {
    height: calc(100 * var(--vh) - var(--site-header-height));
    padding: 0 rem(60) rem(60);
  }
}
