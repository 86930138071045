@import "@scss/base";

.feature-full-width {
  $module: ".feature-full-width";
  @include grid;
  @include container;
  @include verticalSpacing;
  grid-template-rows: repeat(3, auto);
  @include mq($from: vp4) {
    grid-template-rows: auto;
  }

  --feature-full-width-c: var(--c-b-black);
  color: var(--feature-full-width-c);
  --feature-full-width-bgc: transparent;
  background: var(--feature-full-width-bgc);

  &--inverted {
    --feature-full-width-c: var(--c-b-white);
  }
  &--only-media {
    #{$module}__media {
      padding-bottom: 0;
      @include mq($from: vp5) {
        padding-bottom: 0;
      }
    }
  }

  &__media {
    grid-column: 1 / span 12;
    // height: 42vw;
    padding-bottom: rem(40);
    grid-row: 1;
    @include mq($from: vp5) {
      grid-column: 1 / span 24;
      padding-bottom: rem(60);
    }

    .media-focal {
      height: 46vw;
    }
  }
  &__headline {
    @include fontStyle(heading-2);
    grid-column: 2 / span 10;
    grid-row: 2;
    padding-bottom: rem(20);
    @include mq($from: vp5) {
      grid-column: 5 / span 8;
      padding-bottom: 0;
    }
  }
  &__bodytext {
    @include fontStyle(paragraph-2);
    grid-column: 2 / span 10;
    grid-row: 3;
    @include mq($from: vp5) {
      grid-column: 13 / span 8;
      grid-row: 2;
    }
  }
  &__button.add-margin-top {   
    margin-top: rem(10);
  }

  .media-focal__overlay {
    position: static;
  }
}
