@import "@scss/base";

.timeline-presentation-image {
  $module: ".timeline-presentation-image";

  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: column;

  width: rem(330);
  padding-right: rem(40);
  max-height: 100%;
  
  @include mq($from: vp5) {
    width: auto;
    height: 100%;
    align-self: center;
    aspect-ratio: 3 / 4;
    max-height: rem(1000);
  }
  // @include mq($from: vp6) {
  //   width: rem(550);
  //   height: calc((16 / 11) * rem(550));
  // }
  // @include mq($from: vp7) {
  //   width: rem(600);
  //   height: calc((16 / 11) * rem(600));
  // }
  // @include mq($from: vp9) {
  //   width: rem(800);
  //   height: calc((16 / 11) * rem(800));
  // }

  &--offset {
    padding-top: 5vmin;
    justify-content: flex-start;
  }

  &__image {
    position: relative;
    width: 100%;
    margin-bottom: rem(20);
    max-height: 100%;

    .media-focal {
      position: relative;
      display: block;
      min-width: 10vw;

      .lazy-load-image-background {
        position: static;
        display: block;
      }
      &__save-button {
        display: none !important;
      }

    }

    > .media-focal {
      .media-focal__image {
        aspect-ratio: 11 / 16;
        position: static;
        display: block;

        // animation-name: mediaAnim1;
        // animation-duration: 5s;
        // animation-timing-function: cubic-bezier(0.5, 0.19, 0.27, 0.53);
        // animation-direction: alternate;
        // animation-iteration-count: infinite;
      }
    }


    .action-button {
      position: absolute;
      bottom: rem(20);
      right: rem(20);
      border: 0;
      color: var(--c-b-white);
      background-color: var(--c-b-black);

      width: rem(35);
      height: rem(35);
      svg {
        width: rem(15);
        height: rem(15);
      }

      &:hover {
        opacity: 1;
      }
    }
  }
  &__caption {
    position: absolute;
    @include fontStyle(paragraph-2);

    color: var(--c-b-white);
    top: 0;
    right: 0;
    z-index: 3;

    span {
      display: block;
      transform: rotate(-90deg) translate(rem(-20), rem(10));
      transform-origin: top right;
    }
  }

  &__badge {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: rem(10);
    right: rem(10);
    width: rem(76);
    height: rem(76);
    
    overflow: hidden;

    border-radius: 50% 50%;

    span {
      color: var(--c-b-white);
      font-size: rem(12);
      max-width: 100%;
      overflow: hidden;
      z-index: 1;
      font-weight: 500;
      font-size: rem(12);
      line-height: 1.3;
      text-transform: uppercase;
      padding: rem(5);
      text-align: center;
    }
    .media-focal {
      position: absolute;
      inset: 0;
      img {
        display: inline-flex;
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
