@import "@scss/base";

.content-block {
  min-height: 30vh;
  background-color: #eee;
  .test {
    width: rem(100);
    height: rem(50);
    width: var(--spacing-1);
  }
}
