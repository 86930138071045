@import "@scss/base";

.mega-menu {
  $module: ".mega-menu";
  overflow-y: auto;
  width: 100%;
  background-color: var(--c-s-site-bgc);

  @include mq($from: vp5) {
    height: calc(100vh - var(--site-header-height));
    background-color: transparent;
    overflow: visible;
  }

  .search-form {
    margin-bottom: rem(60);
  }

  &__container {
    // width: 85vw;
    min-height: 40vh;
    transform: translateX(0);
    background-color: var(--c-s-site-bgc);

    @include menuOverlay();

    @include mq($from: vp5) {
      background-color: transparent;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      padding: 0;

      transform: translateX(0);
    }

    transform: translateX(0);
    transition: transform var(--default-transition);

    &.is-active {
      @include mq($from: vp5) {
        transform: translateX(-70vw);
      }

      @include mq($from: vp6) {
        transform: translateX(-50vw);
      }
    }
  }

  &__inner {
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto;
    //max-height: 80vh;

    @include mq($from: vp5) {
      //flex: 0 0 70vw;
      padding: rem(60) rem(30) rem(30);
    }

    @include mq($from: vp6) {
      // flex: 0 0 50vw;
      padding: rem(60) rem(40) rem(40);
    }
  }

  &__mobile-search-item {
    display: inline-flex;
    align-items: center;
    margin-bottom: rem(60);

    span {
      padding-left: rem(10);
      text-transform: uppercase;
      font-size: rem(16);
    }
  }

  &__mobile-root {
    &-item {

      >.mega-menu__button,
      >.collapsible>.mega-menu__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include fontStyle(heading-2);
        padding-bottom: rem(22);

        @include mq($from: vp5) {
          padding-bottom: rem(25);
        }

        .svg-icon {
          display: none;
        }
      }

      .mega-menu__list-item {
        .mega-menu__button {
          font-size: rem(14);
        }
      }
    }
  }

  &__list {
    &.is-active {
      #{$module}__button {
        opacity: 0.6;
      }
    }

    h2,
    h4 {
      //@include fontStyle(heading-3);
      padding-bottom: rem(13);
      font-size: 13px;
      font-weight: $weight-medium;
      text-transform: uppercase;

      @include mq($from: vp5) {
        padding-bottom: rem(13);
      }
    }

    li {
      border-top: rem(1) solid var(--c-s-border);
    }

    li:last-child {
      border-bottom: rem(1) solid var(--c-s-border);
    }

    &-item {
      .mega-menu__button {
        padding: rem(7) 0;
        text-transform: capitalize;
        @include mq($from: vp5) {
          padding: rem(7) 0;
          font-size: rem(13);
        }

        @include mq($from: vp7) {
          font-size: rem(13);
        }

        // span {
        //   transition: padding-left var(--default-transition);
        // }
        // &:hover {
        //   span {
        //     padding-left: rem(20);
        //   }
        // }
      }

      &__preload {
        position: absolute;
        visibility: hidden;
        top: 100%;
        left: 100%;
        height: 0;
        width: 0;
        overflow: hidden;
      }
    }
  }

  &__footer {
    background-color: var(--c-b-black);
    min-height: 60vh;
    display: flex;
    align-items: flex-end;

    .mega-menu__container {
      padding-top: rem(50);
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
    }
  }

  .svg-icon {
    fill: var(--c-b-black);
  }

  &__list+&__list {
    @include mq($from: vp5) {
      margin-top: rem(35);
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    box-sizing: border-box;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //@include fontStyle(heading-5);
    font-size: rem(13);

    &:hover {
      opacity: 1 !important;
    }
  }

  &__back-link {
    margin-bottom: rem(20);

    .svg-icon {
      transform: rotate(180deg);
    }
  }

  .collapsible.is-active {
    margin-bottom: rem(38);
    transition: margin-bottom 0.2;

    .svg-icon {
      transform: rotate(0deg);
    }
  }
}