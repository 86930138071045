@import "@scss/base";

.nav-list {
  $module: ".nav-list";
  @include container;
  @include grid;
  @include verticalSpacing;
  // padding-top: rem(40); //removed to tighten layout

  position: relative;
  z-index: 1;

  --nav-list-c: var(--c-b-black);
  --nav-list-bc: var(--c-b-grey3);
  --nav-list-bgc: transparent;

  color: var(--nav-list-c);
  background: var(--nav-list-bgc);
  // overflow: hidden;

  &--inverted {
    --nav-list-c: var(--c-b-white);
    --nav-list-bc: var(--c-b-white);
    .nav-button {
      color: var(--c-b-white);
      .svg-icon {
        fill: var(--c-b-white);
      }
    }
  }
  &.has-custom-headline {
    #{$module}__headline--default {
      display: none;
    }
  }

  position: relative;
  &.is-active {
    #{$module}__item {
      opacity: 0.6;
    }
  }

  &__inner {
    @include gridItem(1, 12);
    @include mq($from: vp5) {
      @include gridItem(3, 20);
    }
  }

  &__headline {
    margin-bottom: rem(30);
    h2 {
      @include fontStyle(heading-3);
    }
  }

  &__item {
    border-bottom: rem(1) solid var(--nav-list-bc);

    &:first-child {
      border-top: rem(1) solid var(--nav-list-bc);
    }
    &.is-active {
      opacity: 1 !important;
    }
  }

  &__image {
    pointer-events: none;
    position: absolute;
    z-index: 100;
    left: 60vw;
    top: 0;

    img {
      display: block;
      width: 25vw;
      position: absolute;
      transform: translateY(-50%);
      height: auto;
    }
  }
  &__footer {
    margin-top: rem(30);
  }
  &__secondary {
    margin-top: rem(30);

    @include mq($from: vp5) {
      margin-top: rem(70);
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: flex-start;
    }

    .nav-button {
      margin-top: rem(30);
      margin-right: rem(40);

      @include mq($from: vp5) {
        margin-top: 0;
      }
      span {
        font-size: rem(14);
      }
    }
    ul {
      position: relative;
      @include mq($from: vp5) {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        p,
        a {
          @include fontStyle(link-2);
          height: rem(20);
          padding: 0;
          display: inline-flex;
          white-space: nowrap;
          align-items: center;
          justify-content: center;
          @include mq($from: vp5) {
            padding: 0 rem(10);
          }
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
