@import "@scss/base";

.timeline-section {
  $module: ".timeline-section";

  position: relative;
  display: flex;
  color: var(--c-b-white);

  padding-right: rem(100);

  @include mq($from: vp4) {
    padding-right: rem(400);
  }
  @include mq($from: vp8) {
    padding-right: rem(600);
  }

  &:first-of-type {
    padding-right: 0;
  }

  //for debugging
  h6 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    font-weight: bold;
    background-color: red;
    color: yellow;
    pointer-events: none;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: rem(1);
      height: 100vh;
      background: red;
    }
  }
}
