.rich-text {
  @include fontStyle(paragraph-2);

  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  ol,
  img,
  p + p,
  > a,
  table,
  blockquote,
  img,
  iframe,
  embed,
  object {
    margin-top: 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @include fontStyle(paragraph-2);
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    @include fontStyle(paragraph-2);

    white-space: normal;

    a {
      text-decoration: underline;
    }
    strong {
      font-weight: 400;
    }
    em {
      font-style: normal;
    }
  }

  span {
    @include fontStyle(paragraph-2);
  }

  > a {
    @include fontStyle(link-1);
    display: inline-block;
    text-decoration: underline;
  }

  ol li,
  ul li {
    margin-left: 2em;
    padding-left: 1em;
  }

  ol li {
    list-style: decimal outside;
  }
  ul li {
    list-style: square outside;
  }

  blockquote {
    padding-left: 2em;
    border-left: rem(1) solid var(--c-b-grey);
  }

  sup {
    font-size: 70%;
    position: relative;
    top: -0.5em;
  }

  sub {
    font-size: 70%;
    position: relative;
    top: 0.5em;
  }
  small {
    font-size: 0.875em;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  iframe,
  object,
  embed {
    background: var(--c-b-grey3);
    display: block;
    width: 100%;
  }

  table {
    min-width: 100%;
    line-height: 1.4;

    @include mq($from: vp4) {
      min-width: 0;

      th,
      td {
        padding-right: rem(30);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
