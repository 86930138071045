@import "@scss/base";

.text-tile {
  $module: ".text-tile";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: auto;
  // gap: rem(30);

  --text-tile-c: var(--c-b-black);
  color: var(--text-tile-c);
  --text-tile-bgc: transparent;
  background: var(--text-tile-bgc);

  &--inverted {
    --text-tile-c: var(--c-b-white);
  }

  @include mq($from: vp4) {
    width: auto;
  }
  @include mq($from: vp5) {
    flex: 100%; // pushes image up.
    padding-left: 0;
  }

  &__tag {
    padding-bottom: rem(5);
    h6 {
      @include fontStyle(trumpet-3);
    }
  }
  &__tag + &__headline {
    h2 {
      padding-top: 0;
      @include mq($from: vp5) {
        padding-top: 0;
      }
    }
  }

  &__headline {
    margin-bottom: auto;
    h2 {
      @include fontStyle(heading-3);
    }
    a {
      display: block;
    }
  }

  &__bodytext {
    margin-top: auto;
    a {
      display: block;
    }
    .rich-text,
    p {
      @include fontStyle(paragraph-2);
      display: block;
    }
  }

  &__link,
  &__contact {
    margin-top: auto;
  }

  &__headline + &__bodytext {
    .rich-text,
    p {
      padding-top: rem(20);
    }
  }
  &__bodytext + &__link {
    .nav-button {
      margin-top: rem(30);
    }
  }
  &__headline + &__link {
    .nav-button {
      margin-top: rem(30);
    }
  }
  &__bodytext + &__contact {
    margin-top: rem(20);
  }
}
