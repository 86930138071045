@import "../mixins/mq";

$mq-breakpoint-names: ("vp1", "vp2", "vp3", "vp4", "vp5", "vp6", "vp7", "vp8", "vp9");
$mq-breakpoints: (
  vp1: 150px,
  vp2: 375px,
  vp3: 560px,
  vp4: 768px,
  vp5: 1024px,
  vp6: 1160px,
  vp7: 1400px,
  vp8: 1900px,
  vp9: 2400px,
);

// stylelint-disable
// exporting breakpoints to Javascript
:export {
  breakpoint-vp1-max: 419px;
  breakpoint-vp2-min: 420px;
  breakpoint-vp2-max: 559px;
  breakpoint-vp3-min: 560px;
  breakpoint-vp3-max: 767px;
  breakpoint-vp4-min: 768px;
  breakpoint-vp4-max: 1023px;
  breakpoint-vp5-min: 1024px;
  breakpoint-vp5-max: 1159px;
  breakpoint-vp6-min: 1160px;
  breakpoint-vp6-max: 1399px;
  breakpoint-vp7-min: 1900px;
  breakpoint-vp7-max: 2399px;
  breakpoint-vp8-min: 2400px;
}
// stylelint-enable
