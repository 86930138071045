@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform-origin: left center;
  }
  20% {
    opacity: 0;
    transform: translateX(-20px) scaleX(0.97);
    transform-origin: left center;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform-origin: left center;
  }
  20% {
    opacity: 0;
    transform: translateX(20px) scaleX(1.03);
    transform-origin: left center;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 1;
  }
}

@mixin shimmer() {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #efefef;
    background-image: linear-gradient(
      to right,
      #efefef 0%,
      #f7f7f7 20%,
      #efefef 40%,
      #efefef 100%
    );
    background-repeat: no-repeat;
    background-size: 400% 100%;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }
}

@mixin shimmerGreen() {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #d6e1b9;
    background-image: linear-gradient(
      to right,
      #d6e1b9 0%,
      #e3eec4 20%,
      #d6e1b9 40%,
      #d6e1b9 100%
    );
    background-repeat: no-repeat;
    background-size: 400% 100%;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }
}

@mixin fadeFromBottom($delay) {
  opacity: 0;
  animation: fadeInBottom 1s var(--anim-ease) forwards #{$delay}s;
}

@mixin fadeFromTop($delay) {
  opacity: 0;
  animation: fadeInTop 1s var(--anim-ease) forwards #{$delay}s;
}

@mixin fadeFromLeft($delay) {
  opacity: 0;
  animation: fadeInLeft 1s var(--anim-ease) forwards #{$delay}s;
}

@mixin fadeFromRight($delay) {
  opacity: 0;
  animation: fadeInRight 1s var(--anim-ease) forwards #{$delay}s;
}
@mixin bounce() {
  animation: bounce 4s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    left: 0%;
    transform: translate(0, 0);
  }
  30% {
    left: 100%;
    transform: translate(-100%, 0);
  }
  50% {
    left: 100%;
    transform: translate(-100%, 0);
  }
  80% {
    left: 0%;
    transform: translate(0, 0);
  }
  100% {
    left: 0%;
    transform: translate(0, 0);
  }
}
