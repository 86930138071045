@import "@scss/base";

.portfolio-submission {
  $module: ".portfolio-submission";

  @include menuOverlay;

  &__preview {
    @include mq($from: vp5) {
      padding-top: rem(60);
    }

    h6 {
      @include fontStyle(trumpet-3);
    }

    .lazy-load-image-background {
      height: rem(200);
      display: block;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
  &__header {
    margin: rem(50) 0 rem(25);
    position: relative;

    h3 {
      @include fontStyle(heading-3);
    }

    .success {
      position: absolute;
      top: calc(100% - 1em);
      right: 0;
      @include fontStyle(trumpet-3);
    }
  }
  &__no-login-notice{
    margin: rem(20) auto 0;
    width: 100%; 
    max-width: rem(360);
    @include fontStyle(paragraph-3);
  }
  &__create {
    margin: rem(40) auto 0;
    border: rem(1) dashed var(--c-b-grey2);

    display: flex;
    width: 100%;
    height: rem(75);
    align-items: center;
    justify-content: center;
    max-width: rem(360);
    @include fontStyle(trumpet-3);
    font-weight: 500;
    transition: background-color var(--default-transition);

    &:hover {
      background-color: var(--c-b-grey4);
    }
    .icon {
      margin-right: rem(10);
    }
  }
  .portfolio-submission-list {
    li + li {
      .portfolio-submission-list-item {
        border-top: 0;
      }
    }
  }
  .portfolio-submission-list-item {
    position: relative;
    transition: opacity var(--default-transition);

    --portfolio-item-border-color: var(--c-b-grey3);
    border-bottom: rem(1) solid var(--portfolio-item-border-color);

    &--create {
      --portfolio-item-border-color: var(--c-b-black);
    }

    &__inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: rem(60);
      width: 100%;
    }

    &:first-child {
      border-top: rem(1) solid var(--portfolio-item-border-color);
    }
    .action {
      opacity: 0;
      transition: opacity var(--default-transition);
      margin-left: auto;
      margin-right: rem(10);
    }

    &:hover {
      .action {
        opacity: 1;
      }
    }
    &.is-unavailable {
      cursor: default;

      background-color: var(--c-b-grey4);
      .portfolio-submission-list-item__inner {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
      }

      .action {
        opacity: 1;
      }
    }

    .count {
      @include fontStyle(paragraph-3);
    }
    .name {
      @include fontStyle(paragraph-1);
    }

    .icon {
      margin-left: rem(10);
    }

    .icon,
    .name,
    .count,
    .input {
      position: relative;
      margin-right: rem(20);
    }

    .input {
      flex: 1 1 40%;

      appearance: none;
      border: 0;
      padding: 0;
      @include fontStyle(paragraph-2);
    }
    .save {
      text-decoration: underline;
    }
  }

  &.has-chosen {
    .portfolio-list-item:not(.is-active) .portfolio-list-item__inner {
      opacity: 0.6;
    }
  }
  &.is-creating {
    .portfolio-list-item:not(.portfolio-list-item--create) {
      .portfolio-list-item__inner {
        opacity: 0.6;
        cursor: default;
      }
      &:hover {
        .action {
          opacity: 0;
        }
      }
    }

    .portfolio-list-item.is-unavailable:not(.portfolio-list-item--create) {
      &:hover {
        .action {
          opacity: 1;
        }
      }
    }
  }

  .portfolio-submission-product {
    display: grid;

    grid-template-columns: 31% 69%;
    grid-gap: rem(30);

    &__image {
      grid-column: 1 / 2;
      background-color: var(--c-t-beige);
      position: relative;
      height: 0;
      padding-bottom: 100%;

      .product-image {
        position: absolute;
        top: 0;
        left: 0;

        img {
          box-sizing: border-box;
          padding: 15% 6%;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
    &__text {
      grid-column: 2 / 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      h3 {
        @include fontStyle(heading-4);
        padding-bottom: rem(25);
        min-height: 1em;

        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        @include fontStyle(paragraph-2);

        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .product-price {
        margin-top: auto;
        font-weight: 600;
      }
    }
  }
}
