@import "@scss/base";

.search-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: rem(1) solid var(--c-b-grey2);

  input {
    border: 0;
    text-transform: uppercase;
    line-height: rem(30);
    flex: 1;
    padding: 0;
    margin: 0;

    @include mq($until: vp3) {
      font-size: rem(16);
    }
    @include mq($from: vp5) {
      line-height: rem(40);
    }
  }
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
  }
}
