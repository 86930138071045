@import "@scss/base";

.article-module {
  $module: ".article-module";

  --article-module-c: var(--c-b-black);
  color: var(--article-module-c);
  --article-module-bgc: transparent;
  background: var(--article-module-bgc);

  &--inverted {
    --article-module-c: var(--c-b-white);
  }

  &--has-bgcolor {
    @include verticalSpacing(true);
  }

  @include grid();
  @include container;
  @include verticalSpacing;

  &__headline {
    @include fontStyle(heading-2);
    padding-bottom: rem(20);
    grid-column: 1 / span 12;

    @include mq($from: vp5) {
      grid-column: 3 / span 22;
      padding-bottom: rem(30);
    }
  }

  &__manchet {
    @include fontStyle(paragraph-1);
    padding-bottom: rem(35);
    grid-column: 1 / span 12;

    @include mq($from: vp5) {
      grid-column: 3 / span 11;
    }
  }

  .rich-text-module {
    grid-column: 1 / span 12;
    @include mq($from: vp5) {
      grid-column: 3 / span 11;
    }
  }

  &__aside {
    @include fontStyle(paragraph-3);
    font-weight: 600;
    grid-column: 1 / span 12;
    padding-bottom: rem(35);

    @include mq($from: vp4) {
      grid-column: 1 / span 7;
    }

    @include mq($from: vp5) {
      grid-column: 15 / span 9;
      grid-row: 2 / span 3;
      padding-bottom: 0;
    }

    @include mq($from: vp7) {
      grid-column: 16 / span 8;
    }

    .media-focal {
      height: 80vw;
      @include mq($from: vp4) {
        height: 50vw;
      }
      @include mq($from: vp5) {
        height: 30vw;
      }

      + .article-module__data {
        padding-top: rem(20);
      }
    }
  }

  &__button {
    padding-top: rem(60);
    grid-column: 1 / span 12;
    grid-row: auto;
    @include mq($from: vp5) {
      grid-column: 3 / span 11;
    }
  }

  &--no-headline {
    #{$module}__aside {
      grid-row: 1 / span 4;
    }
  }
}
