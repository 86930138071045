@import "@scss/base";

.hero-cover {
  $module: ".hero-cover";
  @include verticalSpacing(false, true);
  overflow: hidden;

  --hero-c: var(--c-b-black);
  --hero-bgc: transparent;

  color: var(--hero-c);
  background: var(--hero-bgc);

  &--inverted {
    // --hero-c: var(--c-b-white);

    #{$module}__headline {
      color: var(--c-b-white);
    }
    #{$module}__text {
      color: var(--c-b-black);
      @include mq($from: vp5) {
        color: var(--c-b-white);
      }
    }
  }

  &__inner {
    position: relative;
  }

  &__image {
    display: block;

    // @include fadeFromLeft(1);

    @include mq($from: vp5) {
      position: static;
    }
    &.media-focal {
      height: 50vh;
      @include mq($from: vp5) {
        height: 100vh;
      }
      opacity: 0;
      transform: scale(1.1);
      transition: all 3s ease;
    }

    .media-focal__lazy {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__content {
    @include grid();
    z-index: 1;

    position: relative;

    @include mq($from: vp5) {
      position: absolute;
      bottom: 25%;
      z-index: 1;
    }
  }

  &__headline {
    @include gridItem(2, 10, 1, 1);
    position: absolute;
    bottom: calc(100% + rem(60));
    white-space: pre-line;

    // @include fadeFromLeft(1.8);

    @include mq($from: vp5) {
      position: static;
      bottom: auto;
      @include gridItem(4, 10, 1, 1);
    }
    h1 {
      @include fontStyle(heading-1);
    }
    h2 {
      @include fontStyle(heading-2);
    }
  }
  &__text {
    @include gridItem(2, 10, 2, 1);

    @include mq($from: vp5) {
      padding-top: 0;
      @include gridItem(4, 8, 2, 1);
    }

    p {
      margin-top: rem(20);
      @include fontStyle(paragraph-2);
    }

    .nav-button,
    .action-button {
      box-sizing: border-box;
      margin-top: rem(35);
      width: 100%;
      @include mq($from: vp3) {
        width: auto;
      }
      @include mq($from: vp5) {
        margin-top: rem(40);
      }

      @include mq($until: vp5) {
        --button-c: var(--c-b-white);
        &:not(.is-outlined) {
          --button-bgc: var(--c-b-black);
        }
      }
    }
  }

  &__extra-content {
    margin-top: rem(50);
    @include grid();

    #{$module}--inverted & {
      #{$module}__text {
        @include mq($from: vp5) {
          color: var(--c-b-black);
        }
      }
    }
  }
  .readmore-button {
    display: block;
    padding: rem(15);
    @include mq($from: vp5) {
      padding: rem(15);
    }
    .svg-icon {
      transform: rotate(90deg);
    }
    .text {
      @include fontStyle(link-2);
    }
  }
  .readmore-text {
    display: inline;
    margin-top: rem(20);
  }
  &__curtain {
    background: var(--c-b-white);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  &--extra-content {
    #{$module}__text {
      p {
        display: inline;
      }
      .nav-button,
      .action-button {
        display: block;
      }
    }
  }

  &--ready {
    .media-focal {
      opacity: 1;
      transform: scale(1);
    }
  }

  &--text-right {
    #{$module}__headline {
      @include mq($from: vp5) {
        @include gridItem(14, 10, 1, 1);
      }
    }

    #{$module}__text {
      @include mq($from: vp5) {
        @include gridItem(14, 8, 2, 1);
      }
    }
  }
}
