// Regular
@font-face {
  font-family: "HansenGrotesque";
  font-style: normal;
  font-weight: 400;
  src: url("../font/HansenGrotesque-Regular.woff2") format("woff2"),
    url("../font/HansenGrotesque-Regular.woff") format("woff");
}
// Regular Italic
@font-face {
  font-family: "HansenGrotesque";
  font-style: italic;
  font-weight: 400;
  src: url("../font/HansenGrotesque-Italic.woff2") format("woff2"),
    url("../font/HansenGrotesque-Italic.woff") format("woff");
}
// Medium
@font-face {
  font-family: "HansenGrotesque";
  font-style: normal;
  font-weight: 500;
  src: url("../font/HansenGrotesque-Medium.woff2") format("woff2"),
    url("../font/HansenGrotesque-Medium.woff") format("woff");
}
// Medium Italic
@font-face {
  font-family: "HansenGrotesque";
  font-style: italic;
  font-weight: 500;
  src: url("../font/HansenGrotesque-MediumItalic.woff2") format("woff2"),
    url("../font/HansenGrotesque-MediumItalic.woff") format("woff");
}
// Bold
@font-face {
  font-family: "HansenGrotesque";
  font-style: normal;
  font-weight: 700;
  src: url("../font/HansenGrotesque-Bold.woff2") format("woff2"),
    url("../font/HansenGrotesque-Bold.woff") format("woff");
}
// Bold Italic
@font-face {
  font-family: "HansenGrotesque";
  font-style: italic;
  font-weight: 700;
  src: url("../font/HansenGrotesque-BoldItalic.woff2") format("woff2"),
    url("../font/HansenGrotesque-BoldItalic.woff") format("woff");
}
