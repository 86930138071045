@import "@scss/base";

.material-set-tile {
  $module: ".material-set-tile";
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  &:hover {
    #{$module}__image {
      opacity: 0.8;
    }
    #{$module}__content {
      h5 span {
        &:before {
          right: 0;
        }
      }
    }
  }
  &__image {
    position: relative;
    margin-bottom: rem(20);
    flex: 0 0 22vw;
    display: flex;
    max-height: 22vw;
    transition: opacity var(--default-transition);
    overflow: hidden;

    @include mq($from: vp5) {
      flex: 0 0 rem(200);
      max-height: rem(200);
    }
    background-color: var(--c-t-beige);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__tag {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: rem(4) rem(8);
    @include fontStyle(trumpet-2);
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 100%;

    h3 {
      @include fontStyle(heading-4);
      margin-bottom: rem(10);
      @include mq($from: vp5) {
        margin-bottom: rem(20);
      }
    }
    p {
      margin-bottom: auto;
      @include fontStyle(paragraph-2);

      @include mq($until: vp4) {
        font-size: rem(12) !important;
      }
      @include mq($from: vp5) {
        line-height: 2;
      }
    }
    p + h5 {
      margin-top: rem(20);
      @include mq($from: vp5) {
        margin-top: rem(30);
      }
    }
    h5 {
      span {
        display: inline-block;
        @include animated-underline(true);
        @include fontStyle(trumpet-2);
      }
    }
  }
}

@media (any-hover: hover) {
  .material-set-tile {
    .save-button {
      opacity: 0;
      transform: scale(0.9);
    }
    &:hover .save-button {
      opacity: 1;
      transform: scale(1);
    }
  }
}
