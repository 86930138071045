@import "@scss/base";

.tag-list {
  $module: ".tag-list";
  @include grid;
  @include container;
  margin-bottom: rem(40); // = 50 incl tag individual margins

  &__inner {
    @include gridItem(2, 11);
    @include offsetContent(right);
    @include mq($from: vp5) {
      display: flex;
      margin-left: 0;
      justify-content: center;
      width: 100%;
      @include gridItem(5, 16);
    }
  }

  &__label {
    display: inline-flex;
    white-space: nowrap;
    margin-right: rem(20);
    padding-top: rem(15);
    margin-bottom: rem(10);
    p {
      white-space: nowrap;
    }
    @include mq($from: vp5) {
      margin-bottom: 0;
    }
  }
  &__item {
    display: inline-flex;
    margin-right: rem(10);
    @include mq($from: vp5) {
      margin-bottom: rem(10);
    }

    &:nth-child(n + 4) {
      @include mq($from: vp5) {
        display: none;
      }
    }
    &:last-child {
      @include mq($from: vp5) {
        display: inline-flex;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mq($from: vp5) {
      justify-content: flex-start;
      overflow: visible;
      margin-right: 0;
      flex-wrap: wrap;
    }
  }

  &__expand {
    height: rem(45);
    text-decoration: underline;
    display: none;
    align-items: center;
    font-size: rem(12);
    margin-left: rem(10);
    white-space: nowrap;

    @include mq($from: vp5) {
      margin-left: 0;
      display: inline-flex;
    }
  }

  &.is-expanded {
    #{$module}__item {
      &:nth-child(n + 4) {
        display: inline-flex;
      }
    }
  }
}
