@import "@scss/base";

.media-info-overlay {
  $module: ".media-info-overlay";

  @include menuOverlay;

  &__image {
    .lazy-load-image-background {
      height: rem(200);
      display: block;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  &__cta {
    margin-top: rem(15);
    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: rem(20);
      height: rem(20);
    }
    .svg-icon--arrow-small {
      transform: rotate(90deg);
    }
  }
  &__button {
    @include fontStyle(paragraph-2);
    display: flex;
    align-items: center;

    .label {
      text-decoration: underline;
      display: inline-flex;
    }
    .icon {
      margin-right: rem(20);
      display: inline-flex;
    }
  }
  &__button + &__button {
    margin-top: rem(10);
  }
  &__products {
    margin-top: rem(50);
    h4 {
      @include fontStyle(heading-3);
      padding-bottom: rem(20);
      border-bottom: rem(1) solid var(--c-b-grey3);
    }

    .media-info-overlay-product {
      padding: rem(40) 0;

      border-bottom: rem(1) solid var(--c-b-grey3);
      display: grid;

      grid-template-columns: 31% 69%;
      grid-gap: rem(30);

      &:first-child {
        border-top: rem(1) solid var(--c-b-grey3);
      }

      &__image {
        background-color: var(--c-t-beige);
        position: relative;
        height: 0;
        padding-bottom: 100%;

        .product-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            box-sizing: border-box;
            padding: 15% 6%;
          }
        }
      }

      &__text {
        grid-column: 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
          @include fontStyle(heading-4);
          padding-bottom: rem(25);
          min-height: 1em;
        }
        p {
          @include fontStyle(paragraph-2);
        }
        .nav-button {
          margin-top: auto;
          @include fontStyle(heading-6);
          .text {
            font-weight: 500;
          }
        }
      }
      .product-price {
        margin-top: 0.5em;
      }
      &.has-error {
        display: block;

        h5 {
          @include fontStyle(heading-5);
        }
        p {
          @include fontStyle(paragraph-2);
        }
      }
    }
  }
}
