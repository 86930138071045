@import "@scss/base";

.feature-block {
  $module: ".feature-block";
  @include container;
  @include grid;
  @include verticalSpacing;

  --feature-block-c: var(--c-b-black);
  --feature-block-bgc: transparent;

  color: var(--feature-block-c);
  background: var(--feature-block-bgc);

  &--inverted {
    --feature-block-c: var(--c-b-white);
  }

  &__img {
    width: 100%;
    object-fit: cover;
    display: block;
  }

  .media-focal__lazy {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__img--1 {
    grid-column: 2 / span 11;
    overflow: visible;
    height: 114vw;
    .lazy-load-image-background,
    .media-focal__overlay {
      @include offsetContent(right);
    }

    @include mq($from: vp4) {
      grid-column: 1 / span 7;
      height: 72.5vw;
    }
    @include mq($from: vp5) {
      grid-column: 3 / span 11;
      height: 54vw;
    }
    @include mq($from: vp8) {
      height: 49vw;
    }

    .media-focal__save-button {
      right: 3px;
    }
  }

  &__img--2 {
    display: none;
    @include mq($from: vp5) {
      display: block;
    }
  }

  // TEXT WRAPPER

  &__text-wrapper {
    grid-column: 2 / span 10;
    padding: rem(30) 0 6vw;
    @include mq($from: vp4) {
      grid-column: 8 / span 5;
      padding-top: 10vw;
    }
    @include mq($from: vp5) {
      grid-column: 15 / span 8;
      padding-top: 14vw;
    }
  }
  &__headline,
  &__bodytext {
    margin-bottom: rem(30);
  }
  &__headline {
    @include fontStyle(heading-3);
    @include mq($from: vp5) {
      margin-bottom: rem(12);
    }
  }
  &__bodytext {
    @include fontStyle(paragraph-2);
  }
  &__tag {
    @include fontStyle(trumpet-1);
    font-weight: 700;
    padding-bottom: 0.5vw;
  }

  // Version with two images
  &:not(&--2-imgs) {
    #{$module}__img--1 {
      overflow: hidden;
    }
  }
  &--2-imgs {
    @include mq($from: vp5) {
      grid-template-rows: auto auto minmax(0, auto);
    }
    #{$module}__img--2 {
      @include mq($from: vp4) {
        height: 30vw;
        grid-column: 14 / span 7;
        align-self: start;
        grid-row: 2 / span 2;
      }
    }
    #{$module}__img--1,
    .link-wrapper {
      @include mq($from: vp5) {
        grid-column: 1 / span 11;

        grid-row: 1 / span 2;
        position: relative;
      }
    }
    #{$module}__text-wrapper {
      @include mq($from: vp5) {
        grid-row: 1;
        grid-column: 13 / span 9;
      }
    }
  }

  &--right-body {

    .link-wrapper {
      grid-column: 2 / span 11;
      @include mq($from: vp4) {
        grid-column: 1 / span 7;
      }
      @include mq($from: vp5) {
        grid-column: 3 / span 11;
      }
    }

    #{$module}__img--1 {
      @include mq($from: vp4) {
        .lazy-load-image-background,
        .media-focal__overlay {
          @include offsetContent(left);
          margin-right: 0;
          width: 100%;
        }
      }
      .media-focal__save-button {
        @include mq($from: vp3) {
          right: 15px;
        }
      }
    }
  }

  // Version with left body
  &--left-body {
    .link-wrapper {
      grid-column: 2 / span 11;
      @include mq($from: vp4) {
        grid-column: 6 / span 7;
      }
      @include mq($from: vp5) {
        grid-column: 12 / span 11;
      }
    }

    #{$module}__img--1 {
      @include mq($from: vp4) {
        grid-column: 6 / span 7;

        .lazy-load-image-background,
        .media-focal__overlay {
          margin-right: 0;
        }
        .lazy-load-image-background {
          width: 100%;
        }
      }
      @include mq($from: vp5) {
        grid-column: 12 / span 11;
      }
      .media-focal__save-button {
        @include mq($from: vp3) {
          right: -15px;
        }
        @include mq($from: vp7) {
          right: -45px;
        }
        @include mq($from: vp8) {
          right: -205px;
        }
      }
    }
    #{$module}__img--2 {
      grid-column: 5 / span 7;
    }
    #{$module}__text-wrapper {
      grid-row: 0;
      @include mq($from: vp4) {
        grid-row: 1;
        grid-column: 1 / span 5;
        padding-top: rem(83);
      }
      @include mq($from: vp5) {
        grid-column: 3 / span 8;
        padding-top: rem(136);
      }
      @include mq($from: vp7) {
        padding-top: rem(193);
      }
    }
  }
  // Version with left body and two images
  &--2-imgs--left-body {
    .link-wrapper {
      grid-column: 2 / span 11;
      @include mq($from: vp4) {
        grid-column: 6 / span 7;
      }
      @include mq($from: vp5) {
        grid-column: 14 / span 11;
      }
    }

    #{$module}__img--1 {
      margin-left: 0;

      @include mq($from: vp4) {
        .lazy-load-image-background,
        .media-focal__overlay {
          @include offsetContent(right);
        }
        grid-column: 6 / span 7;
      }
      @include mq($from: vp5) {
        grid-column: 14 / span 11;
      }

      .media-focal__save-button {
        right: -5;
        @include mq($from: vp3) {
          right: -3;
        }
      }
    }
  }
  &--2-imgs--right-body {
    .link-wrapper {
      @include mq($from: vp5) {
        grid-column: 1 / span 11;

        grid-row: 1 / span 2;
        position: relative;
      }
    }
    #{$module}__img--1 {
      @include mq($from: vp4) {
        .lazy-load-image-background,
        .media-focal__overlay {
          @include offsetContent(left);
        }
      }
    }
  }
}
