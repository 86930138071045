@import "@scss/base";

.material-list {
  $module: ".material-list";
  @include grid;
  @include container;
  grid-template-rows: auto;

  &__tiles {
    @include gridItem(1, 12, 1, 1);
    @include mq($from: vp5) {
      @include gridItem(1, 24, 1, 1);
    }
    h3 {
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }
    ul {
      @include grid;
      li {
        grid-column: span 6;
        @include mq($from: vp5) {
          grid-column: span 8;
        }
        @include mq($from: vp7) {
          grid-column: span 6;
        }
      }
    }
  }

  &__description {
    @include gridItem(1, 12, 2, 1);
    @include mq($from: vp5) {
      @include gridItem(4, 13, 2, 1);
    }
    margin-bottom: rem(40);
  }
  &__specs {
    padding-top: rem(20);
    @include gridItem(2, 10, 3, 1);
    @include mq($from: vp5) {
      margin-bottom: rem(60);
      padding-top: rem(50);
      @include gridItem(3, 12, 3, 1);
    }
    margin-bottom: rem(30);

    h3 {
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }
    p {
      @include fontStyle(oaragraph-2);
    }

    table {
      width: 100%;
      display: block;
      tbody {
        display: block;
        text-align-last: left;
      }
      tr {
        display: block;
        th {
          font-weight: 600;
          display: block;
        }
        tr {
          display: block;
        }
      }

      tr + tr {
        margin-top: rem(10);
      }
    }

    .nav-button {
      margin-top: rem(60);
    }
  }
}

.material-list-controller {
  @include verticalSpacing(true);
  @include verticalSpacing;
  .list-title {
    padding-bottom: 0;
    @include mq($from: vp5) {
      padding-bottom: 0;
    }
  }
}
