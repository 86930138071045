@import "@scss/base";

.references {
  $module: ".references";

  &__tiles {
    @include container();
    @include grid();

    .nav-tile {
      grid-column: span 6;
      @include mq($from: vp5) {
        grid-column: span 12;
      }

      &:not(:last-child):not(:nth-last-child(2)) {
        @include verticalSpacing(false);
      }

      .media-focal {
        height: 33vw;
      }
    }
  }
  &__footer {
    margin-top: rem(30);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
