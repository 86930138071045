@import "@scss/base";

.current-selection {
    margin: rem(20) 0 rem(68);

    @include mq($from: vp5) {
        margin: rem(20) 0 auto;
    }

    strong {
        @include fontStyle(paragraph-3);
        font-weight: $weight-medium;
    }

    span {
        @include fontStyle(paragraph-3);
    }
}