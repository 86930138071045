@import "@scss/base";

.mini-basket-calculation {
  $module: ".mini-basket-calculation";

  &__row + &__row {
    margin-top: rem(14);
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.mini-basket-calculation__voucher {
        @include mq($until: vp5) {
          align-items: flex-start;
        }
      }

      &:last-child {
        @include mq($from: vp5) {
          justify-content: flex-end;
        }
      }
    }

    h5 {
      @include fontStyle(paragraph-2);
      display: inline-flex;
      align-items: center;
      @include mq($until: vp5) {
        font-size: rem(15);
      }

      span {
        @include fontStyle(paragraph-2);
        display: inline-flex;
        margin-left: rem(10);
        @include mq($from: vp5) {
          font-size: rem(15);
        }
        @include mq($from: vp6) {
          margin-left: rem(20);
        }
      }
    }
    p {
      @include fontStyle(paragraph-2);
      display: inline-flex;
      margin-left: rem(10);
      display: none;
      @include mq($from: vp4) {
        display: block;
        margin-left: rem(20);
      }
    }
    button {
      @include fontStyle(paragraph-2);
      text-decoration: underline;

      margin-left: rem(10);
      @include mq($from: vp6) {
        margin-left: rem(20);
      }
    }
    &--result {
      padding: rem(17) 0;
      border-top: rem(1) solid var(--c-b-grey1);
      border-bottom: rem(1) solid var(--c-b-grey1);
    }
  }
  &__voucher {
    @include mq($until: vp5) {
      flex-direction: column;
    }
  }
  &__voucher-info {
    @include mq($until: vp5) {
      span {
        display: none;
      }
      button {
        margin-left: 0;
        font-size: rem(15);
      }
    }
    @include mq($from: vp5) {
      display: flex;
      margin-left: rem(20);
    }
  }
}
