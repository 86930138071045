@import "@scss/base";

.page-siblings {
  $module: ".page-siblings";
  position: relative;
  z-index: 1;
  &.is-active {
    #{$module}__item {
      opacity: 0.6;
    }
  }

  .nav-list {
    padding-top: 0;
  }

  &__headline {
    @include fontStyle(heading-2);
    margin-bottom: rem(24);
  }

  &__item {
    border-bottom: rem(1) solid var(--c-b-grey3);
    &.is-active {
      opacity: 1 !important;
    }

    &:first-child {
      border-top: rem(1) solid var(--c-b-grey3);
    }
  }
}
