@import "@scss/base";

.nav-tiles-block {
  @include grid;
  @include container;
  @include verticalSpacing;

  --nav-tiles-c: var(--c-b-black);
  --nav-tiles-bgc: transparent;

  color: var(--nav-tiles-c);
  background: var(--nav-tiles-bgc);

  &--inverted {
    --nav-tiles-c: var(--c-b-white);
  }

  .nav-tile {
    grid-column: 2 / span 10;
    &__img {
      width: 69%;
      height: 62vw;
      transition: opacity var(--default-transition);
      @include mq($from: vp4) {
        width: auto;
        height: 40vw;
      }
      @include mq($from: vp7) {
        height: 32vw;
      }
    }
    @include mq($from: vp4) {
      grid-column: 2 / span 10;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @include mq($from: vp5) {
      display: block;
      grid-template-columns: 1fr;
      &:nth-child(1) {
        grid-column: 1 / span 8;
      }
      &:nth-child(2) {
        grid-column: 9 / span 8;
      }
      &:nth-child(3) {
        grid-column: 17 / span 8;
      }
    }
  }
  .text-tile {
    @include mq($from: vp4) {
      padding: rem(30) 0 0 rem(30);
    }
    @include mq($from: vp5) {
      padding: rem(30) 0 0;
    }
  }
  .nav-tile + .nav-tile {
    @include mq($from: vp4) {
      margin-top: rem(30);
    }
    @include mq($from: vp5) {
      margin-top: 0;
    }
  }
  &--2col {
    .nav-tile {
      @include mq($from: vp5) {
        &:nth-child(1) {
          grid-column: 3 / span 10;
        }
        &:nth-child(2) {
          grid-column: 13 / span 10;
        }
      }

      &__img {
        @include mq($from: vp4) {
          height: 46vw;
        }
        @include mq($from: vp7) {
          height: 38vw;
        }
      }
    }
  }
}
