@import "@scss/base";

.main-footer {
  $module: ".main-footer";

  background-color: var(--c-s-site-c);
  color: var(--c-s-site-bgc);
  padding: rem(45) rem(25);

  .link {
    @include fontStyle(heading-4);
    &--small {
      @include fontStyle(trumpet-1);
      color: var(--c-b-grey3);
    }
  }

  &__links {
    li + li {
      margin-top: rem(30);
    }
  }

  &__social {
    margin-top: rem(50);

    li + li {
      margin-top: rem(25);
    }
  }
}
