@import "@scss/base";

.product-filters-block {
  $module: ".product-filters-block";

  display: flex;

  .collapsible {
    flex: 1 1 auto;
    @include mq($until: vp5){
      display: none;
    }
    &__trigger {
      display: flex;
      line-height: 16px;

      h3 {
        font-size: 13px;
        padding-right: rem(10);
      }
    }
  }

  ul {
    display: flex;

    @include mq($from: vp4) {
      margin-left: rem(-10);
      margin-right: rem(-10);
    }

    li {
      @include mq($from: vp5) {
        margin-right: rem(10);
      }
    }
  }

  p {
    @include fontStyle(paragraph-3);
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    margin-left: rem(10);
    margin-right: rem(10);
  }

  &__button {
    @include basicLink();
    font-weight: $weight-medium;

    @include mq($from: vp4) {
      padding: 0 rem(10);
    }

    &.is-active {
      color: var(--c-b-white);
      background-color: var(--c-b-black);
      text-decoration: none;
    }
  }

  &__select {
    flex: 0 1 10%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    font-weight: $weight-medium;

    span,
    select {
      font-size: rem(13);
      line-height: 1.4;
      font-weight: 500;
      text-transform: none;
    }

    select {
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__hide-filters {
    font-size: 10px;
    text-decoration: underline;
  }

  &__list {
    flex: 0 1 20%;

    &-wrapper {
      padding-top: rem(30);
      display: flex;
      width: 100%;
      justify-content: flex-start;
    }

    .checkbox {
      padding-bottom: rem(7);

      &__label {
        line-height: rem(15);
        font-size: 10px;
        margin-left: rem(10);
      }

      &__box {
        height: rem(15);
        flex: 0 0 rem(15);

        &:after {
          content: '';
        }

        &:disabled {
          border-color: var(--c-b-grey2);
          background-color: transparent;
          opacity: .5;

          +label {
            opacity: .5;
          }
        }
      }
    }

    h3 {
      @include fontStyle(paragraph-4);
      text-transform: uppercase;
      padding-bottom: rem(20);
    }
  }
  &__load-more{
    text-decoration: underline;
    font-size: 10px;
    padding-left: rem(25);
    
    &.disabled{
      pointer-events: none;
      opacity: .5;
    }
  }
  &__current-filters {
    &-wrapper {
      display: none;
      @include mq($from: vp5){
        display: flex;
      }
      position: absolute;
      right: 0;
      top: 0;
      //float: right;
    }

    &-item {
      //height: rem(30);
      background-color: var(--c-b-grey5);
      margin-left: rem(14);
      margin-top: rem(8);
      padding: rem(6) rem(6) rem(6) rem(10);
      font-size: 10px;

      &-icon {
        margin-left: rem(12);
        cursor: pointer;

        svg {
          transform: rotate(45deg);
          vertical-align: middle;
        }
      }
    }
  }
}