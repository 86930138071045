@import "@scss/base";

.contact-list {
  $module: ".contact-list";
  @include verticalSpacing;

  @include container;
  @include grid;

  &__headline {
    grid-column: 2 / span 10;
    @include fontStyle(heading-2);
    @include mq($from: vp5) {
      grid-column: 3 / span 12;
    }
  }

  &__select {
    grid-column: 2 / span 10;
    padding: rem(50) 0 rem(30);
    @include mq($from: vp4) {
      grid-column: 2 / span 6;
    }
    @include mq($from: vp5) {
      grid-column: 3 / span 8;
    }
    .select {
      &::after {
        right: 0;
      }
      &__selector {
        @include fontStyle(link-1);
        padding: rem(18) 0 rem(16);
        border-bottom: 1px solid var(--c-b-grey3);
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }
  }

  &__list-wrapper {
    grid-column: 2 / span 8;
    @include mq($from: vp5) {
      grid-column: 3 / span 8;
    }
  }

  &__list-headline {
    @include fontStyle(heading-3);
    padding: rem(60) 0 rem(30);
  }

  &__ul {
    padding-bottom: rem(20);
    &:last-of-type {
      padding-bottom: rem(60);
    }
    li {
      @include fontStyle(paragraph-2);
      &:first-of-type {
        font-weight: 700;
      }
    }
  }
}
