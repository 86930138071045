@import "@scss/base";

.pdp-download-images{
    text-decoration: underline;
    
    .is-download-button{
        --button-bgc: unset;
        .download-text{
            @include fontStyle(paragraph-4);
            
            text-decoration: underline;
            text-transform: none;
        }
        padding: 1.25rem;
    }
    .icon{
        transform: rotateZ(90deg)
    }
    .is-download{
        left: 3%;
        right: unset;
        top: unset;
        @include mq($from: vp5) {
            transform-origin: bottom;
            top:0%;
            right: 3%;
            left: unset;
        }
    }
}

