.d-n {
  display: none !important;
}
.d-i {
  display: inline !important;
}
.d-ib {
  display: inline-block !important;
}
.d-b {
  display: block !important;
}
.d-t {
  display: table !important;
}
.d-tr {
  display: table-row !important;
}
.d-tc {
  display: table-cell !important;
}
.d-f {
  display: flex !important;
}
.d-if {
  display: inline-flex !important;
}
.d-g {
  display: grid !important;
}

// responsive display utils
@for $i from 1 to length($mq-breakpoint-names) + 1 {
  $breakpoint-name: unquote(nth($mq-breakpoint-names, $i));
  @include mq($from: $breakpoint-name) {
    .d-n-#{$breakpoint-name} {
      display: none !important;
    }
    .d-i-#{$breakpoint-name} {
      display: inline !important;
    }
    .d-ib-#{$breakpoint-name} {
      display: inline-block !important;
    }
    .d-b-#{$breakpoint-name} {
      display: block !important;
    }
    .d-t-#{$breakpoint-name} {
      display: table !important;
    }
    .d-tr-#{$breakpoint-name} {
      display: table-row !important;
    }
    .d-tc-#{$breakpoint-name} {
      display: table-cell !important;
    }
    .d-f-#{$breakpoint-name} {
      display: flex !important;
    }
    .d-if-#{$breakpoint-name} {
      display: inline-flex !important;
    }
    .d-g-#{$breakpoint-name} {
      display: grid !important;
    }
  }
}
// responsive spacing
@include responsive-spacing();
@for $i from 1 to length($mq-breakpoint-names) + 1 {
  $breakpoint-name: unquote(nth($mq-breakpoint-names, $i));
  @include responsive-spacing($breakpoint-name);
}

//typography
@each $name, $typeSettings in $typography-vars {
  .t-#{$name} {
    @extend %t-#{$name};
  }
}

.sr-only  {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.t {
  &--right {
    text-align: right;
  }
  &--center {
    text-align: center;
  }
}
