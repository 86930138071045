@import "@scss/base";

.hero-staggered {
  $module: ".hero-staggered";
  @include container;

  @include verticalSpacing;
  @include topModuleSpacing;

  position: relative;

  --hero-c: var(--c-b-black);
  --hero-bgc: transparent;

  color: var(--hero-c);
  background: var(--hero-bgc);

  &--inverted {
    --hero-c: var(--c-b-white);
  }

  @include grid();
  @include mq($from: vp5) {
    // grid-template-rows: 10vw 10vw auto 10vw 10vw 10vw 10vw 10vw;
    grid-template-rows: 6vw 10vw repeat(7, auto);
  }

  &__content {
    @include grid();
    margin-bottom: rem(30);
    padding-top: rem(60);

    @include gridItem(1, 12, 2, 1);

    @include mq($from: vp5) {
      display: block;
      margin-top: 0;
      @include gridItem(14, 9, 2, 3);
      margin-bottom: rem(60);
    }
  }
  &__headline {
    @include gridItem(2, 10);

    //@include fadeFromLeft(0.6);

    @include mq($from: vp5) {
      @include gridItem(3, 8);
    }
    h1,
    h2 {
      hyphens: auto;
    }
    h1 {
      @include fontStyle(heading-1);
    }
    h2 {
      @include fontStyle(heading-2);
    }
  }
  &__text {
    margin-top: rem(20);
    @include gridItem(2, 10);

    @include mq($from: vp5) {
      @include gridItem(12, 8);
    }

    p {
      @include fontStyle(paragraph-2);
    }

    .nav-button,
    .action-button {
      margin-top: rem(60);
      // width: 100%;
      @include mq($from: vp3) {
        width: auto;
      }
      @include mq($from: vp5) {
        margin-top: rem(40);
      }
    }
  }
  .readmore-button {
    display: block;
    padding: rem(15);
    @include mq($from: vp5) {
      padding: rem(15);
    }
    .svg-icon {
      transform: rotate(90deg);
    }
    .text {
      @include fontStyle(link-2);
    }
  }
  .readmore-text {
    display: inline;
    margin-top: rem(20);
  }

  &__image {
    display: block;

    &--left {
      z-index: 1;
      @include offsetContent(left);
      @include gridItem(1, 7, 1, 1);
      max-height: 70vw;
      margin-bottom: 15vw;
      @include mq($until: vp5) {
        height: 60vw;
      }
      @include mq($from: vp5) {
        margin: 0;
        width: 100%;
        min-height: 40vw;
        @include gridItem(1, 12, 1, 5);
      }
      @include mq($from: vp7) {
        // min-height: rem(800);
      }

      //@include fadeFromLeft(0);
    }

    &--right {
      @include gridItem(5, 7, 1, 1);
      width: 100%;
      max-height: 60vw;
      margin-top: 20vw;

      @include mq($from: vp3) {
        min-height: 40vw;
      }
      @include mq($from: vp5) {
        margin-bottom: 0;
        margin-top: 0;
        max-height: none;
        @include gridItem(10, 13, 5, 4);
      }
      @include mq($from: vp7) {
        // min-height: rem(800);
      }
    }
    //@at-root fadeFromLeft(0.3);

    &.media-focal {
      z-index: auto;

      // &:before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   height: 100%;
      //   width: 100%;
      //   z-index: 1;
      //   background: var(--hero-bgc);

      //   transform-origin: left center;
      //   transition: all var(--default-transition);
      // }

      &--reveal:before {
        width: 0.001%;
      }
    }
  }

  &--extra-content {
    #{$module}__text {
      p {
        display: inline;
      }
      .nav-button,
      .action-button {
        display: block;
      }
    }
  }

  &--left-img-infront {
    #{$module}__image--right {
      z-index: 0;
    }
    #{$module}__image--left {
      z-index: 1;
    }
  }

  &--separated {
    @include mq($from: vp5) {
      grid-template-rows: repeat(10, 3.5vw) repeat(6, auto);
      grid-row-gap: rem(10);
    }
    @include mq($from: vp6) {
      grid-template-rows: repeat(10, 3vw) repeat(6, auto);
    }
    @include mq($from: vp8) {
      grid-template-rows: repeat(10, 2.5vw) repeat(6, auto);
    }
    #{$module}__content {
      @include mq($from: vp5) {
        margin: 0;
        @include gridItem(16, 8, 8, 8);
      }
    }
    #{$module}__image--left {
      @include mq($from: vp5) {
        height: 100%;
        min-height: 50vw;
        margin: 0;
        @include gridItem(3, 12, 4, 13);
      }
      @include mq($from: vp7) {
        max-height: 50vw;
      }
    }
    #{$module}__image--right {
      @include mq($from: vp5) {
        height: 100%;
        min-height: 0;
        margin: 0;
        @include gridItem(20, 5, 1, 6);

        overflow: visible;

        .lazy-load-image-background,
        .media-focal__overlay,
        .media-focal__video {
          @include offsetContent(right);
        }
        .media-focal__save-button {
          @include mq($from: vp3) {
            right: -15px;
          }
          @include mq($from: vp7) {
            right: -45px;
          }
          @include mq($from: vp8) {
            right: -205px;
          }
        }
      }
      @include mq($from: vp7) {
        @include gridItem(21, 4, 1, 6);
      }
    }
  }
}
