@import "@scss/base";

.pdp-product-images {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;

  padding: 10%;
  box-sizing: border-box;

  @include mq($from: vp5) {
    padding: rem(60) rem(20);
  }

  @include mq($from: vp8) {
    padding: rem(70) rem(90);
  }

  @include mq($from: vp5) {
    max-height: calc(100vh - #{rem($container-horizontal-spacing-regular)} - var(--site-header-height) - rem(30));
  }

  @include mq($from: vp7) {
    max-height: calc(100vh - #{rem($container-horizontal-spacing-large)} - var(--site-header-height) - rem(30));
  }

  @include mq($from: vp8) {
    max-height: calc(100vh - #{rem($container-horizontal-spacing-xlarge/2)} - var(--site-header-height) - rem(30));
  }

  &__download-mobile{
    position: absolute;
    top: rem(23);
    left: rem(20);
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin-right: rem(10);
    font-size: rem(10);
    text-decoration: underline;
    @include mq($from: vp5) {
      display: none;
    }
    &.is-styled-image{
      display: none;
    } 
  }
  &__webar {
    display: inline-flex;
    align-items: center;
    margin-right: rem(10);
    font-size: rem(10);
    text-decoration: underline;
    a {
      span {
        margin-right: rem(6);
      }
    }

    img:first-child {
      display: none;
      pointer-events: none;
      position: absolute;
    }

    position: absolute;
    top: rem(23);
    right: rem(20);

    .svg-icon {
      width: rem(20);
      height: rem(20);
      -webkit-animation: spin 3s linear infinite;
      -moz-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }

    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }

    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @include mq($from: vp5) {
      display: none;
    }
  }

  &__progress {
    appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;

    &::-webkit-progress-bar {
      background-color: transparent;
      border-radius: 2px;
    }

    &::-moz-progress-bar {
      background-color: transparent;
      border-radius: 2px;
    }

    &::-webkit-progress-value {
      border-radius: 2px;
      background-color: var(--c-b-grey3);
    }

    &::-moz-progress-value {
      border-radius: 2px;
      background-color: var(--c-b-grey3);
    }
  }

  &__carousel {
    height: 100%;
    width: 100%;

    &.is-unique-material{
      width: rem(750);
    }
    &.zoom-active{
      opacity: 0;
    }

    .swiper-container,
    .swiper-wrapper {
      z-index: 0;
      height: 100%;
    }

    .pdp-product-images__styled-img {
      height: 100%;
    }

    .pdp-product-images_styled-image-slide {
      margin-top: auto;
      margin-bottom: auto;
      display: grid;
      grid-template-areas: "image";
      grid-template-rows: 100%;
      grid-template-rows: 100%;
      touch-action: pan-y;

      @include mq($from: vp5) {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
      }

      @include mq($from: vp5) {
        max-height: calc(100vh - #{rem($container-horizontal-spacing-regular)} - var(--site-header-height));
      }

      @include mq($from: vp7) {
        max-height: calc(100vh - #{rem($container-horizontal-spacing-large)} - var(--site-header-height));
      }

      @include mq($from: vp8) {
        max-height: calc(100vh - #{rem($container-horizontal-spacing-xlarge/2)} - var(--site-header-height));
      }
    }

    &__prev {
      position: absolute;
      top: 50%;
      left: 5%;
      z-index: 1;

      &.is-disabled {
        opacity: 0;
      }

      svg {
        transform-origin: center;
        transform: rotate(180deg);
      }
    }

    &__next {
      position: absolute;
      top: 50%;
      right: 5%;
      z-index: 1;

      &.is-disabled {
        opacity: 0;
      }
    }
  }
}