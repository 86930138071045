@import "@scss/base";

.portfolio-material-tile {
  $module: ".portfolio-material-tile";
  position: relative;

  grid-column: span 6;

  @include mq($from: vp3) {
    grid-column: span 7;
  }

  @include mq($from: vp5) {
    grid-column: span 9;
  }

  &__headline {
    @include fontStyle(heading-4);
  }
  &__bodytext {
    padding-top: rem(10);
  }

  &__image {
    position: relative;
    width: 100%;
    height: 0;

    //assuming rows of 2 elements - overwritten for wide

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    padding-bottom: 100%;

    @include mq($from: vp5) {
      padding-bottom: 100%;
    }
  }
  &__text {
    padding-top: rem(20);
  }
}
