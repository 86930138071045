@import "@scss/base";

.image-modal {
  &__content {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: rem(30) rem(40);

    h3 {
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }

    p {
      @include fontStyle(paragraph-1);
      margin-bottom: rem(20);
    }

    .action-button {
      margin-top: auto;
    }

    img {
      @include mq($from: vp1) {
        //width: 200px;
        height: 150px;
      }
      @include mq($from: vp2) {
        //width: 340px;
        height: 200px;
      }
      @include mq($from: vp3) {
        //width: 500px;
        height: 330px;
      }
      @include mq($from: vp4) {
        //width: 650px;
        height: 460px;
      }
      @include mq($from: vp5) {
        //width: 900px;
        height: 600px;
      }
      @include mq($from: vp6) {
        //width: 1050px;
        height: 700px;
      }
      @include mq($from: vp7) {
        //width: 1200px;
        height: 800px;
      }
      @include mq($from: vp8) {
        //width: 1300px;
        height: 880px;
      }
    }
  }
}