@import "@scss/base";

.nav-panels-block {
  @include grid;
  @include mq($from: vp5) {
    @include container;
  }
  padding-bottom: 10vw;

  --nav-panels-c: var(--c-b-black);
  --nav-panels-bgc: transparent;

  color: var(--nav-panels-c);
  background: var(--nav-panels-bgc);

  &--inverted {
    --nav-panels-c: var(--c-b-white);
  }
}
