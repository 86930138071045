@mixin verticalSpacing($top: false, $use-margin: false) {
  $mobile-size: clamped($container-vertical-spacing-xsmall, $container-vertical-spacing-small, vp2);
  @if $top {
    @if ($use-margin) {
      margin-top: $mobile-size;
    } @else {
      padding-top: $mobile-size;
    }
  } @else {
    @if ($use-margin) {
      margin-bottom: $mobile-size;
    } @else {
      padding-bottom: $mobile-size;
    }
  }

  @each $breakpoint, $size in $container-vertical-spacing-values-fluid {
    @include mq($from: $breakpoint) {
      @if $top {
        @if ($use-margin) {
          margin-top: $size;
        } @else {
          padding-top: $size;
        }
      } @else {
        @if ($use-margin) {
          margin-bottom: $size;
        } @else {
          padding-bottom: $size;
        }
      }
    }
  }
}
