@import "@scss/base";

.basket-summary {
  $module: ".basket-summary";

  p,
  h5 {
    @include fontStyle(paragraph-2);
  }

  h6 {
    @include fontStyle(paragraph-3);
  }

  h5 {
    font-weight: $weight-medium;
    word-break: normal;

    small {
      font-weight: $weight-regular;
      display: block;
      font-size: 85.7%;
    }
  }

  h4 {
    @include fontStyle(heading-4);
  }

  strong {
    font-weight: $weight-medium;
  }

  &__product-image {
    
    @include mq($until: vp7) {
      display: none;
    }
    .product-image {
      margin: rem(10) rem(20) 0 0;
      width: unset;

      &--full-horizontal {
        img {
          padding: 10% 2%;
        }
      }
      &--full-vertical {
        img {
          padding: 2%% 4%;
        }
      }
      &--scale-small {
        img {
          padding: 23% 18%;
        }
      }
      &--scale-medium {
        img {
          padding: 15% 12%;
        }
      }
      &--scale-large {
        img {
          padding: 10% 6%;
        }
      }

      img {
        box-sizing: border-box;
        width: 100%;
        object-fit: contain;
        object-position: center;
        background-color: var(--c-t-beige);
      }
    }
  }

  &__header {
    padding-bottom: rem(25);
  }

  &__products {
    border-top: rem(1) solid var(--c-b-black);
    border-bottom: rem(1) solid var(--c-b-black);

    li {
      padding: rem(20) 0 rem(30);
      display: flex;
      justify-content: space-between;

      div {
        &:nth-child(1) {
          flex: 1 0 rem(70);
          @include mq($until: vp7) {
            flex: 0 0 rem(40);
          }
        }

        &:nth-child(2) {
          flex: 1 1 rem(250);
        }

        &:nth-child(3) {
          flex: 1 0 rem(120);
          text-align: right;
        }
      }
    }

    li+li {
      border-top: rem(1) solid var(--c-b-grey3);
    }
  }

  &__calculation {
    padding: rem(25) 0;
    border-bottom: rem(1) solid var(--c-b-black);

    div,
    footer {
      display: flex;
      justify-content: space-between;

      h5 {
        &:nth-child(2) {
          text-align: right;
        }
      }
    }

    footer {
      padding-top: rem(30);
    }
  }

  &__cta {
    margin-top: rem(25);
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: underline;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__unique-material-notice {
    color: var(--c-s-error2);
  }
}