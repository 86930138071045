@import "@scss/base";

.validate-basket-modal {
  $module: ".market-change-modal";

  &__content {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: rem(30) rem(40);

    h3 {
      @include fontStyle(heading-3);
      margin-bottom: rem(20);
    }
    p, ul {
      @include fontStyle(paragraph-1);
      margin-bottom: rem(20);
    }

    ul {
        margin-left: 0;
        margin-right: auto;
    }

    .action-button {
      margin-top: auto;
    }
  }
}
