@import "@scss/base";

.nav-highlight-block {
  $module: ".nav-highlight-block";
  display: flex;
  flex-direction: column;
  @include container;
  @include verticalSpacing;
  @include mq($from: vp4) {
    @include grid;
    grid-template-rows: repeat(11, 3.5vw) repeat(3, auto);
  }

  --highlight-c: var(--c-b-black);
  --highlight-bgc: transparent;

  color: var(--highlight-c);
  background: var(--highlight-bgc);

  &--inverted {
    --highlight-c: var(--c-b-white);
  }

  .media-focal {
    width: 100%;
    height: 100%;
  }

  &__text-wrapper {
    order: 2;
    margin-bottom: rem(55);
    @include mq($from: vp5) {
      margin-bottom: 0;
    }
    &--left {
      grid-column: 2 / span 10;
      @include mq($from: vp4) {
        @include gridItem(2, 6, 12, 3);
      }
      @include mq($from: vp5) {
        @include gridItem(3, 11, 11, 3);
      }
    }
  }

  &--left-hover-text {
    &:hover ~ .nav-highlight-block__img--left {
      opacity: 0.7;
    }
  }
  &--left-hover-img {
    &:hover {
      opacity: 0.7;
    }
  }
  &--right-hover {
    &:hover .nav-highlight-block__img--right {
      opacity: 0.7;
    }
  }

  &__headline {
    padding: rem(30) 0;
    @include fontStyle(heading-3);
  }
  &__bodytext {
    @include fontStyle(paragraph-2);
    padding-bottom: rem(20);
  }

  &__contact {
    margin-top: rem(20);
  }
  // .nav-button {
  //   padding: rem(30) 0 0;
  // }

  &__img {
    &--left {
      order: 1;
      transition: opacity 200ms ease;
      grid-column: 2 / span 10;
      height: 56vw;
      @include mq($from: vp4) {
        @include gridItem(1, 7, 1, 11);
        @include offsetContent(left);
        height: auto;
      }
      @include mq($from: vp5) {
        @include gridItem(1, 13, 1, 10);
      }
    }

    &--right {
      transition: opacity 200ms ease;
      @include mq($from: vp4) {
        height: 37.4vw;
      }
      @include mq($from: vp5) {
        height: 33.4vw;
      }
      .media-focal {
        height: 100vw;
      }
    }
  }

  // right tile wrapper
  &__wrapper {
    order: 3;
    grid-column: 2 / span 10;
    height: auto;
    @include mq($from: vp4) {
      @include gridItem(9, 4, 4, 11);
    }
    @include mq($from: vp5) {
      @include gridItem(16, 7, 4, 11);
    }
  }
}
