@import "@scss/base";

.search-page {
  $module: ".search-page";

  @include container;

  &__form {
    @include grid;
    margin-bottom: rem(40);

    @include mq($from: vp5) {
      padding-top: rem(60);
    }

    input {
      text-transform: uppercase;
      flex: 1;

      @include mq($from: vp5) {
        font-size: rem(30);

        &::placeholder {
          font-size: rem(30);
        }
      }
    }
  }

  &__paging {
    margin: rem(20) 0;
    text-align: center;
  }

  &__filter-item {
    &:nth-child(1) {
      margin-right: rem(10);

      @include mq($until: vp2) {
        margin-bottom: rem(10);
      }
    }
  }

  &__filter-list {
    margin-bottom: rem(40);

    ul {
      @include mq($until: vp2) {
        flex-wrap: wrap;
      }

      display: flex;
    }
  }

  &__tiles {
    @include mq($from: vp3) {
      @include grid();
    }
  }

  &__tile {
    border-bottom: rem(1) solid var(--c-b-grey3);
    padding-bottom: rem(20);
    margin-bottom: rem(60);

    @include mq($from: vp3) {
      grid-column: span 6;
    }

    @include mq($from: vp5) {
      grid-column: span 6;
    }

    &.page {
      .product-tile__image {
        .image {
          padding: 0;
        }
      }

      .product-tile__text {

        p,
        h3 {
          white-space: normal;
        }
      }
    }
  }
  &__suggestion{
    &-list{
      margin-bottom: rem(30);
      span{
        float: left;
        padding-right: rem(20);
      }
      ul{
        display: flex;
        flex-direction: row;
      }
    }
    &-item{
      text-decoration: underline;
      padding-right: rem(20);
    }
  }
}