@import "@scss/base";

@mixin singleAndDualTileLayoutTile() {
  display: flex;
  flex-direction: column;

  > a {
    display: flex;
    height: 0;
    padding-bottom: 73%;
    position: relative;
  }
  .media-focal {
    background-color: #eee;
  }
  .text-tile {
    text-align: center;
    padding: rem(30) 0;
    @include mq($from: vp5) {
      padding-bottom: 0;
    }
  }
}

@mixin threeTileLayoutTileLarge() {
  margin: 0 rem(30);
  @include mq($until: vp5) {
    padding: rem(30) 0;
  }
  @include mq($from: vp4) {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  min-height: 20vw;
  .nav-tile__headline {
    h2 {
      @include fontStyle(heading-2);
    }
  }
  > a {
    flex: 0 1 80%;
    position: relative;
    display: flex;
    padding-bottom: 73%; // height on mobile
  }
  .text-tile {
    text-align: center;
    flex: 1 1 20%;
    padding: rem(30) 0;
    @include mq($from: vp5) {
      padding-bottom: 0;
    }
  }
  .media-focal {
    height: auto;
    padding-bottom: 0;
  }
}

@mixin threeTileLayoutTileSmall() {
  display: flex;
  flex-direction: column;

  > a {
    display: flex;
    position: relative;
    @include mq($from: vp5) {
      height: 0;
      padding-bottom: 73%;
    }
  }

  .media-focal {
    background-color: #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  .text-tile {
    @include mq($from: vp5) {
      text-align: center;
      flex: none;
    }
  }

  @include mq($until: vp5) {
    padding: rem(30) 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: rem($grid-gap-regular);

    border-top: rem(1) solid var(--c-b-grey3);
    > a {
      height: rem(100);
    }
    .media-focal {
      grid-column: 1 / 2;
    }
    .text-tile {
      grid-column: 2 / 3;
      padding-top: 0;

      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;

      &__headline {
        margin-bottom: 0;
        h2 {
          font-size: rem(20);
        }
      }
      &__tag,
      &__bodytext {
        display: none;
      }
    }
  }
}

.stories {
  $module: ".stories";
  @include verticalSpacing();

  &__tiles {
    @include container();
    @include grid();
  }
  &__footer {
    margin-top: rem(30);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /// After lazyload
  .nav-tile {
    a > .nav-tile__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
    }
    .text-tile {
      // margin-bottom: 0;
      &__bodytext {
        margin-top: 0;
      }
      &__headline {
        margin-bottom: 0;
      }
    }
  }
  ///

  &__layout {
    border-top: rem(1) solid var(--c-b-grey3);
    @include gridItem(1, 12);

    @include mq($from: vp5) {
      padding-top: rem(50);

      @include gridItem(2, 22);
    }

    .text-tile {
      padding: rem(10) 0 0;

      @include mq($from: vp5) {
        padding: rem(30) 0 0;
      }
      &__tag {
        h6 {
          color: var(--c-t-yellow);
        }
      }
      &__headline {
        h2 {
          @include fontStyle(heading-3);
          @include mq($from: vp5) {
            min-height: 0;
          }
        }
      }
    }

    &--one {
      display: flex;
      justify-content: center;

      .nav-tile {
        flex: 0 0 100%;
        @include singleAndDualTileLayoutTile();
        @include mq($until: vp5) {
          padding: rem(30) 0;
        }
        @include mq($from: vp5) {
          flex: 0 0 50%;
        }
      }
    }

    &--one-one {
      @include grid();

      .nav-tile {
        grid-column: span 6;
        @include singleAndDualTileLayoutTile();
        @include mq($until: vp5) {
          padding: rem(30) 0;
        }
        @include mq($from: vp5) {
          grid-column: span 12;
        }
      }
    }
    &--two-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mq($from: vp5) {
        display: grid;
        grid-template-columns: 35% 7% 58%;
        grid-template-rows: minmax(1fr, auto) minmax(1fr, auto);
        grid-column-gap: 0;
        grid-template-areas:
          "tile1 space tile2"
          "tile3 space tile2";
      }

      .nav-tile {
        &:nth-child(1),
        &:nth-child(3) {
          @include threeTileLayoutTileSmall();
        }
        &:nth-child(1) {
          grid-area: tile1;
          order: 2;
          @include mq($until: vp5) {
            padding: rem(30) 0;
          }
          .text-tile {
            padding-bottom: rem(10);
            @include mq($from: vp5) {
              padding-bottom: rem(30);
            }
          }
        }
        &:nth-child(3) {
          grid-area: tile3;
          order: 3;

          @include mq($until: vp5) {
            padding: rem(30) 0;
          }
          .text-tile {
            padding-bottom: 0;
          }
        }
        &:nth-child(2) {
          grid-area: tile2;
          @include threeTileLayoutTileLarge();
        }
      }
    }

    &--one-two {
      @include mq($from: vp5) {
        display: grid;
        grid-template-columns: 58% 7% 35%;
        grid-template-rows: minmax(1fr, auto) minmax(1fr, auto);
        grid-column-gap: 0;
        grid-template-areas:
          "tile1 space tile2"
          "tile1 space tile3";
      }
      .nav-tile {
        &:nth-child(3),
        &:nth-child(2) {
          @include threeTileLayoutTileSmall();
        }

        &:nth-child(1) {
          grid-area: tile1;
          @include threeTileLayoutTileLarge();
        }

        &:nth-child(2) {
          grid-area: tile2;
          @include mq($until: vp5) {
            padding: rem(30) 0;
          }
          .text-tile {
            padding-bottom: rem(10);
            @include mq($from: vp5) {
              padding-bottom: rem(30);
            }
          }
        }
        &:nth-child(3) {
          grid-area: tile3;
          @include mq($until: vp5) {
            padding: rem(30) 0;
          }
        }
      }
    }
  }
}
