@import "../../assets/scss/base";

.logo-animation {
  $module: ".logo-animation";
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;

  &__outer {
    transform-origin: left center;

    @include mq($from: vp5) {
      transform: scale(0.8);
      margin: 0;
      // min-width: rem(200);
    }
    @include mq($from: vp6) {
      transform: scale(1);
    }
    @include mq($from: vp7) {
      min-width: rem(250);
    }
  }

  svg {
    fill: currentColor;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    position: static; // if no animation on mobile/tablet
    @include mq($from: vp5) {
      position: absolute;
      left: 0;
    }
  }

  &__hansen {
    margin-left: 0.8rem;

    @include mq($from: vp5) {
      margin-left: 0 !important;
    }
    &.padding-reset {
      padding-left: 0 !important;
    }
  }

  &__fritz,
  &__hansen {
    overflow: hidden;
    display: grid; // positions the svg
    @include mq($until: vp5) {
      width: auto !important;
    }
  }

  &__fritz-svg,
  &__hansen-svg {
    height: rem(16);
    // @include mq($from: vp5) {
    //   height: rem(20);
    // }
    @include mq($from: vp5) {
      height: rem(23);
    }
  }
  &__fritz-svg {
    width: rem(63);
    // @include mq($from: vp5) {
    //   width: rem(72);
    // }
    @include mq($from: vp5) {
      width: rem(91);
    }
  }
  &__hansen-svg {
    width: rem(97);
    // @include mq($from: vp5) {
    //   width: rem(110);
    // }
    @include mq($from: vp5) {
      width: rem(138);
    }
  }
  &__dot-a-svg,
  &__dot-b-svg {
    width: rem(4);
    height: rem(16);
    display: none; // if no animation on mobile/tablet
    @include mq($from: vp5) {
      display: flex;
      height: rem(23);
    }
  }

  &__dot-a,
  &__dot-b {
    position: absolute;
    top: 0;
    display: inline-flex;
  }

  &__dot-a {
    left: rem(14);
    @include mq($from: vp5) {
      left: rem(16);
    }
  }
  &__dot-b {
    left: rem(4);
    @include mq($from: vp5) {
      left: rem(52);
    }
  }

  @keyframes slideHansen {
    0% {
      width: auto;
      padding-left: 1.2rem;
    }
    50% {
      width: 1.4rem;
      padding-left: 0;
    }
    100% {
      width: auto;
      padding-left: 1.2rem;
    }
  }
}
