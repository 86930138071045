@import "./base";
@import "./reset";
@import "./utils";
@import "./root";
@import "./font-faces";
@import "./rich-text";
@import "./animations";

.layout {
  position: relative;
  overflow: hidden;
  background-color: var(--c-s-site-bgc);
  border-top: var(--mode-switch-height, 0) solid transparent;
  z-index: 1;
  
  &__container {
    transition: transform 0.7s cubic-bezier(0.75, 0, 0.4, 1);
    &.is-inactive {
      transition: transform 0.7s cubic-bezier(0.75, 0, 0.4, 1) 0.2s;
      transform: translateX(-50vw) !important;
    }
  }
}

.mode-container {
  > *:first-child {
    border-top: var(--site-header-height) solid transparent;
  }

  .hero-cover:first-child {
    border-top: none;
  }

  &.is-inactive {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

.svg-icon {
  // width: 1em;
  // height: 1em;
  fill: var(--c-b-black);
}

.vsp {
  @include verticalSpacing;
}

body {
  @include mq($from: vp5) {
    --site-header-height: rem(83);
  }
  @include mq($from: vp8) {
    --site-header-height: rem(103);
  }
}

html {
  
  //to overwrite default anchoring
  overflow-anchor: none;

  &.is-fixed {
    height: 100vh;
    body {
      overscroll-behavior-y: initial;
      position: fixed;
      width: 100vw;
      overflow: hidden;
    }
  }
  &.is-configuring {
    @include mq($until: vp5) {
      height: 100%;
      width: 100%;
      position: fixed;
      overflow: hidden;

      body {
        height: 100%;
      }
    }
  }
}
.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
