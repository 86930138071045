@import "@scss/base";

.pdp-product-thumbs {
  $module: ".pdp-product-thumbs";
  
  display: block;
  @include mq($from: vp5) {
    display: none;
  }

  &__note {
    @include fontStyle(paragraph-3);
    color: var(--c-b-grey2);
    margin-bottom: rem(20);
  }

  &__inner {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(27);
  }
  &__image {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    display: flex;
  }

  .product-image {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: var(--c-t-beige);
  }
}
