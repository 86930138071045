@import "@scss/base";

.feature-small {
  $module: ".feature-small";
  @include container;
  @include verticalSpacing;

  --feature-small-bgc: var(--c-t-beige);

  &__wrapper {
    background: var(--feature-small-bgc);
    @include grid;
    grid-template-rows: auto auto;
    @include mq($from: vp4) {
      grid-template-rows: auto;
    }
  }

  &__text-wrapper {
    padding: rem(30) 0;
    @include gridItem(2, 10, 1, 1);
    @include mq($from: vp4) {
      padding: rem(50) 0;
      @include gridItem(2, 5, 1, 1);
    }
    @include mq($from: vp5) {
      @include gridItem(2, 11, 1, 1);
    }
  }

  &__image {
    overflow: visible;
    @include gridItem(1, 12, 2, 1);
    @include mq($from: vp4) {
      position: relative;
      min-height: rem(250);
      @include gridItem(7, 6, 1, 1);
    }
    @include mq($from: vp5) {
      @include gridItem(13, 12, 1, 1);
    }

    .media-focal {
      height: 56vw;
      @include mq($from: vp4) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
      }
    }
  }

  &--body-right {
    #{$module}__image {
      @include mq($from: vp4) {
        @include gridItem(1, 6, 1, 1);
      }
      @include mq($from: vp5) {
        @include gridItem(1, 12, 1, 1);
      }
    }
    #{$module}__text-wrapper {
      @include mq($from: vp4) {
        @include gridItem(7, 5, 1, 1);
      }
      @include mq($from: vp5) {
        @include gridItem(14, 10, 1, 1);
      }
    }
  }
}
