@import "@scss/base";

.raw-html-module {
  $module: ".raw-html-module";
  @include grid;

  > div {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    @include gridItem(1, 12);
    @include mq($from: vp5) {
      @include gridItem(1, 24);
    }
  }

  &--indented {
    > div {
      @include gridItem(2, 10);
      @include mq($from: vp5) {
        @include gridItem(2, 22);
      }
    }
  }
}
