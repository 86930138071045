@import "../../assets/scss/base";
@import "../../assets/scss/root";
@import "../../assets/scss/reset";

$vp1: 10px;
$vp2: 375px;
$vp3: 560px;
$vp4: 768px;
$vp5: 900px;
$vp6: 1160px;
$vp7: 1400px;
$vp8: 1900px;
$vp9: 2400px;

// Regular
@font-face {
  font-family: "HansenGrotesque";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/font/HansenGrotesque-Regular.woff2") format("woff2");
}
// Regular Italic
@font-face {
  font-family: "HansenGrotesque";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/font/HansenGrotesque-Italic.woff2") format("woff2");
}
// Medium
@font-face {
  font-family: "HansenGrotesque";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/font/HansenGrotesque-Medium.woff2") format("woff2");
}
// Medium Italic
@font-face {
  font-family: "HansenGrotesque";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/font/HansenGrotesque-MediumItalic.woff2")
    format("woff2");
}
// Bold
@font-face {
  font-family: "HansenGrotesque";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/font/HansenGrotesque-Bold.woff2") format("woff2");
}
// Bold Italic
@font-face {
  font-family: "HansenGrotesque";
  font-style: italic;
  font-weight: 700;
  src: url("../../assets/font/HansenGrotesque-BoldItalic.woff2") format("woff2");
}

.body-container {
  width: auto;
  padding: 3rem 1.5rem;
  //font-family: "HansenLight", sans-serif;

  @media screen and (min-width: $vp5) {
    padding: 5rem 3rem;
  }

  .alert {
    margin-bottom: rem(20);
    strong {
      font-weight: $weight-medium;
    }
    &-danger {
      color: var(--c-s-error2);
    }
  }

  // headline
  .card-header {
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 500;
    font-size: 22px;
    padding-bottom: 1.2em;

    @media screen and (min-width: $vp5) {
      font-size: 26px;
    }
    @media screen and (min-width: $vp9) {
      letter-spacing: -1;
      font-size: 60px;
    }
    .svg-icon {
      font-size: 25px;
      height: 1em;
    }
  }

  form {
    padding-bottom: 1em;
  }

  // label and input wrapper
  .form-group {
    position: relative;
    margin: 0 0 20px;
    padding: 3px 0;

    // <input/>
    .form-control {
      font-size: 14px;
      line-height: 1.6;
      @media screen and (min-width: $vp8) {
        font-size: 16px;
      }
      @media screen and (min-width: $vp9) {
        font-size: 18px;
      }

      appearance: none;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
      width: 100%;
      padding: 1.2rem 1.2rem 1rem 1.2rem;
      border: 1px solid #c6c6c6;
      background-color: transparent;
      color: #333333;
      line-height: 1;
      outline: 0;
      transition: all 0.25s ease, text-indent 0s ease 0s;

      /////////// PLACEHOLDER BROWSER STUFF /////////////
      &::placeholder {
        // Hide the "real" placeholder text
        color: transparent;
        opacity: 0;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: transparent;
        opacity: 0;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: transparent;
        opacity: 0;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: transparent;
        opacity: 0;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: transparent;
        opacity: 0;
      }
      &:-webkit-autofill {
        // Expose a hook for JavaScript when auto fill is shown.
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillStart;
      }
      &:not(:-webkit-autofill) {
        animation-name: onAutoFillStop;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover {
        // Known issue - Chrome has a bug regarding font-size when hovering autofill-suggestions
        -webkit-box-shadow: 0 0 0 10000px transparent inset; // Actual background-color
        box-shadow: 0 0 0 10000px transparent inset;
        transition: background-color 10000s ease-in-out 0s;
        -webkit-text-fill-color: black !important;
        color: black !important;
        outline: 0;
      }

      &:-moz-autofill,
      &:-moz-autofill-preview {
        background-color: red;
        color: black;
      }
      &:-moz-ui-invalid {
        // Handles Firefox's internal validation, ex. when type="email" without validation rules
        color: black;
        border-color: #f41212;
        outline: 0; // Overwrite browser styling
        box-shadow: none; // Overwrite browser styling

        + .label {
          color: #f41212;
        }
      }

      //////////// ON FOCUS ///////////////
      &:focus {
        border: 1px solid #666666;
      }

      /////////// LABEL IN FIELD ////////////////
      &:placeholder-shown + .label {
        top: 32px;
        color: #c6c6c6;
        background-color: transparent;
        font-size: 14px;
        line-height: 1.6;

        @media screen and (min-width: $vp8) {
          font-size: 16px;
        }
        @media screen and (min-width: $vp9) {
          font-size: 18px;
        }
      }

      /////////// LABEL ON BORDER ////////////////
      &:focus + .label {
        top: 4px;
        font-size: 11px;
        background-color: white;
        color: #333333;
      }

      &:-webkit-autofill:focus + .label {
        // When in (Ch)rome, hovering an autofill-suggestion (supposedly...)
        top: 4px !important;
        font-size: 11px;
        background-color: white;
      }
    }

    > label {
      font-size: rem(11);
      display: block;

      position: absolute;
      top: 4px;
      left: 18px;

      pointer-events: none;
      transform: translate(0, -50%);
      transition: all 0.25s ease;
      line-height: 1;
      color: #666666;

      background-color: white;
      padding: 0 2px;
      z-index: 1;
      max-width: 85%;
    }
  }

  .buttons {
    margin-top: 2.5rem;
    display: flex;
    flex-grow: auto;
    justify-content: space-between;
    @media screen and (min-width: $vp4) {
      justify-content: start;
    }
  }

  .btn {
    @include buttonStyles();
    & + .btn {
      margin-left: 0.6rem;
    }
  }

  //CHECKBOX

  .form-check {
    display: flex;
    position: relative;
    cursor: pointer;

    &-input {
      appearance: none;
      margin: 0;
      height: 25px;
      flex: 0 0 25px;
      background-color: transparent;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;

      &:checked {
        height: 25px;
        width: 25px;
      }
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        content: url("../../assets/svg/checkmark-small.svg");
        background-color: black;
        font-size: 18px;
        width: 100%;
        height: 100%;
        opacity: 0;
        color: white;
        transition: all 0.25s cubic-bezier(0.25, 0.01, 0.25, 1);
      }
      &:checked:after {
        opacity: 1;
      }
      &:disabled {
        border-color: #666666;
        background-color: darken(white, 5%);
        + label {
          color: #666666;
        }
      }
    }

    &-label {
      margin-left: 15px;
      font-size: 14px;
      line-height: 1.6;
      cursor: pointer;
      text-align: left;
      width: 100%;
      word-break: break-all;
      @media screen and (min-width: $vp8) {
        font-size: 16px;
      }
      @media screen and (min-width: $vp9) {
        font-size: 18px;
      }
    }
  }

  a {
    text-decoration: none;
    color: black;
  }
}

@keyframes onAutoFillStart {
  from {
    width: inherit;
  }
  to {
    width: inherit;
  }
}

@keyframes onAutoFillCancel {
  from {
    width: inherit;
  }
  to {
    width: inherit;
  }
}
