@import "@scss/base";

.product-series-slider {
  $module: ".product-series-slider";
  @include verticalSpacing;

  .swiper-container {
    display: flex;

    box-sizing: border-box;
    padding-left: 2vw;
    @include mq($from: vp3) {
      padding-left: 13vw;
    }
    @include mq($from: vp4) {
      padding-left: 12vw;
    }
    @include mq($from: vp5) {
      padding-left: 11vw;
    }
    @include mq($from: vp6) {
      @include container;
      padding-left: 0;
    }
  }

  &__slide {
    width: 37.3%;
    @include gridGapRight();
    @include mq($from: vp4) {
      width: 23.1%;
    }
    &:nth-child(n + 5) {
      @include mq($from: vp5) {
        display: none;
      }
    }
  }
  .product-series-tile__img {
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }
  .carousel__nav {
    visibility: visible;
    @include mq($from: vp5) {
      visibility: hidden;
    }
  }
  &__show-more {
    @include mq($from: vp4) {
      width: 20%;
    }
  }

  &__link {
    padding-bottom: 100%;
    position: relative;
    display: flex;
    @include mq($from: vp5) {
      height: rem(30); //tile text height - to ensure vertical align
    }
    @include mq($from: vp6) {
      height: rem(40);
    }
    @include mq($from: vp8) {
      height: rem(55);
    }
  }

  &__expand {
    text-decoration: underline;
    display: none;
    font-size: rem(12);
    margin-left: rem(25);
    white-space: nowrap;
    align-items: center;

    @include mq($from: vp5) {
      position: absolute;
      display: inline-flex;
      top: 0;
      bottom: 0;
    }

    .svg-icon {
      margin-right: rem(10);
    }
  }

  &.is-expanded {
    .carousel__nav {
      visibility: visible;
    }
    #{$module}__slide {
      width: 19.2%;
      &:nth-child(n + 5) {
        width: 19.2%;
        display: block;
      }
    }
  }
}
