@mixin topModuleSpacing($large: false) {
  @if $large {
    padding-top: rem(40);
  } @else {
    padding-top: rem(20);
  }

  @include mq($from: vp5) {
    @if $large {
      padding-top: rem(100);
    } @else {
      padding-top: rem(50);
    }
  }
}
