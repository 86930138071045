@import "@scss/base";

.checkout-flow__footer{

  .checkbox{
    padding-top: 10px;
    margin-top: 0.9375rem;
  }
}

.checkbox {
  $formfield: ".checkbox";

  &__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
  }

  &__box {
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    margin: 0;
    height: rem(25);
    flex: 0 0 rem(25);
    background-color: transparent;
    border: rem(1) solid var(--c-b-black);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;

    &:checked {
      height: rem(25);
      width: rem(25);
    }
    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      content: url("../../../assets/svg/checkmark-small.svg");
      background-color: var(--c-b-black);
      font-size: 18px;
      width: 100%;
      height: 100%;
      opacity: 0;
      color: var(--c-b-white);
      transition: opacity var(--default-transition);
    }
    &:checked:after {
      opacity: 1;
    }
    &:disabled {
      border-color: var(--c-b-grey2);
      background-color: darken(white, 5%);
      + label {
        color: var(--c-b-grey2);
      }
    }
  }

  &__label {
    margin-left: rem(15);
    @include fontStyle(paragraph-2);
    line-height: rem(25);
    cursor: pointer;
    text-align: left;
    width: 100%;
    word-break: break-word;

    a {
      display: block;
    }

    strong {
      font-weight: $weight-medium;
    }
  }

  &--label-left {
    #{$formfield}__label {
      order: 1;
    }
    #{$formfield}__box {
      &[type="checkbox"] {
        order: 2;
      }
    }
    #{$formfield}__label {
      text-align: start;
    }
  }

  &.is-invalid {
    #{$formfield}__box {
      color: var(--c-s-error2);
      border-color: var(--c-s-error2);
    }
    #{$formfield}__label {
      color: var(--c-s-error2);
    }
  }

  &__message {
    @include fontStyle(paragraph-2);
    margin-top: rem(5);
    color: var(--c-s-error2);
  }
}
