@import "@scss/base";

.pdp-vertical-product-specification {
    grid-column: 1 / 24;
    display: none;

    @include mq($from: vp5) {
        display: block;
    }

    &__inner {
        @include container;
    }

    .specification-list-filters {
        @include grid;
        @include container;

        &__inner {
            @include gridItem(1, 24);
            @include offsetContent(right);
            display: flex;
            margin-left: 0;
            justify-content: center;
            width: 100%;
        }

        &__item {
            display: inline-flex;
            margin-right: rem(40);
            //margin-bottom: rem(10);
        }
    }

    .specification-list {
        @include grid;
        @include container;
        margin-top: rem(50);

        &__inner {
            @include gridItem(5, 15);
            @include offsetContent(right);

            h4 {
                @include fontStyle(pdp-specification-text);
                font-weight: $weight-medium;
                margin-bottom: rem(10);
            }

            p {
                @include fontStyle(pdp-specification-text);
                margin-bottom: rem(30);
            }
        }
    }

    .specification-list-item {
        &__specifications-wrapper {
            display: flex;
            flex-direction: row;
            //display: grid;
            

            img {
                flex: 1 0 0px;
                width: 0;
            }

            table {
                flex: 1 0 0px;
                width: 0;
            }
        }
    }
    .pdp-product-specification__table-wrapper{
        width: 45%;
        float: right;
    }

    .specification-list-button {
        @include fontStyle(pdp-specification-text);
        text-transform: uppercase;
        opacity: 0.6;

        &.is-active {
            opacity: 1;
            font-weight: $weight-medium;
            text-decoration: underline;
        }
    }

}