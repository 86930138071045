@import "@scss/base";

.nav-module {
  $module: ".nav-module";
  @include grid;
  @include container;
  @include verticalSpacing;

  &__headline {
    @include fontStyle(heading-2);
    padding-bottom: rem(60);
    grid-column: 2 / span 10;
    grid-row: 1;
    @include mq($from: vp5) {
      grid-column: 3 / span 20;
    }
  }

  &__wrapper {
    grid-column: 2 / span 10;
    @include mq($from: vp5) {
      grid-column: 3 / span 20;
    }
    display: grid;
    @include mq($from: vp5) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($from: vp6) {
      grid-template-columns: repeat(3, 1fr);
    }
    grid-auto-rows: auto;
    @include mq($from: vp5) {
      grid-gap: 5.5vw;
    }

    .text-tile {
      flex-direction: row;
      align-items: center;
      padding: 1em 0;
      border-bottom: rem(1) solid var(--c-b-grey3);
      @include mq($from: vp5) {
        align-items: start;
        flex-direction: column;
        margin-bottom: 0;
      }
      &__headline {
        width: 100%;

        margin-bottom: 0;
      }
      &__bodytext {
        display: none;
        @include mq($from: vp5) {
          display: block;
        }
        margin-top: 0;
        margin-bottom: auto;
      }
      .nav-button {
        .text {
          display: none;
          @include mq($from: vp5) {
            display: block;
          }
        }
      }
    }
  }
}
