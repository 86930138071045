@import "@scss/base";

.empty-quantity{
  height: rem(43);
}

.empty-quantity-mobile{
  height: rem(53);
}

.quantity-selector {
  $module: ".quantity-selector";

  &__remove-button {
    margin-right: rem(10);

    &:hover {
      text-decoration: underline;
    }
  }

  &.disable-input-change {
    input {
      pointer-events: none;
    }
  }

  &.is-locked {
    opacity: 0.5;

    button,
    input {
      pointer-events: none;
    }
  }

  &__inner {
    border: rem(1) solid var(--c-b-grey3);
    display: inline-flex;
    width: calc(rem(30 + 30) + 5em);

    @include mq($from: vp5) {
      width: calc(rem(10 + 10) + 7.5em);
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 rem(30);
      height: rem(41);
      box-sizing: border-box;

      @include mq($from: vp5) {
        flex: 0 0 rem(40);
      }
    }

    input {
      min-width: calc(100% - rem(60));

      @include mq($from: vp5) {
        min-width: calc(100% - rem(80));
      }

      cursor: default;
      padding: rem(10) 0;
      height: rem(41);
      appearance: none;
      -webkit-appearance: none;
      border-radius: 0;
      text-align: center;
      border: 0;
      box-sizing: border-box;

      @include mq($until: vp5) {
        font-size: 1rem;
      }
    }
  }
}