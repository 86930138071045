.timeline-scrollhint {
    position: fixed;
    bottom: rem(120);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    pointer-events: none;

    svg {
        fill: var(--c-b-white);
    }
    span {
        position: absolute;
    }
    .timeline-scrollhint__oval {
        top: 0;
        left: 0;
        width: rem(24);
        height: rem(42);

        animation: scrollhint-anim-oval 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) alternate infinite;
    }
    .timeline-scrollhint__dot {
        top: rem(3);
        left: rem(11);
        width: rem(2);
        height: rem(6);
        
        animation: scrollhint-anim-dot 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) alternate infinite;
    }
    .timeline-scrollhint__arrow {
        top: rem(45);
        left: rem(5);
        width: rem(15);
        height: rem(9);
        
        animation: scrollhint-anim-arrow 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) alternate infinite;
    }
}

@keyframes scrollhint-anim-oval {
    0% {
        top: 0;
    }
    20% {
        top: 0;
    }
    80% {
        top: rem(5);
    }
    100% {
        top: rem(5);
    }
}

@keyframes scrollhint-anim-dot {
    0% {
        top: rem(3);
    }
    20% {
        top: rem(3);
    }   
    80% {
        top: rem(10);
    }   
    100% {
        top: rem(10);
    }   
}

@keyframes scrollhint-anim-arrow {
    0% {
        top: rem(45);
    }
    20% {
        top: rem(45);
    }   
    80% {
        top: rem(55);
    }   
    100% {
        top: rem(55);
    }   
}