@import "@scss/base";

.list-title {
  $module: ".list-title";

  @include container();
  @include grid();
  @include verticalSpacing(false, true);

  &--simple {
      #{$module}__content {
        @include gridItem(2, 10);
  
        @include mq($from: vp5) {
          @include gridItem(7, 11);
        }
      }
  
      #{$module}__headline { 
        h1,
        h2 {
          text-align: center;
          font-size: 30px;
        }
      }
  
      #{$module}__text {
        p {
          text-align: center;
          font-size: 15px;
        }
      }
    }

  &__content {
    @include gridItem(2, 10);
    @include mq($from: vp5) {
      @include gridItem(2, 11);
    }
  }

  &__headline {
    h1 {
      @include fontStyle(heading-1);
    }
    h2 {
      @include fontStyle(heading-2);
    }
  }
  &__text {
    margin-top: rem(20);
    p {
      @include fontStyle(paragraph-2);
    }
  }
}
