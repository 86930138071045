@import "@scss/core.scss";
.notification {
  $module: ".notification";
  --notification-c: var(--c-b-black);
  --notification-bgc: var(--c-s-alert);

  position: relative;
  overflow: hidden;

  background-color: var(--notification-bgc);
  z-index: map-get($z-indexes, "notification");

  &__close-icon {
    position: absolute;
    top: rem(20);
    right: rem(20);
    font-size: rem(32);
    cursor: pointer;

    svg {
      stroke: var(--notification-c);
    }
  }
  &__inner-wrapper {
    height: inherit;
    padding: rem(50) rem(50);
    margin: 0 auto;
    max-width: 1000px;

    @include mq($from: vp5) {
      padding: rem(60) rem(70);
    }
  }

  &__headline,
  &__bodytext {
    text-align: center;
  }
  &__headline {
    @include fontStyle(heading-3);
    margin-bottom: rem(20);
  }
  &__bodytext {
    @include fontStyle(paragraph-1);
    margin-bottom: rem(30);
  }
  &--geolocation {
    --notification-bgc: var(--c-t-lightgreen);
    overflow: hidden;
  }
  &__form-wrapper {
    display: grid;
    grid-gap: rem(10);
    @include mq($from: vp4) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(30);
    }
  }

  &--service-message {
    @include grid;
    .notification__bodytext {
      @include fontStyle(paragraph-2);
      color: var(--notification-c);
      white-space: pre-line;
      
      padding: rem(20) 0;
      grid-column: 3 / span 9;
      @include mq($from: vp5) {
        grid-column: 3 / span 21;
      }
      &.button-is-active{
        padding: rem(20) 0 0;
        margin-bottom: rem(0);
      }
    }
    .notification__button {
      @include fontStyle(paragraph-2);
      color: var(--notification-c);
      text-align: center;
      padding: rem(20) 0;
      grid-column: 3 / span 9;
      @include mq($from: vp5) {
        grid-column: 3 / span 21;
      }
      margin-bottom: rem(30);
    }
  }

  &__link {
    text-decoration: underline;
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &.below-mode-switch {
    top: var(--mode-switch-height);
    
    .country-selector  {
      .select__selector {
        height: 62px;
      }

      .action-button {
        height: 62px;
        margin-top: 27px;
      }
    }
  }
}
