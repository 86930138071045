@import "../../assets/scss/base";

.checkout-header {
  $module: ".checkout-header";
  @include container;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mq($from: vp4) {
    padding-top: rem(30);
    height: rem(25);
    flex-direction: row;
    justify-content: space-between;
  }

  &__logo {
    order: 2;
    @include mq($until: vp4) {
      align-self: center;
    }

    @include mq($from: vp4) {
      order: 1;
      margin-top: 0;
    }
    svg {
      width: rem(160);
      @include mq($from: vp4) {
        width: auto;
        height: rem(23);
      }
    }
  }

  &__link {
    @include fontStyle(paragraph-2);
    text-align: center;
    order: 1;
    text-decoration: underline;
    width: 100%;
    padding: rem(5) 0;
    margin-bottom: rem(40);
    background-color: var(--c-b-grey3);
    @include mq($from: vp4) {
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;
      order: 2;
      text-align: end;
      align-self: flex-end;
    }
  }
}
