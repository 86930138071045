@import "@scss/base";

.material-tile {
  $module: ".material-tile";

  margin-bottom: rem(40);
  button {
    width: 100%;
  }
  &:hover {
    .magnify {
      opacity: 1;
    }
  }
  &__image {
    display: flex;
    position: relative;
    width: 100%;
    background-color: var(--c-b-grey4);
    margin-bottom: rem(10);
    height: 30vw;
    @include mq($from: vp3) {
      height: 20vw;
    }
    @include mq($from: vp5) {
      height: 15vw;
    }

    @include mq($from: vp7) {
      height: 10vw;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .magnify {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);
    color: var(--c-b-white);
    transition: opacity var(--default-transition);

    .text {
      @include fontStyle(heading-4);
    }
    .icon {
      margin-right: rem(10);
    }
    svg {
      width: 2em;

      height: 2em;
      fill: currentColor;
    }
  }
  .tag {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: rem(4) rem(8);
    @include fontStyle(trumpet-2);
  }
}
