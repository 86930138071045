@import "@scss/base";

.mode-switch {
  height: rem(30);
  box-sizing: border-box;

  background-color: var(--c-s-mode-switch-bc);
  color: var(--c-s-mode-switch-c);
  
  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: map-get($z-indexes, "main-header");
  display: flex;
  justify-content: center;
  align-items: center;

  transition: height 0.2s ease, padding 0.2s ease, background-color 0.2s;
  @include mq($until: vp5) {
    transform: translateY(-1px);
  }

  &__container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 rem(20);
    
    @include mq($from: vp4) {
      margin: 0;
      padding: rem(6) rem(20);
      flex-wrap: nowrap;
    }
    @include mq($from: vp5) {
      padding: rem(6) rem(40);
    }
  }
  // .is-scrolled & {
  // height: 0;
  // padding-top: 0;
  // padding-bottom: 0;
  // transition: height 0.2s 0.3s ease, padding 0.2s 0.3s ease;
  //}
  // opacity: 1;
  // transition: opacity var(--default-transition);
  // &.is-inactive {
  //   opacity: 0;
  // }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq($from: vp4) {
      margin-right: auto;
      padding-top: 0;
    }
    
    button {
      text-decoration: underline;
      @include fontStyle(trumpet-1);
  
      &:not(.is-inactive) {
        text-decoration: none;
        cursor: default;
        opacity: 0.7;
      }
    }
  }

  &__bodytext {
    @include fontStyle(trumpet-2);
    display: none;
    @include mq($from: vp4) {
      display: block;
    }
  }

  button + button {
    padding-left: rem(10);
    margin-left: rem(10);
    border-left: rem(1) solid var(--c-s-mode-switch-c);
  }

  // Country-selector
  &__country-selector {
    text-align: center;
    width: 100%;
    padding: rem(8.5) 0;

    &--mobile {
      display: inline-flex;
      width: auto;

      .svg-icon {
        width: rem(16);
        height: rem(16);
      }
      @include mq($from: vp5) {
        display: none;
      }
    }
    
    &--desktop {
      display: none;
      
      @include mq($from: vp5) {
        display: block;
      }
    }

    @include mq($from: vp4) {
      padding: rem(30) 0;
      text-align: end;
    }
    @include mq($from: vp5) {
      padding: 0;
    }
    button {
      @include fontStyle(trumpet-1);
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      gap: 1em;

      .svg-icon {
        fill: white;
      }
    }
  }
}
