@import "@scss/base";

.accordion-module {
  $module: ".accordion-module";
  @include grid;
  @include container;
  @include verticalSpacing;

  &__headline {
    @include fontStyle(heading-2);
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(30);
    @include gridItem(2, 10, 1, 1);
  }

  &__handle-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: var(--c-b-black);
    color: var(--c-b-white);
  }
  &__bodytext {
    @include fontStyle(paragraph-2);
    padding-bottom: rem(30);
    @include gridItem(2, 10, 2, 1);
  }
  &__load-more {
    @include gridItem(2, 10, 4, 1);
    padding-top: rem(50);
    display: flex;
    align-items: center;
  }
  &__load-more-icon {
    display: flex;
    .svg-icon {
      margin-left: 0.5em;
    }
  }
  &__items {
    @include gridItem(2, 10, 3, 1);
  }

  .collapsible {
    border-bottom: rem(1) solid var(--c-b-grey3);
    width: 100%;
    &.is-active {
      padding-bottom: rem(25);
    }
    &:first-child {
      border-top: rem(1) solid var(--c-b-grey3);
    }

    &__trigger {
      @include fontStyle(heading-4);
      padding: rem(20) 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      #{$module}__icon {
        transform: rotate(90deg);
      }

      &.is-active {
        #{$module}__icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
