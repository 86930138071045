@import "@scss/base";

.vision-slider-gallery {
  display: block;

  &__item {
    position: relative;
    display: inline-block;
    margin-top: rem(200);
    overflow: hidden;

    .media-focal {
      display: block;
      position: static;
      width: 100%;
      height: auto;
      &__image,
      & > span {
        position: static;
        width: 100%;
        height: auto;
      }
    }
    h6 {
      display: block;
      text-align: left;
      margin-top: rem(10);
      @include fontStyle(paragraph-2);
      margin-left: 10%;
    }
  }

  //mobile layout
  @include mq($until: vp3) {
    @include grid;

    .vision-slider-gallery__item {
      margin-top: rem(80);
      @include gridItem(2, 10);
      position: relative;

      &:nth-child(2) {
        @include gridItem(3, 10);
        @include offsetContent(right);
        width: calc(100% + 5vw);
      }
      &:nth-child(3) {
        @include gridItem(1, 10);
        @include offsetContent(left);
      }
      &:nth-child(4) {
        @include gridItem(3, 9);
      }
    }
  }

  //desktop layout
  &__left {
    .vision-slider-gallery__item {
      margin-top: rem(200);
      &:first-child {
        margin-top: 0;
        width: 100%;
      }
      &:nth-child(2) {
        width: 78%;
        @include offsetContent(left);
        left: -4vw;
      }
      &:nth-child(3) {
        width: 100%;
      }
    }
  }

  &__right {
    text-align: right;

    .vision-slider-gallery__item {
      margin-top: rem(200);

      &:first-child {
        width: calc(100% + 5vw);
        position: relative;
        left: 4vw;
        @include offsetContent(right);
      }
      &:nth-child(2) {
        width: 85%;
      }
    }
  }

  &__right,
  &__left {
    flex: 0 0 45%;
  }
}
