@mixin buttonStyles() {
  --button-bgc: var(--c-b-white);
  --button-c: var(--c-b-black);

  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }

  display: inline-flex;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;

  justify-content: center;
  transition: all var(--default-transition);

  color: var(--button-c);
  background-color: var(--button-bgc);

  padding: rem(20);
  @include mq($from: vp4) {
    padding: rem(20) rem(30);
  }

  @include mq($from: vp6) {
    padding: rem(20) rem(40);
  }

  &:hover {
    opacity: 0.7;
  }

  &.is-disabled {
    opacity: 0.5;

    --button-bgc: var(--c-b-grey3);
    &:hover {
      opacity: 0.5;
    }
  }

  .svg-icon {
    fill: var(--button-c);
  }

  .text {
    display: inline-block;
    @include fontStyle(link-1);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon {
    display: inline-flex;
  }
  .text + .icon,
  .icon + .text {
    margin-left: rem(10);
  }

  &.is-outlined {
    --button-bgc: transparent;
    color: var(--button-c);
    border: rem(1) solid var(--button-c);

    &:hover {
      color: var(--button-c);
      background-color: var(--button-bgc);
    }

    &.is-disabled {
      &:hover {
        color: var(--button-c);
        background: var(--button-bgc);
      }
    }
  }

  &.is-inverted {
    --button-bgc: var(--c-b-black);
    &.is-outlined{
      --button-bgc: transparent;
    }
    --button-c: var(--c-b-white);

    &:hover {
      .svg-icon {
        fill: var(--button-c);
      }
    }

    &.is-disabled {
      --button-bgc: var(--c-b-grey3);
      --button-c: var(--c-b-black);
      color: var(--button-c);
      &:hover {
        color: var(--button-c);
        background: var(--button-bgc);
      }
    }
  }

  &.is-icon-link {
    padding: rem(10);
  }

  &.is-link {
    --button-bgc: transparent;
    --button-c: var(--c-b-black);
    padding: 0;

    strong {
      padding-left: 0.25em;
      padding-right: 0.5em;
      transition: transform var(--default-transition);
    }

    @include animated-underline();

    &:hover {
      .svg-icon {
        fill: var(--button-c);
      }
    }

    &.is-inverted {
      --button-bgc: transparent;
      --button-c: var(--c-b-white);
    }
  }
}
