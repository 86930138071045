@import "@scss/base";

.carousel {
  $module: ".carousel";

  --carousel-bgc: transparent;
  background: var(--carousel-bgc);

  --carousel-c: var(--c-b-black);
  color: var(--carousel-c);

  &--inverted {
    --carousel-c: var(--c-b-white);
  }

  &--has-bgcolor {
    @include verticalSpacing(true);
  }

  &__header {
    // padding-bottom: rem(20);
    // @include mq($from: vp4) {
    //   padding-bottom: 0;
    // }
    @include container();
    @include grid;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: rem(40);
  }
  &__headline {
    @include fontStyle(heading-3);

    grid-column: 2 / span 10;
    @include mq($from: vp4) {
      grid-column: 2 / span 7;
    }
    @include mq($from: vp5) {
      grid-column: 3 / span 18;
    }
  }
  &__bodytext {
    margin-top: rem(15);
    grid-area: 2 / 2 / span 1 / span 7;
    @include mq($from: vp4) {
      grid-area: 2 / 2 / span 1 / span 7;
    }
    @include mq($from: vp5) {
      grid-area: 2 / 3 / span 1 / span 10;
    }
  }

  &__footer {
    @include container();
  }

  &__nav {
    display: none;
    grid-column: 2 / span 10; // Place under headline (same width)
    grid-row: 3;
    padding: rem(20) 0;
    @include mq($from: vp4) {
      grid-row: 1;
      padding: 0;
      display: block;
      justify-self: end;
      grid-column: 9 / span 4;
    }
    @include mq($from: vp5) {
      grid-column: 21 / span 4;
    }
  }
  &__prev,
  &__next {
    font-size: rem(34);
    line-height: 1;
    display: inline-flex;
    z-index: 10;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all var(--default-transition);

    &.is-disabled {
      cursor: default;
      opacity: 0.5;
    }

    @include mq($from: vp4) {
      padding: rem(5) rem(7);
    }
    
    @media (hover: hover) {
      &:not(.is-disabled):hover {
        transform: translateX(rem(8));
      }
    }

    svg {
      height: rem(30);
      width: rem(30);
    }
  }
  &__prev {
    svg {
      transform-origin: center;
      transform: rotate(180deg);
    }
    @media (hover: hover) {
      &:not(.is-disabled):hover {
        transform: translateX(rem(-8));
      }
    }
  }
}

.test-carousel {
  &__slide {
    padding: rem(100) rem(30);
    background-color: #eee;
    width: 70%;
    &:not(:last-child) {
      @include gridGapRight();
    }
    @include mq($from: vp5) {
      width: 40%;
    }
    @include mq($from: vp8) {
      width: 30%;
    }
    h3 {
      @include fontStyle(heading-3);
    }
    p {
      @include fontStyle(paragraph-2);
    }

    &.lars {
      background-color: beige;
    }

    &.mads {
      background-color: pink;
    }
  }
}
