@import "@scss/base";

.timeline-number-display {
  $module: ".timeline-number-display";

  position: fixed;
  top: calc(var(--site-header-height) + var(--mode-switch-height));
  z-index: 3; // CHANGE LATER ??

  pointer-events: none;
  display: block;

  color: var(--c-b-white);

  left: rem(10);
  right: 0;

  small {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    position: relative;
    display: grid;

    grid-template-areas:
      "shared current"
      "empty next";
    grid-template-columns: auto 1fr;

    @include scaledHeadline();
  }

  &__shared {
    grid-area: shared;
    top: 0;
  }
  &__current {
    grid-area: current;
    opacity: 0.5;
  }
  &__next {
    grid-area: next;
    opacity: 0.5;
  }
}
