@import "@scss/base";

.pdp-material-set-selector {
  $module: ".pdp-material-set-selector";
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;

  border-top: rem(1) solid var(--c-b-grey3);

  h3 {
    @include fontStyle(heading-4);
    margin: rem(15) 0 rem(20);
    flex: 0 0 1em;
  }
  &__content {
    margin-top: rem(20);
    overflow: hidden;
    overflow-y: auto;
    max-height: 100%;
    @include custom-scrollbar;
    padding-right: rem(20);
  }
  &__groups {
    padding-bottom: rem(20);
    .pdp-swatch-group + .pdp-swatch-group {
      margin-top: rem(20);
    }
  }
}
