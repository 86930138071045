@import "@scss/base";

.progress-bar {
  $module: ".progress-bar";
  @include container;
  @include grid;

  &__wrapper {
    @include gridItem(1, 12);
    @include mq($from: vp3) {
      @include gridItem(2, 10);
    }
    @include mq($from: vp5) {
      @include gridItem(2, 22);
    }

    display: flex;
    justify-content: space-between;
    counter-reset: step;
  }

  &__dot {
    width: rem(18);
    height: rem(18);
    content: "";
    background-color: var(--c-b-grey3);
    line-height: rem(30);
    display: block;
    text-align: center;
    margin: 0 auto rem(10) auto;
    border-radius: 50%;
    transition: background-color 300ms ease;

    @include mq($from: vp5) {
      width: rem(30);
      height: rem(30);
    }
  }

  &__label {
    font-size: rem(10);
    @include mq($from: vp5) {
      font-size: rem(14);
    }
  }

  &__step {
    list-style-type: none;
    flex: 1 1 0px;
    position: relative;
    text-align: center;
    color: var(--c-b-grey3);

    &::before,
    &::after {
      width: 30%;
      height: rem(1);
      content: "";
      position: absolute;
      background-color: var(--c-b-grey3);
      pointer-events: none;
      top: rem(9);
      @include mq($from: vp5) {
        top: rem(15);
        width: 35%;
      }
      @include mq($from: vp6) {
        width: 40%;
      }
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }

    &:last-child:after,
    &:first-child:before {
      display: none;
      @include mq($from: vp5) {
        display: block;
        width: 40%;
      }
    }
    &.is-active {
      color: var(--c-b-black);
    }
    &.is-active {
      #{$module}__dot {
        background-color: var(--c-b-black);
      }
    }
    &.is-complete {
      #{$module}__dot {
        background: url("../../../assets/svg/checkmark-small.svg")
          var(--c-b-grey3) no-repeat;
        background-position: center top;
        @include mq($from: vp5) {
          background-position: center rem(6);
        }
      }
    }
  }
}
