@import "@scss/base";

.inspiration-slider {
  $module: ".inspiration-slider";

  @include verticalSpacing;

  &__img {
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }
  &__slide {
    width: 60%;
    &:nth-child(4n + 1) {
      @include mq($from: vp4) {
        width: 53%;
      }
      @include mq($from: vp5) {
        width: 37.15%;
      }
    }
    &:nth-child(4n + 2),
    &:nth-child(4n + 4) {
      @include mq($from: vp4) {
        width: 29.2%;
      }
      @include mq($from: vp5) {
        width: 21.6%;
      }
    }
    &:nth-child(4n + 3) {
      @include mq($from: vp4) {
        width: 41.1%;
      }
      @include mq($from: vp5) {
        width: 31.1%;
      }
    }

    .media-focal {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:not(:last-child) {
      @include gridGapRight();
    }
  }

  .swiper-container {
    // padding-bottom: rem(30);
    box-sizing: border-box;
    padding-left: 2vw;
    @include mq($from: vp3) {
      padding-left: 13vw;
    }
    @include mq($from: vp4) {
      padding-left: 12vw;
    }
    @include mq($from: vp5) {
      padding-left: 11vw;
    }
    @include mq($from: vp6) {
      @include container;
      padding-left: 0;
    }
  }
  .carousel__footer {
    padding-left: 2vw;
    @include mq($from: vp3) {
      padding-left: 13vw;
    }
    @include mq($from: vp4) {
      padding-left: 12vw;
    }
    @include mq($from: vp5) {
      padding-left: 11vw;
    }
    @include mq($from: vp6) {
      @include container;
      padding-left: 0;
    }
  }

  // HEADER with nav on mobile
  .carousel__header {
    height: auto;
    margin-bottom: 0;
    @include mq($from: vp4) {
      margin-bottom: rem(40);
    }
    .carousel__headline {
      grid-column: 2 / span 7;
      @include mq($from: vp5) {
        grid-column: 3 / span 12;
      }
    }
    .carousel__nav {
      display: block;
      .carousel__next {
        padding: rem(10) 0 rem(10) rem(10); //remove side padding to fit to border
        @include mq($from: vp4) {
          padding: rem(5) rem(10);
        }
      }
    }
    .carousel__nav + .carousel__bodytext {
      padding-bottom: rem(50);
    }
  }
  .nav-button {
    margin-top: rem(20);

    span {
      @include fontStyle(trumpet-1);
    }
  }
}
