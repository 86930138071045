@import "@scss/base";

.spot-tile {
  $module: ".spot-tile";
  position: relative;
  background-position: cover;
  min-height: rem(200);
  padding: rem(20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  --spot-tile-c: var(--c-b-black);

  color: var(--spot-tile-c);

  &--inverted {
    --spot-tile-c: var(--c-b-white);
  }

  &__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 100%;
  }
  &__headline {
    margin-bottom: auto;

    h1 {
      @include fontStyle(heading-1);
    }
    h2 {
      @include fontStyle(heading-2);
    }
    h3 {
      @include fontStyle(heading-3);
    }
  }
  &__bodytext {
    p {
      @include fontStyle(paragraph-1);
    }
  }

  &--linear {
    padding: 0;
    #{$module}__image {
      flex: 0;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      height: 0;

      padding-bottom: 100%; //to match product tile image ratio
      @include mq($from: vp5) {
        padding-bottom: 48.463%; //to match product tile image ratio
      }

      .media-focal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        padding-bottom: 100%; //to match product tile image ratio
        @include mq($from: vp5) {
          padding-bottom: 48.463%; //to match product tile image ratio
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    #{$module}__content {
      margin-top: rem(25);
      flex-direction: row;
      flex: 1 1 20%;
      display: block;
      @include mq($from: vp5) {
        display: flex;
      }
    }

    #{$module}__headline {
      flex: 0 0 40%;
    }
    #{$module}__bodytext {
      margin-top: rem(25);
      flex: 0 0 50%;
      p {
        @include fontStyle(paragraph-2);
      }
      @include mq($from: vp5) {
        margin-top: 0;
      }
    }
  }
}
